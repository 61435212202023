import { useState, useEffect, useRef } from "react";
import {
    Box,
    Typography,
    Stack,
    Alert,
    AlertTitle,
    Button,
    Grid
} from "@mui/material";
import {
    intialNumberSymbolQuestions,
    numberSymbolButtons
} from "./NumberSymbolQuestion";
import { NumberSymbol, ScoreType } from "./NumberSymbolType";
import _chunk from "lodash/chunk";
import AlphabetToSymbol from "./AlphabetToSymbol";

const totalTestTime = 90;
const initialCounterValue = 3;

export type PuzzleGameCalculationProps = {
    puzzleScore: ScoreType;
    setPuzzleScore: (value: ScoreType) => void;
};

const PuzzleGameCalculation = ({
    puzzleScore,
    setPuzzleScore
}: PuzzleGameCalculationProps) => {
    const [activeItem, setActiveItem] = useState<number>(1);
    const [testBegin, setTestBegin] = useState(true);
    const [realTime, setRealTime] = useState(false);
    const [counter, setCounter] = useState(totalTestTime + initialCounterValue);
    const [activeBlock, setActiveBlock] = useState<number>(1);
    const [readyToTestBlock, setReadyToTestBlock] = useState<boolean>(false);

    const [numberSymbolQuestions, setNumberSymbolQuestions] = useState<
        NumberSymbol[]
    >(intialNumberSymbolQuestions);

    const countRef = useRef(counter);
    countRef.current = counter;

    useEffect(() => {
        if (counter <= 0) {
            setPuzzleScore({ ...puzzleScore, timeSpend: totalTestTime });
        }
    }, [counter]);

    const showClickedButton = (clickedBtnValue: string) => {
        if (numberSymbolQuestions[activeItem - 1].symbol === "") {
            numberSymbolQuestions[activeItem - 1].symbol = clickedBtnValue;
        } else if (numberSymbolQuestions[activeItem - 1].num === "") {
            numberSymbolQuestions[activeItem - 1].num = clickedBtnValue;
        }

        if (activeItem > 10 && activeItem <= 80) {
            if (
                numberSymbolQuestions[activeItem - 1].correctAns ===
                clickedBtnValue
            ) {
                setPuzzleScore({
                    ...puzzleScore,
                    timeSpend: totalTestTime - counter,
                    correctAnswer: puzzleScore.correctAnswer + 1,
                    attemptedAnswer: puzzleScore.attemptedAnswer + 1,
                    numberToSymbolAnswer:
                        puzzleScore.numberToSymbolAnswer +
                        (numberSymbolQuestions[activeItem - 1]
                            .questionColumn === "top"
                            ? 1
                            : 0),
                    symbolToNumberAnswer:
                        puzzleScore.symbolToNumberAnswer +
                        (numberSymbolQuestions[activeItem - 1]
                            .questionColumn === "bottom"
                            ? 1
                            : 0)
                });
            } else {
                setPuzzleScore({
                    ...puzzleScore,
                    timeSpend: totalTestTime - counter,
                    attemptedAnswer: puzzleScore.attemptedAnswer + 1
                });
            }
        }

        setNumberSymbolQuestions(numberSymbolQuestions);
        setActiveItem(activeItem + 1);

        if (activeItem === 10) {
            setTestBegin(false);
        }

        if (activeItem < 5) {
            setActiveBlock(1);
        } else if (activeItem >= 5 && activeItem < 10) {
            setActiveBlock(2);
        } else {
            setReadyToTestBlock(true);
        }
    };

    const loadNumberSymbolButtons = (): JSX.Element[] =>
        numberSymbolButtons.map((button: NumberSymbol) => (
            <Grid
                display='inline-flex'
                item
                key={button.num}
                marginRight={1.5}
                fontWeight='800'
                style={{
                    cursor:
                        activeItem <= numberSymbolQuestions.length && testBegin
                            ? "pointer"
                            : "not-allowed"
                }}
            >
                <Box border='2px solid #000'>
                    <Box
                        height='40px'
                        width='40px'
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                        borderBottom='2px solid #000'
                        onClick={() =>
                            activeItem <= numberSymbolQuestions.length &&
                            testBegin
                                ? showClickedButton(button.num)
                                : ""
                        }
                    >
                        {button.num}
                    </Box>
                    <Box
                        height='40px'
                        width='40px'
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                        onClick={() =>
                            activeItem <= numberSymbolQuestions.length &&
                            testBegin
                                ? showClickedButton(button.symbol)
                                : ""
                        }
                    >
                        <AlphabetToSymbol alphabet={button.symbol} />
                    </Box>
                </Box>
            </Grid>
        ));

    const getStyleOfQuestion = (question: NumberSymbol): string => {
        const selectedBox = [
            ...numberSymbolButtons.filter(
                (buttons) => buttons.symbol === question.symbol
            ),
            ...numberSymbolButtons.filter(
                (buttons) => buttons.num === question.num
            )
        ];
        if (question.num === "" || question.symbol === "") {
            return "transparent";
        }
        if (
            selectedBox[0].num === question.num &&
            selectedBox[0].symbol === question.symbol
        ) {
            return "lightgreen";
        }
        return "lightcoral";
    };

    const questionAnswerBlock = (question: NumberSymbol): JSX.Element => {
        return (
            <Box
                marginLeft='-1px'
                border='1px solid #000'
                style={{
                    backgroundColor:
                        question.sNo <= 10
                            ? getStyleOfQuestion(question)
                            : "transparent",
                    outline:
                        question.sNo === activeItem ? "2px dashed red" : "none",
                    outlineOffset: "2px"
                }}
            >
                <Box
                    height='40px'
                    width='40px'
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    borderBottom='1px solid #000'
                    fontWeight='800'
                >
                    <AlphabetToSymbol alphabet={question.num} />
                </Box>
                <Box
                    height='40px'
                    width='40px'
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    fontWeight='800'
                >
                    <AlphabetToSymbol alphabet={question.symbol} />
                </Box>
            </Box>
        );
    };

    const loadViewOfNumSymbolPuzzel = (): JSX.Element[] => {
        const stackedBox = [];
        _chunk(numberSymbolQuestions, 20).forEach((chunkArray, index) => {
            const chunkedQuestionList = chunkArray.map((question) => (
                <Box position='relative' key={question.sNo}>
                    {question.sNo === 1 && activeBlock === 1 && (
                        <Box
                            className='practise-line'
                            position='absolute'
                            top='-25px'
                            left='5px'
                            fontWeight='bold'
                            whiteSpace='nowrap'
                        >
                            {`Practice #1`}
                        </Box>
                    )}
                    {question.sNo === 6 && activeBlock === 2 && (
                        <Box
                            className='practise-line'
                            position='absolute'
                            top='-25px'
                            left='5px'
                            fontWeight='bold'
                            whiteSpace='nowrap'
                        >
                            {`Practice #2`}
                        </Box>
                    )}

                    {activeBlock === question.block &&
                        questionAnswerBlock(question)}

                    {/* {(question.sNo === 5 || question.sNo === 10) &&
                        activeBlock === 2 && (
                            <Box
                                className='practise-line'
                                position='absolute'
                                borderRight='2px solid #000'
                                height='130%'
                                bottom='0'
                                right='0'
                            ></Box>
                        )} */}
                </Box>
            ));
            stackedBox.push(
                <Stack direction='row' key={index}>
                    {chunkedQuestionList}
                </Stack>
            );
        });
        return stackedBox;
    };

    const reset = () => {
        setRealTime(!realTime);
    };

    useEffect(() => {
        let interval;

        if (realTime) {
            interval = setInterval(() => {
                setCounter((currCount) => (currCount > 0 ? currCount - 1 : 0));
            }, 1000);
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [realTime]);

    useEffect(() => {
        if (counter - totalTestTime < 0) {
            setActiveBlock(3);
            setTestBegin(true);
        }
    }, [counter]);

    return (
        <Box width='100%'>
            <Typography variant='h6' fontWeight='bold' gutterBottom>
                Number - Symbol Coding Test Instructions
            </Typography>
            <Typography variant='body1' gutterBottom marginBottom='10px'>
                Look at these boxes. For each one of these marks there is a
                number that goes with it. Sometimes the symbols are missing and
                sometimes the numbers are missing. I want you to fill in the
                missing symbol that goes with each number and the missing number
                that goes with each symbol.
            </Typography>
            <Typography variant='body1' gutterBottom marginBottom='20px'>
                For example, to pick a symbol corresponding to a number, hover
                the cursor over that symbol and click on it. Make sure you click
                on the symbol and not anywhere else. When you want to pick a
                number, make sure you hover over the number and click on it and
                not anywhere outside.
            </Typography>
            <Box
                display='flex'
                alignItems='center'
                marginBottom='20px'
                justifyContent='space-between'
                width='100%'
                maxWidth='820px'
            >
                <Grid display='inline-flex' borderRight='0'>
                    <Grid display='inline-flex' item>
                        <Box marginRight={2}>
                            <Box
                                height='40px'
                                display='flex'
                                alignItems='center'
                                justifyContent='center'
                                fontWeight='800'
                                fontSize='18px'
                            >
                                Number
                            </Box>
                            <Box
                                height='40px'
                                display='flex'
                                alignItems='center'
                                justifyContent='center'
                                fontWeight='800'
                                fontSize='18px'
                            >
                                Symbol
                            </Box>
                        </Box>
                    </Grid>
                    {loadNumberSymbolButtons()}
                </Grid>
                <Box>{`${counter < 0 ? reset() : ""}`}</Box>
            </Box>
            {activeBlock === 2 && (
                <Typography variant='body1' gutterBottom marginBottom='40px'>
                    {`For example, the number corresponding to the < symbol is 6. Next, the number corresponding to the rectangle is 5. Now, complete the rest of the items in practice # 2`}
                </Typography>
            )}
            {activeBlock === 1 && (
                <Typography variant='body1' gutterBottom marginBottom='40px'>
                    For example, the symbol corresponding to number 3 is a minus
                    sign. Next, the symbol corresponding to number 1 is a
                    triangle. Now, complete the rest of the items in practice #
                    1
                </Typography>
            )}
            {activeBlock === 3 && (
                <Typography variant='body1' gutterBottom marginBottom='40px'>
                    You will need to complete each box in order, so skipping a
                    box is not allowed
                </Typography>
            )}

            <Box
                position='fixed'
                left='0'
                right='0'
                bottom='10px'
                zIndex='999'
                margin='0px auto'
                width='100%'
                maxWidth='790px'
                visibility={
                    readyToTestBlock && counter - totalTestTime > 0
                        ? "visible"
                        : "hidden"
                }
            >
                <Alert
                    variant='filled'
                    severity='success'
                    icon={false}
                    style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        padding: "15px 40px"
                    }}
                    action={
                        <Button
                            color='inherit'
                            variant='outlined'
                            size='medium'
                            style={{ fontSize: "20px" }}
                            onClick={() => {
                                setRealTime(true);
                            }}
                        >
                            {`${
                                realTime
                                    ? `Countdown : ${counter - totalTestTime}`
                                    : "Ready to start"
                            } ${counter < 0 ? reset() : ""}`}
                        </Button>
                    }
                >
                    <AlertTitle
                        style={{
                            fontSize: "20px",
                            marginBottom: "0"
                        }}
                    >
                        Please complete the test in 90 seconds
                    </AlertTitle>
                </Alert>
            </Box>
            {counter <= 0 || activeItem === 81 ? (
                <Box
                    fontSize='24px'
                    fontWeight='bold'
                    height='150px'
                    width='100%'
                    maxWidth='820px'
                    marginBottom='100px'
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                >
                    Thank you
                </Box>
            ) : (
                <Stack direction='column' spacing={2}>
                    {loadViewOfNumSymbolPuzzel()}
                </Stack>
            )}
        </Box>
    );
};

export default PuzzleGameCalculation;
