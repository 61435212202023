import React from "react";
import {
    FormLabel,
    Box,
    Typography,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper
} from "@mui/material";
import { RadioButtonGroupInput, TextInput } from "react-admin";

const NeighborhoodQuestionnaire = (props) => {
    return (
        <Box width='100%'>
            <Box
                borderBottom='1px solid'
                borderColor='grey.200'
                padding='10px 0'
            >
                <Typography variant='h6' fontWeight='bold' gutterBottom>
                    NEIGHBORHOOD QUESTIONNAIRE
                </Typography>
                <Typography variant='body1' gutterBottom>
                    Please rate the characteristics of your neighborhood. Choose the <b>one best answer</b> for each question
                </Typography>
            </Box>
            <Box py='20px'>
                <Box marginBottom={2}>
                    <RadioButtonGroupInput
                        row
                        helperText={false}
                        margin='none'
                        style={{ fontSize: "2rem" }}
                        source='neighborhoodQuestionnaire.closestParkFromHome'
                        label="1. How far is the closest park from your home (city, county, state, or national park)?"
                        choices={[
                            {
                                id: "0-10",
                                name:"0 to 10 minutes if walking (½ mile away or less)"
                            },
                            {
                                id: "11-20",
                                name:"11 to 20 minutes if walking (1/2 to 1 mile away)"
                            },
                            {
                                id: "21-40",
                                name: "21 to 40 minutes if walking (1-2 miles away)"
                            },
                            {
                                id: "40plus",
                                name: "41 or more minutes if walking (over 2 miles away)"
                            },
                            {
                                id: "dontKnow",
                                name: "Don’t know"
                            }
                        ]}
                    ></RadioButtonGroupInput>
                </Box>
                <Box mb={2}>
                    <FormLabel>2. In a typical week, how much do you exercise (examples: walking, gardening, dancing, sports)?</FormLabel>
                    <Box>
                    <TextInput
                        source='neighborhoodQuestionnaire.exercise.hours'
                        label='Hours'
                        variant='outlined'
                        type='number'
                        style={{marginRight: "2rem"}}
                        helperText={false}
                    />
                    <TextInput
                        source='neighborhoodQuestionnaire.exercise.minutes'
                        label='Minutes'
                        variant='outlined'
                        type='number'
                        helperText={false}
                    />
                    </Box>
                    <Typography variant='body2'>Consider your neighborhood as the area within 1 mile of your home (about a 20-minute walk).</Typography>
                </Box>
                <Box marginBottom={2}>
                    <RadioButtonGroupInput
                        row
                        helperText={false}
                        margin='none'
                        style={{ fontSize: "2rem" }}
                        source='neighborhoodQuestionnaire.visitParksInYourNeighborhood'
                        label="3. How often per month do you visit parks in your neighborhood?"
                        choices={[
                            {
                                id: "never",
                                name:"Never or rarely"
                            },
                            {
                                id: "1-4",
                                name:"1 to 4 times per month"
                            },
                            {
                                id: "5-8",
                                name: "5 to 8 times per month"
                            },
                            {
                                id: "9-12",
                                name: "9 to 12 times per month"
                            },
                            {
                                id: "13plus",
                                name: "13 or more times per month"
                            }
                        ]}
                    ></RadioButtonGroupInput>
                </Box>
                <Box mb={2}>
                    <FormLabel>4. In a typical week, how many days do you visit a place in your neighborhood (examples: neighbor’s house, store, restaurant, salon/barber)?</FormLabel>
                    <Box>
                    <TextInput
                        source='neighborhoodQuestionnaire.daysVisitPlaceInYourNeighborhood'
                        label='Days'
                        variant='outlined'
                        type='number'
                        style={{marginRight: "2rem"}}
                        helperText={false}
                    />
                    </Box>
                </Box>
                <Box mb={2}>
                    <FormLabel>5. In a typical week, how much time you spend walking in your neighborhood.</FormLabel>
                    <Box>
                    <TextInput
                        source='neighborhoodQuestionnaire.timeSpendWalkingInYourNeighborhood.hours'
                        label='Hours'
                        variant='outlined'
                        type='number'
                        style={{marginRight: "2rem"}}
                        helperText={false}
                    />
                    <TextInput
                        source='neighborhoodQuestionnaire.timeSpendWalkingInYourNeighborhood.minutes'
                        label='Minutes'
                        variant='outlined'
                        type='number'
                        helperText={false}
                    />
                    </Box>
                </Box>


                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 650 }}
                        size='small'
                        aria-label='simple table'
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2} style={{fontSize: '1.1rem'}}>On a scale of 1 to 7, how much do you agree with the following statements?</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{fontSize: '1.1rem'}}>Check one option for each statement.</TableCell>
                                <TableCell style={{fontSize: '1rem'}}>1 &#8594; Strongly disagree &nbsp;&nbsp; | &nbsp;&nbsp; 4 &#8594; Neutral &nbsp;&nbsp; | &nbsp;&nbsp; 7 &#8594; Strongly agree</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell width="50%" component='th' scope='row'>
                                    <FormLabel>1. The neighborhood has many shade trees.</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='neighborhoodQuestionnaire.manyShadeOfTrees'
                                        label={false}
                                        choices={[{id: "1", name:"1" }, {id: "2", name: "2" }, {id: "3", name: "3" }, {id: "4", name: "4" }, {id: "5", name: "5" }, {id: "6", name: "6" }, {id: "7", name: "7"}]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell width="50%" component='th' scope='row'>
                                    <FormLabel>2. Your neighborhood has a lot of green space (grass, shrubs, trees, and other vegetation).</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='neighborhoodQuestionnaire.lotOFGreenSpace'
                                        label={false}
                                        choices={[{id: "1", name:"1" }, {id: "2", name: "2" }, {id: "3", name: "3" }, {id: "4", name: "4" }, {id: "5", name: "5" }, {id: "6", name: "6" }, {id: "7", name: "7"}]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell width="50%" component='th' scope='row'>
                                    <FormLabel>3. Your neighborhood has a park within walking distance to your home</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='neighborhoodQuestionnaire.parkWithInWalkingDistance'
                                        label={false}
                                        choices={[{id: "1", name:"1" }, {id: "2", name: "2" }, {id: "3", name: "3" }, {id: "4", name: "4" }, {id: "5", name: "5" }, {id: "6", name: "6" }, {id: "7", name: "7"}]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell width="50%" component='th' scope='row'>
                                    <FormLabel>4. You spend a lot of time relaxing in parks, gardens, and other “green” spaces in the neighborhood.</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='neighborhoodQuestionnaire.spendLotOfTimeRelaxingInParks'
                                        label={false}
                                        choices={[{id: "1", name:"1" }, {id: "2", name: "2" }, {id: "3", name: "3" }, {id: "4", name: "4" }, {id: "5", name: "5" }, {id: "6", name: "6" }, {id: "7", name: "7"}]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell width="50%" component='th' scope='row'>
                                    <FormLabel>5. You spend a lot of time exercising in parks, gardens, and other “green” spaces in the neighborhood.</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='neighborhoodQuestionnaire.spendLotOfTimeExercisingInParks'
                                        label={false}
                                        choices={[{id: "1", name:"1" }, {id: "2", name: "2" }, {id: "3", name: "3" }, {id: "4", name: "4" }, {id: "5", name: "5" }, {id: "6", name: "6" }, {id: "7", name: "7"}]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell width="50%" component='th' scope='row'>
                                    <FormLabel>6. You spend a lot of time socializing in parks, gardens, and other “green” spaces in the neighborhood.</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='neighborhoodQuestionnaire.spendLotOfTimeSocializingInParks'
                                        label={false}
                                        choices={[{id: "1", name:"1" }, {id: "2", name: "2" }, {id: "3", name: "3" }, {id: "4", name: "4" }, {id: "5", name: "5" }, {id: "6", name: "6" }, {id: "7", name: "7"}]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell width="50%" component='th' scope='row'>
                                    <FormLabel>7. Crime is a problem in your neighborhood.</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='neighborhoodQuestionnaire.crime'
                                        label={false}
                                        choices={[{id: "1", name:"1" }, {id: "2", name: "2" }, {id: "3", name: "3" }, {id: "4", name: "4" }, {id: "5", name: "5" }, {id: "6", name: "6" }, {id: "7", name: "7"}]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell width="50%" component='th' scope='row'>
                                    <FormLabel>6. Walking in the neighborhood is unsafe due to traffic.</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='neighborhoodQuestionnaire.unsafeDueToTraffic'
                                        label={false}
                                        choices={[{id: "1", name:"1" }, {id: "2", name: "2" }, {id: "3", name: "3" }, {id: "4", name: "4" }, {id: "5", name: "5" }, {id: "6", name: "6" }, {id: "7", name: "7"}]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell width="50%" component='th' scope='row'>
                                    <FormLabel>7. Sidewalks are available in most areas of your neighborhood.</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='neighborhoodQuestionnaire.sidewalkAvailable'
                                        label={false}
                                        choices={[{id: "1", name:"1" }, {id: "2", name: "2" }, {id: "3", name: "3" }, {id: "4", name: "4" }, {id: "5", name: "5" }, {id: "6", name: "6" }, {id: "7", name: "7"}]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell width="50%" component='th' scope='row'>
                                    <FormLabel>8. Most people in your neighborhood can be trusted.</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='neighborhoodQuestionnaire.canBeTrusted'
                                        label={false}
                                        choices={[{id: "1", name:"1" }, {id: "2", name: "2" }, {id: "3", name: "3" }, {id: "4", name: "4" }, {id: "5", name: "5" }, {id: "6", name: "6" }, {id: "7", name: "7"}]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell width="50%" component='th' scope='row'>
                                    <FormLabel>9. Adequate grocery shopping options are available nearby.</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='neighborhoodQuestionnaire.adequateGroceryShopping'
                                        label={false}
                                        choices={[{id: "1", name:"1" }, {id: "2", name: "2" }, {id: "3", name: "3" }, {id: "4", name: "4" }, {id: "5", name: "5" }, {id: "6", name: "6" }, {id: "7", name: "7"}]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell width="50%" component='th' scope='row'>
                                    <FormLabel>10. You feel lonely living in your neighborhood.</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='neighborhoodQuestionnaire.feelLonely'
                                        label={false}
                                        choices={[{id: "1", name:"1" }, {id: "2", name: "2" }, {id: "3", name: "3" }, {id: "4", name: "4" }, {id: "5", name: "5" }, {id: "6", name: "6" }, {id: "7", name: "7"}]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell width="50%" component='th' scope='row'>
                                    <FormLabel>10. Your neighborhood has a lot of noise.</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='neighborhoodQuestionnaire.lotsOfNoise'
                                        label={false}
                                        choices={[{id: "1", name:"1" }, {id: "2", name: "2" }, {id: "3", name: "3" }, {id: "4", name: "4" }, {id: "5", name: "5" }, {id: "6", name: "6" }, {id: "7", name: "7"}]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default NeighborhoodQuestionnaire;
