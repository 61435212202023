import React from "react";
import {
    Box,
    Typography,
    FormLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Divider
} from "@mui/material";
import { RadioButtonGroupInput } from "react-admin";

const GlobalHealthRating = (props) => {
    return (
        <Box width='100%'>
            <Box
                borderBottom='1px solid'
                borderColor='grey.200'
                padding='10px 0'
            >
                <Typography variant='h6' marginTop='10px' gutterBottom>
                    GLOBAL HEALTH RATINGS
                </Typography>
            </Box>
            <Box py='20px'>
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 650 }}
                        size='small'
                        aria-label='simple table'
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    width='50%'
                                    style={{ fontSize: "1.1rem" }}
                                >
                                    Please circle the one best answer for each
                                    question
                                </TableCell>
                                <TableCell>&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>
                                        How would you rate your overall physical
                                        health?
                                    </FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='globalHealthRating.overallPhysicalHealth'
                                        label={false}
                                        choices={[
                                            { id: 0, name: "Poor" },
                                            { id: 1, name: "Fair" },
                                            { id: 2, name: "Good" },
                                            { id: 3, name: "Excellent" }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>
                                        How would you rate your overall mental
                                        health?
                                    </FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='globalHealthRating.overallMentalHealth'
                                        label={false}
                                        choices={[
                                            { id: 0, name: "Poor" },
                                            { id: 1, name: "Fair" },
                                            { id: 2, name: "Good" },
                                            { id: 3, name: "Excellent" }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>
                                        How would you rate your overall
                                        emotional health?
                                    </FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='globalHealthRating.overallEmotionalHealth'
                                        label={false}
                                        choices={[
                                            { id: 0, name: "Poor" },
                                            { id: 1, name: "Fair" },
                                            { id: 2, name: "Good" },
                                            { id: 3, name: "Excellent" }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "td, th": { border: 0 } }}>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>
                                        How would you rate your overall
                                        socialization and social engagement?
                                    </FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='globalHealthRating.overallSocialization'
                                        label={false}
                                        choices={[
                                            { id: 0, name: "Poor" },
                                            { id: 1, name: "Fair" },
                                            { id: 2, name: "Good" },
                                            { id: 3, name: "Excellent" }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Divider />
        </Box>
    );
};

export default GlobalHealthRating;
