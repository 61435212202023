import React, { Fragment } from "react";
import {
    Box,
    Grid,
    Typography,
    FormLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Stack,
} from "@mui/material";
import { TextInput, RadioButtonGroupInput, FormDataConsumer } from "react-admin";

const SocialHabitHistory = (props) => {
    return (
        <Box width='100%'>
            <Box
                borderBottom='1px solid'
                borderTop='1px solid'
                borderColor='grey.200'
                padding='10px 0'
            >
                <Typography variant='h6'>
                    SOCIAL HABIT HISTORY
                </Typography>
            </Box>
            <Box py="20px">
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell width="50%" style={{ fontSize: "1.1rem" }}>HABIT</TableCell>
                                <TableCell>&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Tobacco Use (Cigarettes, cigars, pipe, vaping)</FormLabel>
                                    <FormDataConsumer>
                                        {({ formData }) => {
                                            return (
                                                <Stack spacing={0} marginTop="10px" paddingLeft="30px">
                                                    {formData.socialHabitHistory?.tobaccoUse?.status === 'never' && ''}
                                                    {formData.socialHabitHistory?.tobaccoUse?.status === 'current' && (
                                                        <Grid display="flex" alignItems="center">
                                                            <FormLabel style={{ "marginRight": "10px", width: "50%", fontSize: ".95rem" }}>Number of Packs Per Day</FormLabel>
                                                            <TextInput variant='outlined' helperText={false} type="number" source="socialHabitHistory.tobaccoUse.PackedPerDays" label="Packed per days" />
                                                        </Grid>
                                                    )}
                                                    {formData.socialHabitHistory?.tobaccoUse?.status === 'inPast' && (
                                                        <Fragment>
                                                            <Grid display="flex" alignItems="center">
                                                                <FormLabel style={{ "marginRight": "10px", width: "50%", fontSize: ".95rem" }}>Number of Years Smoked</FormLabel>
                                                                <TextInput variant='outlined' helperText={false} type="number" source="socialHabitHistory.tobaccoUse.numOfYears" label="Number of Years" />
                                                            </Grid>
                                                            <Grid display="flex" alignItems="center">
                                                                <FormLabel style={{ "marginRight": "10px", width: "50%", fontSize: ".95rem" }}>If quit, how long ago (in years):</FormLabel>
                                                                <TextInput variant='outlined' helperText={false} type="number" source="socialHabitHistory.tobaccoUse.quitInYears" label="Years" />
                                                            </Grid>
                                                        </Fragment>
                                                    )}
                                                </Stack>
                                            )
                                        }}
                                    </FormDataConsumer>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="socialHabitHistory.tobaccoUse.status"
                                        label={false}
                                        choices={[{ id: 'never', name: 'Never' }, { id: 'inPast', name: 'In Past' }, { id: 'current', name: 'Current' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Alcohol Use</FormLabel>
                                    <FormDataConsumer>
                                        {({ formData }) => {
                                            return (
                                                <Stack spacing={0} marginTop="10px" paddingLeft="30px">
                                                    {formData.socialHabitHistory?.alcoholUse?.status === 'never' && ''}
                                                    {formData.socialHabitHistory?.alcoholUse?.status === 'current' && (
                                                        <Grid display="flex" alignItems="center">
                                                            <FormLabel style={{ "marginRight": "10px", width: "50%", fontSize: ".95rem" }}>Number of Drinks Per Day</FormLabel>
                                                            <TextInput variant='outlined' helperText={false} type="number" source="socialHabitHistory.alcoholUse.packedPerDays" label="Drink per days" />
                                                        </Grid>
                                                    )}
                                                    {formData.socialHabitHistory?.alcoholUse?.status === 'inPast' && (
                                                        <Fragment>
                                                            <Grid display="flex" alignItems="center">
                                                                <FormLabel style={{ "marginRight": "10px", width: "50%", fontSize: ".95rem" }}>Number of Years Drinking</FormLabel>
                                                                <TextInput variant='outlined' helperText={false} type="number" source="socialHabitHistory.alcoholUse.numOfYears" label="Number of Years" />
                                                            </Grid>
                                                            <Grid display="flex" alignItems="center">
                                                                <FormLabel style={{ "marginRight": "10px", width: "50%", fontSize: ".95rem" }}>If quit, how long ago (in years):</FormLabel>
                                                                <TextInput variant='outlined' helperText={false} type="number" source="socialHabitHistory.alcoholUse.quitInYears" label="Years" />
                                                            </Grid>
                                                        </Fragment>
                                                    )}
                                                </Stack>
                                            )
                                        }}
                                    </FormDataConsumer>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                            row
                                            helperText={false}
                                            margin="none"
                                            source="socialHabitHistory.alcoholUse.status"
                                            label={false}
                                            choices={[{ id: 'never', name: 'Never' }, { id: 'inPast', name: 'In Past' }, { id: 'current', name: 'Current' }]}
                                        >
                                    </RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Substance Use (marijuana, cocaine, IV drugs, etc)</FormLabel>
                                    <FormDataConsumer>
                                        {({ formData }) => {
                                            return (
                                                <Stack spacing={0} marginTop="10px" paddingLeft="30px">
                                                    {formData.socialHabitHistory?.substanceUse?.status === 'never' && ''}
                                                    {(formData.socialHabitHistory?.substanceUse?.status === 'current' || formData.socialHabitHistory?.substanceUse?.status === 'inPast') && (
                                                        <Fragment>
                                                            <Grid display="flex" alignItems="center">
                                                                <FormLabel style={{ "marginRight": "10px", width: "50%", fontSize: ".95rem" }}>Specify type:</FormLabel>
                                                                <TextInput variant='outlined' helperText={false} type="number" source="socialHabitHistory.substanceUse.timesPerDays" label="Times per days" />
                                                            </Grid>
                                                            <Grid display="flex" alignItems="center">
                                                                <FormLabel style={{ "marginRight": "10px", width: "50%", fontSize: ".95rem" }}>How frequent:</FormLabel>
                                                                <TextInput variant='outlined' helperText={false} type="text" source="socialHabitHistory.substanceUse.type" label="Specify type" />
                                                            </Grid>
                                                        </Fragment>
                                                    )}
                                                    {formData.socialHabitHistory?.substanceUse?.status === 'inPast' && (
                                                        <Fragment>
                                                            <Grid display="flex" alignItems="center">
                                                                <FormLabel style={{ "marginRight": "10px", width: "50%", fontSize: ".95rem" }}>Number of Years Using</FormLabel>
                                                                <TextInput variant='outlined' helperText={false} type="number" source="socialHabitHistory.substanceUse.numOfYears" label="Number of Years" />
                                                            </Grid>
                                                            <Grid display="flex" alignItems="center">
                                                                <FormLabel style={{ "marginRight": "10px", width: "50%", fontSize: ".95rem" }}>If quit, how long ago (in years):</FormLabel>
                                                                <TextInput variant='outlined' helperText={false} type="number" source="socialHabitHistory.substanceUse.quitInYears" label="Years" />
                                                            </Grid>
                                                        </Fragment>
                                                    )}
                                                    
                                                </Stack>
                                            )
                                        }}
                                    </FormDataConsumer>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                            row
                                            helperText={false}
                                            margin="none"
                                            source="socialHabitHistory.substanceUse.status"
                                            label={false}
                                            choices={[{ id: 'never', name: 'Never' }, { id: 'inPast', name: 'In Past' }, { id: 'current', name: 'Current' }]}
                                        >
                                    </RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

        </Box>
    );
};

export default SocialHabitHistory;
