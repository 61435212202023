import { Box, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useRedirect } from "react-admin";

export const Dashboard = () => {
    const redirect = useRedirect();
    useEffect(() => {
        redirect("/brainHealth/create");
    }, []);
    return (
        <Box py={6} textAlign='center'>
            <CircularProgress />
        </Box>
    );
};
