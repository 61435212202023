import { getAMPSScoring } from "./AMPSCalculation";
import { getCognitiveAndLeisureActivitiesScoring } from "./cognitiveAndLeisureActivitiesCalculation";
import { getMediterraneanDashInterventionScoring } from "./mediterraneanDashInterventionCalculation";
import { getQPAR } from "./QPAR";
import { getSocialPositionScoring } from "./socialPositionCalculation";

export const getResilienceIndexCalculation = (data) => {
    return calculateResilienceIndexCalculation(data);
};

const calculateResilienceIndexCalculation = (data) => {
    return {
        cognitiveReserveUnits: getSocialPositionScoring(data).CRU,
        socialEngagement: parseInt(data.globalHealthRating.overallSocialization ?? 0),
        QPAR: getQPAR(data),
        CLAS: getCognitiveAndLeisureActivitiesScoring(data),
        MIND: getMediterraneanDashInterventionScoring(data),
        AMPS: getAMPSScoring(data).total,
        TOTAL_Range_1_to_378:
            getSocialPositionScoring(data).CRU +
            parseInt(data.globalHealthRating.overallSocialization ?? 0) +
            getQPAR(data) +
            getCognitiveAndLeisureActivitiesScoring(data) +
            getMediterraneanDashInterventionScoring(data) +
            getAMPSScoring(data).total
    };
};
