export const getMediterraneanDashInterventionScoring = (data) => {
    return calculateMediterraneanDashIntervention(
        data.mediterraneanDashIntervention
    );
};

const calculateMediterraneanDashIntervention = (data) => {
    return (
        parseInt(data.greenLeafyVegetables) +
        parseInt(data.otherVegetables) +
        parseInt(data.berries) +
        parseInt(data.nuts) +
        parseInt(data.oliveOil) +
        parseInt(data.butterMargarine) +
        parseInt(data.cheese) +
        parseInt(data.wholeGrains) +
        parseInt(data.fish) +
        parseInt(data.beans) +
        parseInt(data.poultry) +
        parseInt(data.redMeat) +
        parseInt(data.fastFriedFoods) +
        parseInt(data.pastries) +
        parseInt(data.wine)
    );
};
