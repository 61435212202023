import React from "react";
import {
    FormLabel,
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper
} from "@mui/material";
import { RadioButtonGroupInput } from "react-admin";

const DepressionAnxietyAndApathyAssessment = (props) => {
    return (
        <Box width='100%'>
            <Box
                borderBottom='1px solid'
                borderColor='grey.200'
                padding='10px 0'
            >
                <Typography variant='h6' fontWeight='bold' gutterBottom>
                    MOOD ASSESSMENT
                </Typography>
                <Typography variant='body1' gutterBottom>
                    <b>Instructions:</b> Over the past 2 weeks, how often would you say that you have felt or experienced the following? Please choose one answer for each question.
                </Typography>
            </Box>
            <Box py='20px'>
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 650 }}
                        size='small'
                        aria-label='simple table'
                    >
                        <TableBody>
                            <TableRow>
                                <TableCell width="50%" component='th' scope='row'>
                                    <FormLabel>1. I feel tense and unable to relax:</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='depressionAnxietyAndApathyAssessment.DA3_An.tenseAndUnableToRelax'
                                        label={false}
                                        choices={[
                                            {
                                                id: "3",
                                                name:
                                                    "Always"
                                            },
                                            {
                                                id: "2",
                                                name:
                                                    "Often"
                                            },
                                            {
                                                id: "1",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "0",
                                                name: "Rarely"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>2. I have frightened or nervous feelings I cannot control:</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='depressionAnxietyAndApathyAssessment.DA3_An.frightened'
                                        label={false}
                                        choices={[
                                            {
                                                id: "3",
                                                name:
                                                    "Always"
                                            },
                                            {
                                                id: "2",
                                                name:
                                                    "Often"
                                            },
                                            {
                                                id: "1",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "0",
                                                name: "Rarely"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>3. I am afraid that something bad is going to happen:</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='depressionAnxietyAndApathyAssessment.DA3_An.afraid'
                                        label={false}
                                        choices={[
                                            {
                                                id: "3",
                                                name:
                                                    "Always"
                                            },
                                            {
                                                id: "2",
                                                name:
                                                    "Often"
                                            },
                                            {
                                                id: "1",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "0",
                                                name: "Rarely"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>4. I worry about things too much:</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='depressionAnxietyAndApathyAssessment.DA3_An.worry'
                                        label={false}
                                        choices={[
                                            {
                                                id: "3",
                                                name: "Always"
                                            },
                                            {
                                                id: "2",
                                                name: "Often"
                                            },
                                            {
                                                id: "1",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "0",
                                                name: "Rarely"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>5. I feel restless and fidgety:</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='depressionAnxietyAndApathyAssessment.DA3_An.restless'
                                        label={false}
                                        choices={[
                                            {
                                                id: "3",
                                                name: "Always"
                                            },
                                            {
                                                id: "2",
                                                name: "Often"
                                            },
                                            {
                                                id: "1",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "0",
                                                name: "Rarely"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>6. I have trouble focusing my thoughts:</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='depressionAnxietyAndApathyAssessment.DA3_An.trouble'
                                        label={false}
                                        choices={[
                                            {
                                                id: "3",
                                                name: "Always"
                                            },
                                            {
                                                id: "2",
                                                name: "Often"
                                            },
                                            {
                                                id: "1",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "0",
                                                name: "Rarely"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>7. I get strange feelings like shortness of breath, heart fluttering, or butterflies in my stomach:</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='depressionAnxietyAndApathyAssessment.DA3_An.strangeFeelings'
                                        label={false}
                                        choices={[
                                            {
                                                id: "3",
                                                name: "Always"
                                            },
                                            {
                                                id: "2",
                                                name: "Often"
                                            },
                                            {
                                                id: "1",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "0",
                                                name: "Rarely"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        <TableBody>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>1. I feel cheerful and happy:</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='depressionAnxietyAndApathyAssessment.DA3_D.cheerFulAndHappy'
                                        label={false}
                                        choices={[
                                            {
                                                id: "1",
                                                name: "Always"
                                            },
                                            {
                                                id: "2",
                                                name: "Often"
                                            },
                                            {
                                                id: "3",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "4",
                                                name: "Rarely"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>2. I am more critical of myself than I use to be:</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='depressionAnxietyAndApathyAssessment.DA3_D.criticalOfMyself'
                                        label={false}
                                        choices={[
                                            {
                                                id: "1",
                                                name: "Always"
                                            },
                                            {
                                                id: "2",
                                                name: "Often"
                                            },
                                            {
                                                id: "3",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "4",
                                                name: "Rarely"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>3. I have a good energy level:</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='depressionAnxietyAndApathyAssessment.DA3_D.goodEnergyLevel'
                                        label={false}
                                        choices={[
                                            {
                                                id: "1",
                                                name: "Always"
                                            },
                                            {
                                                id: "2",
                                                name: "Often"
                                            },
                                            {
                                                id: "3",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "4",
                                                name: "Rarely"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>4. I am satisfied with my life:</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='depressionAnxietyAndApathyAssessment.DA3_D.satisfied'
                                        label={false}
                                        choices={[
                                            {
                                                id: "1",
                                                name: "Always"
                                            },
                                            {
                                                id: "2",
                                                name: "Often"
                                            },
                                            {
                                                id: "3",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "4",
                                                name: "Rarely"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>5. I have feelings of low self-esteem or worthlessness:</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='depressionAnxietyAndApathyAssessment.DA3_D.lowSelfEsteem'
                                        label={false}
                                        choices={[
                                            {
                                                id: "1",
                                                name: "Always"
                                            },
                                            {
                                                id: "2",
                                                name: "Often"
                                            },
                                            {
                                                id: "3",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "4",
                                                name: "Rarely"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>6. I feel hopeful and look forward to the next day:</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='depressionAnxietyAndApathyAssessment.DA3_D.hopeful'
                                        label={false}
                                        choices={[
                                            {
                                                id: "1",
                                                name: "Always"
                                            },
                                            {
                                                id: "2",
                                                name: "Often"
                                            },
                                            {
                                                id: "3",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "4",
                                                name: "Rarely"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>7. I can laugh at myself and see the funny side of things:</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='depressionAnxietyAndApathyAssessment.DA3_D.laughtAtMyself'
                                        label={false}
                                        choices={[
                                            {
                                                id: "1",
                                                name: "Always"
                                            },
                                            {
                                                id: "2",
                                                name: "Often"
                                            },
                                            {
                                                id: "3",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "4",
                                                name: "Rarely"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        <TableBody>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>1. I am able to get things started and completed:</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='depressionAnxietyAndApathyAssessment.DA3_Ap.getThingStarted'
                                        label={false}
                                        choices={[
                                            {
                                                id: "1",
                                                name: "Always"
                                            },
                                            {
                                                id: "2",
                                                name: "Often"
                                            },
                                            {
                                                id: "3",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "4",
                                                name: "Rarely"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>2. I sit by and let things happen around me:</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='depressionAnxietyAndApathyAssessment.DA3_Ap.sitByAndLetThingHappen'
                                        label={false}
                                        choices={[
                                            {
                                                id: "1",
                                                name: "Always"
                                            },
                                            {
                                                id: "2",
                                                name: "Often"
                                            },
                                            {
                                                id: "3",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "4",
                                                name: "Rarely"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>3. I am less likely to start a conversation with someone:</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='depressionAnxietyAndApathyAssessment.DA3_Ap.lessLikelyToStartConversation'
                                        label={false}
                                        choices={[
                                            {
                                                id: "1",
                                                name: "Always"
                                            },
                                            {
                                                id: "2",
                                                name: "Often"
                                            },
                                            {
                                                id: "3",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "4",
                                                name: "Rarely"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>4. I get as much pleasure out of things as I ever did:</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='depressionAnxietyAndApathyAssessment.DA3_Ap.muchPleaseOutOfThings'
                                        label={false}
                                        choices={[
                                            {
                                                id: "1",
                                                name: "Always"
                                            },
                                            {
                                                id: "2",
                                                name: "Often"
                                            },
                                            {
                                                id: "3",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "4",
                                                name: "Rarely"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>5. I feel less spontaneous than I used to:</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='depressionAnxietyAndApathyAssessment.DA3_Ap.lessSpontaneous'
                                        label={false}
                                        choices={[
                                            {
                                                id: "1",
                                                name: "Always"
                                            },
                                            {
                                                id: "2",
                                                name: "Often"
                                            },
                                            {
                                                id: "3",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "4",
                                                name: "Rarely"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>6. I enjoy my hobby activities and interests:</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='depressionAnxietyAndApathyAssessment.DA3_Ap.hobbyActivitiesAndInerest'
                                        label={false}
                                        choices={[
                                            {
                                                id: "1",
                                                name: "Always"
                                            },
                                            {
                                                id: "2",
                                                name: "Often"
                                            },
                                            {
                                                id: "3",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "4",
                                                name: "Rarely"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>7. I am less interested in what family and friends are doing or planning to do:</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='depressionAnxietyAndApathyAssessment.DA3_Ap.lessInterestedInFamilyAndFriendDoing'
                                        label={false}
                                        choices={[
                                            {
                                                id: "1",
                                                name: "Always"
                                            },
                                            {
                                                id: "2",
                                                name: "Often"
                                            },
                                            {
                                                id: "3",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "4",
                                                name: "Rarely"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default DepressionAnxietyAndApathyAssessment;
