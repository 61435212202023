import React from "react";
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper
} from "@mui/material";
import { RadioButtonGroupInput } from "react-admin";

const FunctionalActivitiesQuestionnaire = (props) => {
    return (
        <Box width='100%'>
            <Box
                borderBottom='1px solid'
                borderColor='grey.200'
                padding='10px 0'
            >
                <Typography variant='h6' fontWeight='bold' gutterBottom>
                    FUNCTIONAL ACTIVITIES QUESTIONNAIRE
                </Typography>
                <Typography variant='body1' gutterBottom>
                    Please rate the your ability to carry out your activities of
                    daily living over the <b>past 4 weeks</b>: Choose the{" "}
                    <b>one best answer</b> for each category that best fits your
                    current abilities.
                </Typography>
            </Box>
            <Box py='20px'>
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 650 }}
                        size='small'
                        aria-label='simple table'
                    >
                        <TableBody>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='functionalActivitiesQuestionaire.checkbalancing'
                                        label='1. Writing checks, paying bills, or
                                        balancing a checkbook'
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "Normal Activity or Never did this activity"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Has difficulty but does by self"
                                            },
                                            {
                                                id: "2",
                                                name: "Requires assistance"
                                            },
                                            {
                                                id: "3",
                                                name: "Dependent on others"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='functionalActivitiesQuestionaire.taxRecord'
                                        label='2. Assembling tax records, business
                                        affairs, or other papers'
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "Normal Activity or Never did this activity"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Has difficulty but does by self"
                                            },
                                            {
                                                id: "2",
                                                name: "Requires assistance"
                                            },
                                            {
                                                id: "3",
                                                name: "Dependent on others"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='functionalActivitiesQuestionaire.shoppingAlone'
                                        label='3. Shopping alone for groceries, clothes,
                                        or household necessities'
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "Normal Activity or Never did this activity"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Has difficulty but does by self"
                                            },
                                            {
                                                id: "2",
                                                name: "Requires assistance"
                                            },
                                            {
                                                id: "3",
                                                name: "Dependent on others"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='functionalActivitiesQuestionaire.playingGames'
                                        label='4. Playing a game such as bridge, cards,
                                        chess, checkers, or working on a hobby
                                        activity'
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "Normal Activity or Never did this activity"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Has difficulty but does by self"
                                            },
                                            {
                                                id: "2",
                                                name: "Requires assistance"
                                            },
                                            {
                                                id: "3",
                                                name: "Dependent on others"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='functionalActivitiesQuestionaire.heatingWater'
                                        label='5. Heating water, making a cup of coffee,
                                        turning off the stove'
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "Normal Activity or Never did this activity"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Has difficulty but does by self"
                                            },
                                            {
                                                id: "2",
                                                name: "Requires assistance"
                                            },
                                            {
                                                id: "3",
                                                name: "Dependent on others"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='functionalActivitiesQuestionaire.preparingMeal'
                                        label='6. Preparing a balanced meal'
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "Normal Activity or Never did this activity"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Has difficulty but does by self"
                                            },
                                            {
                                                id: "2",
                                                name: "Requires assistance"
                                            },
                                            {
                                                id: "3",
                                                name: "Dependent on others"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='functionalActivitiesQuestionaire.keepTrack'
                                        label='7. Keeping track of current events'
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "Normal Activity or Never did this activity"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Has difficulty but does by self"
                                            },
                                            {
                                                id: "2",
                                                name: "Requires assistance"
                                            },
                                            {
                                                id: "3",
                                                name: "Dependent on others"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='functionalActivitiesQuestionaire.payingAttention'
                                        label='8. Paying attention to and understanding a
                                        TV program, book or magazine'
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "Normal Activity or Never did this activity"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Has difficulty but does by self"
                                            },
                                            {
                                                id: "2",
                                                name: "Requires assistance"
                                            },
                                            {
                                                id: "3",
                                                name: "Dependent on others"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='functionalActivitiesQuestionaire.remembering'
                                        label='9. Remembering appointments, family
                                        occasions, holidays, medications'
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "Normal Activity or Never did this activity"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Has difficulty but does by self"
                                            },
                                            {
                                                id: "2",
                                                name: "Requires assistance"
                                            },
                                            {
                                                id: "3",
                                                name: "Dependent on others"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    \
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='functionalActivitiesQuestionaire.taveling'
                                        label='10. Traveling out of the neighborhood,
                                        driving, or taking public transportation'
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "Normal Activity or Never did this activity"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Has difficulty but does by self"
                                            },
                                            {
                                                id: "2",
                                                name: "Requires assistance"
                                            },
                                            {
                                                id: "3",
                                                name: "Dependent on others"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default FunctionalActivitiesQuestionnaire;
