import { useState, Fragment } from "react";
import { Create, SimpleForm, useNotify } from "react-admin";
import PersonalInformation from "./PersonalInformation";
import HollingHeadIndexOfSocialPosition from "./HollingHeadIndexOfSocialPosition";
import PatientFamilyHistory from "./PatientFamilyHistory";
import CarePlaning from "./CarePlaning";
import GlobalHealthRating from "./GlobalHealthRating";
import MedicalHistory from "./MedicalHistory";
import SocialHabitHistory from "./SocialHabitHistory";
import SymptomRatings from "./SymptomRatings";
import FunctionalActivitiesQuestionnaire from "./FunctionalActivitiesQuestionnaire";
import AppliedMindfullnessProcessScale from "./AppliedMindfullnessProcessScale";
import PersonalityTraitQuestionaire from "./PersonalityTraitQuestionaire";
import TheHealthyBrain from "./TheHealthyBrain";
import MediterraneanDashIntervention from "./MediterraneanDashIntervention";
import PhysicalActivityScale from "./PhysicalActivityScale";
import CognitiveAndLeisureActivities from "./CognitiveAndLeisureActivities";
import AutonomicSymptomsChecklist from "./AutonomicSymptomsChecklist";
import SleepEvaluation from "./SleepEvaluation";
import SelfReportPhyicalExamination from "./SelfReportPhysicalExamination";
import DepressionAnxietyAndApathyAssessment from "./DepressionAnxietyAndApathyAssessment";
import NeighborhoodQuestionnaire from "./NeighborhoodQuestionnaire";
import BrainHealthOverLifeCourse from "./BrainHealthOverLifeCourse";
import { getSocialPositionScoring } from "./calculation/socialPositionCalculation";
import { getDA3Scoring } from "./calculation/da3Calulation";
import { getCognitiveAndLeisureActivitiesScoring } from "./calculation/cognitiveAndLeisureActivitiesCalculation";
import { getAMPSScoring } from "./calculation/AMPSCalculation";
import { getFunctionalActivitiesQuestionnaireScoring } from "./calculation/functionalActivitiesQuestionnaireCalculation";
import { getMediterraneanDashInterventionScoring } from "./calculation/mediterraneanDashInterventionCalculation";
import { getModifiedCaideScore } from "./calculation/modifiedCaideScoreCalculation";
import { getPersonalityTraitScoring } from "./calculation/personalityTraitCalculation";
import { getSystemRatingScoring } from "./calculation/symtomsRatingCalculation";
import { getTheHealthyBrainScoring } from "./calculation/theHealthyBrainCalculation";
import { getVulnerAbilityScore } from "./calculation/vulnerabilityScoreCalculation";
import PuzzleGameCalculation from "./PuzzleGameCalculation";
import { ScoreType } from "./NumberSymbolType";
import { getCharlsonComorbidityIndexCalculation } from "./calculation/charlsonComorbidityIndexCalculation";
import { getFunctionalComorbidityIndexCalculation } from "./calculation/functionalComorbidityIndexCalculation";
import { getQPAR } from "./calculation/QPAR";
import { getResilienceIndexCalculation } from "./calculation/resilienceIndexCalculation";
import Scatter3DChart from "./graph/Scatter3DChart";

const BrainHealthCreateForm = () => {
    const [puzzleScore, setPuzzleScore] = useState<ScoreType>({
        correctAnswer: 0,
        attemptedAnswer: 0,
        numberToSymbolAnswer: 0,
        symbolToNumberAnswer: 0,
        timeSpend: 0
    });

    const notify = useNotify();

    const onSuccess = (data) => {
        notify(`Your record created successfully`, {
            type: "success",
            undoable: true
        });
        //redirect("edit", "posts", data.id, data);
    };

    const transform = (data) => ({
        ...data,
        calculation: {
            QPAR: getQPAR(data),
            socialPositionScoring: getSocialPositionScoring(data),
            DA3: getDA3Scoring(data),
            AMPS: getAMPSScoring(data),
            cognitiveAndLeisureActivities: getCognitiveAndLeisureActivitiesScoring(
                data
            ),
            functionalActivitiesQuestionnaire: getFunctionalActivitiesQuestionnaireScoring(
                data
            ),
            mediterraneanDashIntervention: getMediterraneanDashInterventionScoring(
                data
            ),
            personalityTraitQuestionaire: getPersonalityTraitScoring(data),
            symptomRatings: getSystemRatingScoring(data),
            theHealthyBrain: getTheHealthyBrainScoring(data),
            charlsonComorbidityIndexCalculation: getCharlsonComorbidityIndexCalculation(
                data
            ),
            functionalComorbidityIndexCalculation: getFunctionalComorbidityIndexCalculation(
                data
            ),
            modifiedCaideScore: getModifiedCaideScore(data),
            vulnerabilityScore: getVulnerAbilityScore(data),
            resilienceIndexCalculation: getResilienceIndexCalculation(data)
        },
        puzzleScore
    });

    return (
        <Fragment>
            <Create
                disableAuthentication
                title='Brain Health Platform'
                transform={transform}
                redirect={false}
                mutationOptions={{ onSuccess }}
            >
                <SimpleForm>
                    <PersonalInformation />
                    <HollingHeadIndexOfSocialPosition />
                    <PatientFamilyHistory />
                    <CarePlaning />
                    <GlobalHealthRating />
                    <MedicalHistory />
                    <SocialHabitHistory />
                    <NeighborhoodQuestionnaire />
                    <DepressionAnxietyAndApathyAssessment />
                    <SymptomRatings />
                    <FunctionalActivitiesQuestionnaire />
                    <AppliedMindfullnessProcessScale />
                    <PersonalityTraitQuestionaire />
                    <TheHealthyBrain />
                    <MediterraneanDashIntervention />
                    <PhysicalActivityScale />
                    <CognitiveAndLeisureActivities />
                    <AutonomicSymptomsChecklist />
                    <SleepEvaluation />
                    <BrainHealthOverLifeCourse />
                    <SelfReportPhyicalExamination />
                    <PuzzleGameCalculation
                        puzzleScore={puzzleScore}
                        setPuzzleScore={setPuzzleScore}
                    />
                </SimpleForm>
            </Create>
        </Fragment>
    );
};

export default BrainHealthCreateForm;
