export const getVulnerAbilityScore = (data) => {
    return calculateVulnerAbilityScore(data);
};

const calculateVulnerAbilityScore = (data) => {
    return {
        Age: data.personalInformation.currentAge,
        Sex: data.personalInformation.gender,
        RaceEthnicity_NonHispanicWhite: data.personalInformation.ethinicity,
        Education: data.personalInformation.participantHighestDegree,
        Diabetes: data.medicalHistory.diabetes.status,
        Stroke: data.medicalHistory.miniStroke.status,
        Frailty: data.medicalHistory.slownessInMoving.status,
        HeartDisease: data.medicalHistory.heartDisease.status,
        Obesity: getScoreForBMI(data),
        Hypertension: data.medicalHistory.hypertension.status,
        Hypercholesterolemia: data.medicalHistory.highCholesterol.status,
        Depression: data.medicalHistory.depression.status,
        totalScore_Range_2_to_20:
            getScoreForAge(data.personalInformation.currentAge) +
            parseInt(data.personalInformation.gender === "male" ? "1" : "0") +
            parseInt(
                data.personalInformation.ethinicity === "true" ? "2" : "1"
            ) +
            getScoreForEducation(
                data.personalInformation.participantHighestDegree
            ) +
            parseInt(
                data.medicalHistory.diabetes.status === "true" ? "2" : "0"
            ) +
            parseInt(
                data.medicalHistory.miniStroke.status === "true" ? "2" : "0"
            ) +
            parseInt(
                data.medicalHistory.slownessInMoving.status === "true"
                    ? "2"
                    : "0"
            ) +
            parseInt(
                data.medicalHistory.heartDisease.status === "true" ? "1" : "0"
            ) +
            parseInt(getScoreForBMI(data) >= 30 ? "1" : "0") +
            parseInt(
                data.medicalHistory.hypertension.status === "true" ? "1" : "0"
            ) +
            parseInt(
                data.medicalHistory.highCholesterol.status === "true"
                    ? "1"
                    : "0"
            ) +
            parseInt(
                data.medicalHistory.depression.status === "true" ? "1" : "0"
            )
    };
};

const getScoreForAge = (currentAge) => {
    const ageInNum = parseInt(currentAge);
    if (ageInNum < 65) {
        return 0;
    } else if (ageInNum >= 65 && ageInNum <= 72) {
        return 1;
    } else {
        return 2;
    }
};

const getScoreForEducation = (education) => {
    switch (education) {
        case "graduateProfessionalLargeBusiness":
        case "standardCollege":
            return 0;
        case "partialCollege":
        case "highSchoolGraduate":
            return 1;
        default:
            return 2;
    }
};

const getScoreForBMI = (data) => {
    const weightInKG = parseInt(data.selfReportPhyicalExamination.weight);
    const heightInMeter = parseInt(data.selfReportPhyicalExamination.height);
    return weightInKG / heightInMeter ** 2;
};
