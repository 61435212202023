import React from "react";
import {
    Box,
    Typography,
    FormLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    Divider
} from "@mui/material";
import { RadioButtonGroupInput } from "react-admin";

const CarePlaning = (props) => {
    return (
        <Box width='100%'>
            <Box
                borderBottom='1px solid'
                borderColor='grey.200'
                padding='10px 0'
            >
                <Typography variant='h6' marginTop="10px" gutterBottom>
                    REVIEW OF CARE PLANNING
                </Typography>
            </Box>
            <Box py="20px">
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                <TableCell width="50%" component="th" scope="row">
                                    <FormLabel>Do you have an Advance Directive (Health Care Surrogate and/or Living Will)?</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="carePlanning.advanceDirective"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Do you have a Durable Power of Attorney?</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="carePlanning.powerOfAttorney"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Do you have Medical Insurance?</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="carePlanning.medicalInsurance"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Do you have Disability Care Insurance?</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="carePlanning.disabilityCareInsurance"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ 'td, th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Do you have Long –Term Care Insurance?</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="carePlanning.longTermCareInsurance"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Divider />
        </Box>
    );
};

export default CarePlaning;
