import React, { Fragment } from 'react';
import {
    List,
    Datagrid,
    TextField,
    TopToolbar,
    CreateButton,
    ExportButton,
    Button,
    downloadCSV,
    useGetList
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import DownloadIcon from '@mui/icons-material/Download';
import Scatter3DChart from './graph/Scatter3DChart';

type PuzzleScoreType = {
    attemptedAnswer: number;
    correctAnswer: number;
    numberToSymbolAnswer: number;
    symbolToNumberAnswer: number;
    timeSpend: number;
}

const exporter = posts => {
    const postsForExport = posts.map(post => {
        const postId: string = post.id;
        const puzzelScoreValue = post.puzzleScore as PuzzleScoreType;
        return {
            id: postId, 
            attempedAnswer: puzzelScoreValue?.attemptedAnswer, 
            correctAnswer: puzzelScoreValue?.correctAnswer, 
            numberToSymbolAnswer: puzzelScoreValue?.numberToSymbolAnswer, 
            symbolToNumberAnswer: puzzelScoreValue?.symbolToNumberAnswer, 
            timeSpend: puzzelScoreValue?.timeSpend};
    });
    jsonExport(postsForExport, {
        headers: ['id'] // order fields in the export
    }, (err, csv) => {
        downloadCSV(csv, 'Puzzle game score'); // download as 'posts.csv` file
    });
};

const ListActions = () => {
    const { data } = useGetList('foo');
   
    return <TopToolbar>
        <CreateButton/>
        <ExportButton label="Export ALl"/>
        <Button
            onClick={() => exporter(data)}
            label="Export Number symbole coding"
        >
            <DownloadIcon />
        </Button>
    </TopToolbar>
};

export const BrainHealthPatientList = () => (
    <Fragment>
        <List actions={<ListActions/>} perPage={5}>  
            <Datagrid bulkActionButtons={false}>
                <TextField source='id' />
                <TextField source='personalInformation.patientName' />
                <TextField source='personalInformation.addressOfPatient' />
                <TextField source='personalInformation.eMail' />
            </Datagrid>
        </List>
        <Scatter3DChart />
    </Fragment>
);