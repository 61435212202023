export const getCharlsonComorbidityIndexCalculation = (data) => {
    return calculateCharlsonComorbidityIndexCalculation(data);
};

const calculateCharlsonComorbidityIndexCalculation = (data) => {
    return {
        MyocardialInfarction: data.medicalHistory.heartDisease.effect,
        CongestiveHeartFailure: data.medicalHistory.heartDisease.effect,
        PeripheralDisease: data.medicalHistory.peripheralVascularDisease.status,
        CerebrovascularDisease: data.medicalHistory.traumaticHeadInjury.status,
        Dementia: data.patientFamilyHistory.alzheimerDisease,
        ChronicPulmonaryDisease: data.medicalHistory.chronicLungDisease.status,
        ConnectiveTissueDisease: data.medicalHistory.autoimmuneDisorders.status,
        PepticUlcerDisease: data.medicalHistory.pepticUlcerDisease.status,
        MildLiverDisease: data.medicalHistory.liverDisease.effect,
        ModerateOrSevereLiverDisease: data.medicalHistory.liverDisease.effect,
        DiabetesWithoutEndOrganDamage: data.medicalHistory.diabetes.effect,
        DiabetesWithEndOrganDamage: data.medicalHistory.diabetes.effect,
        Hemiplegia: data.medicalHistory.miniStroke.status,
        ModerateOrSevereRenalDisease: data.medicalHistory.kidneyDisease.effect,
        Leukemia: data.medicalHistory.lymphomaOrLeukemia.status,
        Lymphoma: data.medicalHistory.lymphomaOrLeukemia.status,
        TumorWithoutMetastasis: data.medicalHistory.cancer.metastaticDisease,
        TumorWithMetastasis: data.medicalHistory.cancer.metastaticDisease,
        AIDS: data.medicalHistory.aids.status,
        totalScore:
            parseInt(
                data.medicalHistory.heartDisease.effect === "hearthAttack"
                    ? "1"
                    : "0"
            ) +
            parseInt(
                data.medicalHistory.heartDisease.effect ===
                    "congestiveHeartFailure"
                    ? "1"
                    : "0"
            ) +
            parseInt(
                data.medicalHistory.peripheralVascularDisease.status === "true"
                    ? "1"
                    : "0"
            ) +
            parseInt(
                data.medicalHistory.traumaticHeadInjury.status === "true"
                    ? "1"
                    : "0"
            ) +
            parseInt(
                data.patientFamilyHistory.alzheimerDisease === "true"
                    ? "1"
                    : "0"
            ) +
            parseInt(
                data.medicalHistory.chronicLungDisease.status === "true"
                    ? "1"
                    : "0"
            ) +
            parseInt(
                data.medicalHistory.autoimmuneDisorders.status === "true"
                    ? "1"
                    : "0"
            ) +
            parseInt(
                data.medicalHistory.pepticUlcerDisease.status === "true"
                    ? "1"
                    : "0"
            ) +
            parseInt(
                data.medicalHistory.liverDisease.effect === "mild" ? "1" : "0"
            ) +
            parseInt(
                data.medicalHistory.liverDisease.effect === "moderate" ||
                    data.medicalHistory.liverDisease.effect === "severe"
                    ? "3"
                    : "0"
            ) +
            parseInt(
                data.medicalHistory.diabetes.effect ===
                    "diabetesNoEndOrganDamage"
                    ? "1"
                    : "0"
            ) +
            parseInt(
                data.medicalHistory.diabetes.effect === "diabetesEndOrganDamage"
                    ? "2"
                    : "0"
            ) +
            parseInt(
                data.medicalHistory.miniStroke.status === "true" ? "2" : "0"
            ) +
            parseInt(
                data.medicalHistory.kidneyDisease.effect === "moderate" ||
                    data.medicalHistory.kidneyDisease.effect === "severe"
                    ? "2"
                    : "0"
            ) +
            parseInt(
                data.medicalHistory.lymphomaOrLeukemia.status === "true"
                    ? "2"
                    : "0"
            ) +
            parseInt(
                data.medicalHistory.lymphomaOrLeukemia.status === "true"
                    ? "2"
                    : "0"
            ) +
            parseInt(
                data.medicalHistory.cancer.metastaticDisease === "false"
                    ? "2"
                    : "0"
            ) +
            parseInt(
                data.medicalHistory.cancer.metastaticDisease === "true"
                    ? "6"
                    : "0"
            ) +
            parseInt(data.medicalHistory.aids.status === "true" ? "6" : "0")
    };
};
