import React from "react";
import {
    Box,
    Typography,
    FormLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from "@mui/material";
import { TextInput, RadioButtonGroupInput, FormDataConsumer } from "react-admin";

const MedicalHistory = (props) => {
    return (
        <Box width='100%'>
            <Box
                borderBottom='1px solid'
                borderColor='grey.200'
                padding='10px 0'
            >
                <Typography variant='h6' marginTop="10px" gutterBottom>
                    REVIEW OF MEDICAL HISTORY
                </Typography>
            </Box>
            <Box py="20px">
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell width="50%">ILLNESS</TableCell>
                                <TableCell width="20%">&nbsp;</TableCell>
                                <TableCell>Age at Diagnosis</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Traumatic Head Injury</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="medicalHistory.traumaticHeadInjury.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.traumaticHeadInjury?.status === 'true' &&
                                            <TextInput source="medicalHistory.traumaticHeadInjury.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Stroke / TIA (Mini-Strokes)</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="medicalHistory.miniStroke.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.miniStroke?.status === 'true' &&
                                            <TextInput source="medicalHistory.miniStroke.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Balance problems</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="medicalHistory.balanceProblems.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.balanceProblems?.status === 'true' &&
                                            <TextInput source="medicalHistory.balanceProblems.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Falls</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="medicalHistory.falls.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.falls?.status === 'true' &&
                                            <TextInput source="medicalHistory.falls.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Slowness in moving</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="medicalHistory.slownessInMoving.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.slownessInMoving?.status === 'true' &&
                                            <TextInput source="medicalHistory.slownessInMoving.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Stiffness in moving</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="medicalHistory.stiffnessInMoving.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.stiffnessInMoving?.status === 'true' &&
                                            <TextInput source="medicalHistory.stiffnessInMoving.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Seizures</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="medicalHistory.seizures.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.seizures?.status === 'true' &&
                                            <TextInput source="medicalHistory.seizures.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Fainting Spells/Passing Out/Syncope</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="medicalHistory.faintingSpells.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.faintingSpells?.status === 'true' &&
                                            <TextInput source="medicalHistory.faintingSpells.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Depression</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="medicalHistory.depression.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.depression?.status === 'true' &&
                                            <TextInput source="medicalHistory.depression.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Anxiety</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="medicalHistory.anxiety.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.anxiety?.status === 'true' &&
                                            <TextInput source="medicalHistory.anxiety.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Diabetes</FormLabel>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.diabetes?.status === 'true' &&
                                            <RadioButtonGroupInput
                                                row
                                                helperText={false}
                                                margin="none"
                                                source="medicalHistory.diabetes.effect"
                                                label={false}
                                                choices={[
                                                    { id: 'diabeteDietContolled', name: 'Diet controlled only, no medication' },
                                                    { id: 'diabetesNoEndOrganDamage', name: 'No end organ damage' },
                                                    { id: 'diabetesEndOrganDamage', name: 'End organ damage (retinopathy, neuropathy, nephropathy, etc.)' },
                                                ]}
                                            >
                                            </RadioButtonGroupInput>

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="medicalHistory.diabetes.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.diabetes?.status === 'true' &&
                                            <TextInput source="medicalHistory.diabetes.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Hypertension (High Blood Pressure)</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="medicalHistory.hypertension.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.hypertension?.status === 'true' &&
                                            <TextInput source="medicalHistory.hypertension.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Heart Disease (If yes, please check which are applicable)</FormLabel>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.heartDisease?.status === 'true' &&
                                            <Box>
                                                <RadioButtonGroupInput
                                                    row
                                                    helperText={false}
                                                    margin="none"
                                                    source="medicalHistory.heartDisease.effect"
                                                    label={false}
                                                    {...rest}
                                                    choices={[
                                                        { id: 'hearthAttack', name: 'Hearth Attack' }, 
                                                        { id: 'valveDisease', name: 'Valve disease' }, 
                                                        { id: 'atrialFibrillation', name: 'Atrial Fibrillation' }, 
                                                        { id: 'congestiveHeartFailure', name: 'Congestive Heart Failure' }, 
                                                    ]}
                                                >
                                                </RadioButtonGroupInput>
                                            </Box>
                                        }
                                    </FormDataConsumer>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="medicalHistory.heartDisease.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.heartDisease?.status === 'true' &&
                                            <TextInput source="medicalHistory.heartDisease.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Pacemaker</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="medicalHistory.pacemaker.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.pacemaker?.status === 'true' &&
                                            <TextInput source="medicalHistory.pacemaker.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Peripheral Vascular Disease/Circulation Problems</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="medicalHistory.peripheralVascularDisease.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.peripheralVascularDisease?.status === 'true' &&
                                            <TextInput source="medicalHistory.peripheralVascularDisease.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>High Cholesterol</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="medicalHistory.highCholesterol.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.highCholesterol?.status === 'true' &&
                                            <TextInput source="medicalHistory.highCholesterol.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Chronic Lung Disease (Bronchitis, Asthma, COPD, Emphysema)</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="medicalHistory.chronicLungDisease.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.chronicLungDisease?.status === 'true' &&
                                            <TextInput source="medicalHistory.chronicLungDisease.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Liver Disease (Hepatitis, Cirrhosis, etc.)</FormLabel>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.liverDisease?.status === 'true' &&
                                            <Box><RadioButtonGroupInput
                                                row
                                                helperText={false}
                                                margin="none"
                                                source="medicalHistory.liverDisease.effect"
                                                label={false}
                                                {...rest}
                                                choices={[{ id: 'mild', name: 'Mild' }, { id: 'moderate', name: 'Moderate' }, { id: 'severe', name: 'Severe' }]}
                                            >
                                            </RadioButtonGroupInput>
                                            </Box>
                                        }
                                    </FormDataConsumer>

                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="medicalHistory.liverDisease.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.liverDisease?.status === 'true' &&
                                            <TextInput source="medicalHistory.liverDisease.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Kidney Disease (Renal Failure, Kidney Stones, etc.)</FormLabel>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.kidneyDisease?.status === 'true' &&
                                            <Box>
                                                <RadioButtonGroupInput
                                                    row
                                                    helperText={false}
                                                    margin="none"
                                                    source="medicalHistory.kidneyDisease.effect"
                                                    label={false}
                                                    {...rest}
                                                    choices={[{ id: 'mild', name: 'Mild' }, { id: 'moderate', name: 'Moderate' }, { id: 'severe', name: 'Severe' }]}
                                                >
                                                </RadioButtonGroupInput>
                                            </Box>
                                        }
                                    </FormDataConsumer>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="medicalHistory.kidneyDisease.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.kidneyDisease?.status === 'true' &&
                                            <TextInput source="medicalHistory.kidneyDisease.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Thyroid Disease (Hypothyroidism, etc.)</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="medicalHistory.thyroidDisease.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.thyroidDisease?.status === 'true' &&
                                            <TextInput source="medicalHistory.thyroidDisease.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Hearing Loss</FormLabel>
                                    <Box>
                                        <FormDataConsumer>
                                            {({ formData, ...rest }) => formData.medicalHistory?.hearingLoss?.status === 'true' &&
                                                <RadioButtonGroupInput
                                                    row
                                                    helperText={false}
                                                    margin="none"
                                                    source="medicalHistory.hearingLoss.wearHearingAid"
                                                    label="Wear hearing aid"
                                                    {...rest}
                                                    choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                                >
                                                </RadioButtonGroupInput>
                                            }
                                        </FormDataConsumer>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="medicalHistory.hearingLoss.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.hearingLoss?.status === 'true' &&
                                            <TextInput source="medicalHistory.hearingLoss.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Eye Disease</FormLabel>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.eyeDisease?.status === 'true' &&
                                            <Box>
                                                <RadioButtonGroupInput
                                                    row
                                                    helperText={false}
                                                    margin="none"
                                                    source="medicalHistory.eyeDisease.effect"
                                                    label={false}
                                                    choices={[
                                                        { id: "glaucoma", name: 'Glaucoma' }, { id: 'cataracts', name: 'Cataracts' },
                                                        { id: 'macularDegeneration', name: 'Macular Degeneration' },
                                                    ]}
                                                >
                                                </RadioButtonGroupInput>
                                                <br />
                                                <RadioButtonGroupInput
                                                    row
                                                    helperText={false}
                                                    margin="none"
                                                    source="medicalHistory.eyeDisease.wearGlass"
                                                    label="Wear glasses (Circle)"
                                                    choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                                >
                                                </RadioButtonGroupInput>
                                            </Box>
                                        }
                                    </FormDataConsumer>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="medicalHistory.eyeDisease.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.eyeDisease?.status === 'true' &&
                                            <TextInput source="medicalHistory.eyeDisease.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Peptic Ulcer Disease</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="medicalHistory.pepticUlcerDisease.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.pepticUlcerDisease?.status === 'true' &&
                                            <TextInput source="medicalHistory.pepticUlcerDisease.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Digestive System (Reflux, Irritable Bowel Syndrome, Crohn’s Disease)</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="medicalHistory.digestiveSystemDisease.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.digestiveSystemDisease?.status === 'true' &&
                                            <TextInput source="medicalHistory.digestiveSystemDisease.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Cancer (specify type):</FormLabel>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.cancer?.status === 'true' &&
                                            <Box>
                                                <RadioButtonGroupInput
                                                    row
                                                    helperText={false}
                                                    margin="none"
                                                    source="medicalHistory.cancer.metastaticDisease"
                                                    label="Known metastatic disease"
                                                    {...rest}
                                                    choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                                >
                                                </RadioButtonGroupInput>
                                            </Box>

                                        }
                                    </FormDataConsumer>
                                    
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="medicalHistory.cancer.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.cancer?.status === 'true' &&
                                            <TextInput source="medicalHistory.cancer.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Lymphoma or Leukemia</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="medicalHistory.lymphomaOrLeukemia.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.lymphomaOrLeukemia?.status === 'true' &&
                                            <TextInput source="medicalHistory.lymphomaOrLeukemia.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Arthritis/Osteoarthritis</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="medicalHistory.arthritisOsteoarthritis.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.arthritisOsteoarthritis?.status === 'true' &&
                                            <TextInput source="medicalHistory.arthritisOsteoarthritis.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Degenerative Disc Disease (Low Back Disease)</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="medicalHistory.degenerativeDiscDisease.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.degenerativeDiscDisease?.status === 'true' &&
                                            <TextInput source="medicalHistory.degenerativeDiscDisease.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Osteoporosis</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="medicalHistory.osteoporosis.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.osteoporosis?.status === 'true' &&
                                            <TextInput source="medicalHistory.osteoporosis.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>Autoimmune Disorders (Lupus, Rheumatoid Arthritis, etc.)</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="medicalHistory.autoimmuneDisorders.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.autoimmuneDisorders?.status === 'true' &&
                                            <TextInput source="medicalHistory.autoimmuneDisorders.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>AIDS (not just HIV positive)</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="medicalHistory.aids.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.medicalHistory?.aids?.status === 'true' &&
                                            <TextInput source="medicalHistory.aids.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

        </Box>
    );
};

export default MedicalHistory;
