import _isNil from "lodash/isNil";

export const getQPAR = (data) => {
    return calculateQPAR(data.physicalActivityScale);
};

const participantInSittingActivities = 1;
const walkOutsideForAnyReason = 1;
const engageOnLightActivities = 1;
const engageModerateActivities = 2;
const engageStrenuousActivities = 3;
const excericesForMuscleStrength = 2;
const engagedOnFlexibilityActivities = 1;
const engagedOnLightHousework = 1;
const engagedOnModerateHousework = 2;
const engagedOnHeavyHousework = 3;

const calculateQPAR = (data) => {
    return (
        calculateEachActivityDose(
            participantInSittingActivities,
            data.participantInSittingActivities.value,
            data.participantInSittingActivities.spend
        ) +
        calculateEachActivityDose(
            walkOutsideForAnyReason,
            data.walkOutsideForAnyReason.value,
            data.walkOutsideForAnyReason.spend
        ) +
        calculateEachActivityDose(
            engageOnLightActivities,
            data.engageOnLightActivities.value,
            data.engageOnLightActivities.spend
        ) +
        calculateEachActivityDose(
            engageModerateActivities,
            data.engageModerateActivities.value,
            data.engageModerateActivities.spend
        ) +
        calculateEachActivityDose(
            engageStrenuousActivities,
            data.engageStrenuousActivities.value,
            data.engageStrenuousActivities.spend
        ) +
        calculateEachActivityDose(
            excericesForMuscleStrength,
            data.excericesForMuscleStrength.value,
            data.excericesForMuscleStrength.spend
        ) +
        calculateEachActivityDose(
            engagedOnFlexibilityActivities,
            data.engagedOnFlexibilityActivities.value,
            data.engagedOnFlexibilityActivities.spend
        ) +
        calculateEachActivityDose(
            engagedOnLightHousework,
            data.engagedOnLightHousework.value,
            data.engagedOnLightHousework.spend
        ) +
        calculateEachActivityDose(
            engagedOnModerateHousework,
            data.engagedOnModerateHousework.value,
            data.engagedOnModerateHousework.spend
        ) +
        calculateEachActivityDose(
            engagedOnHeavyHousework,
            data.engagedOnHeavyHousework.value,
            data.engagedOnHeavyHousework.spend
        )
    );
};

const calculateEachActivityDose = (intensity, frequency, duration) => {
    if (_isNil(frequency)) {
        return 0;
    }
    return (
        intensity *
        parseInt(frequency) *
        (!_isNil(duration) ? parseInt(duration) : 0)
    );
};
