import React from "react";
import { useState, useCallback, useEffect } from "react";
import Plot from "react-plotly.js";
import { useGetList } from "react-admin";

function Scatter3DChart() {
    const [graphXData, setGraphXData] = useState([]);
    const [graphYData, setGraphYData] = useState([]);
    const [graphZData, setGraphZData] = useState([]);
    const { data } = useGetList("foo");

    const formatGraphData = useCallback((data) => {
        const xData = [];
        const yData = [];
        const zData = [];
        data &&
            data.length &&
            data.forEach((item) => {
                if (
                    item.calculation?.resilienceIndexCalculation?.TOTAL_Range_1_to_378 &&
                    item.calculation?.vulnerabilityScore?.totalScore_Range_2_to_20 &&
                    item.puzzleScore?.correctAnswer
                ) {
                    xData.push(
                        item.calculation.resilienceIndexCalculation
                            .TOTAL_Range_1_to_378
                    );
                    yData.push(
                        item.calculation.vulnerabilityScore
                            .totalScore_Range_2_to_20
                    );
                    zData.push(item.puzzleScore.correctAnswer);
                }
            });
        setGraphXData(xData);
        setGraphYData(yData);
        setGraphZData(zData);
    },[data]);

    useEffect(() => {
        formatGraphData(data);
    },[data])

    return (
        <div>
            <Plot
                data={[
                    {
                        x: [...graphXData],
                        y: [...graphYData],
                        z: [...graphZData],
                        type: "scatter3d",
                        mode: "markers",
                        marker: {
                            size: 10,
                            color: "#006064",
                            line: {
                                color: "orange",
                                width: 5
                            },
                            opacity: 0.8,
                        },
                    }
                ]}
                layout={{
                    margin: { l: 0, r: 0, b: 0, t: 0 },
                    width: 540,
                    height: 440,
                    title: "Graph Plot",
                    scene: {
                        xaxis:{title: 'RI'},
                        yaxis:{title: 'VI'},
                        zaxis:{title: 'NSCT'},
                        }
                }}
            />
        </div>
    );
}

export default Scatter3DChart;
