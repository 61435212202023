import React from "react";
import {
    Box,
    Typography,
    FormLabel,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper
} from "@mui/material";
import { RadioButtonGroupInput, SelectInput, required, FormDataConsumer } from "react-admin";

const SleepEvaluation = (props) => {
    return (
        <Box width='100%'>
            <Box
                borderBottom='1px solid'
                borderColor='grey.200'
                padding='10px 0'
            >
                <Typography variant='h6' fontWeight='bold' gutterBottom>
                    SLEEP EVALUATION
                </Typography>
                <Typography variant='body1' gutterBottom>
                    Please answer the following questions about (a) nighttime sleep behaviors, (b) sleep quality, and (c) daytime
                    sleepiness. You can answer based on your awareness of your sleep as well as any information others (e.g.,
                    spouse, bed partner) may have told you. The time frame is over the <b>past month</b>.
                </Typography>
            </Box>
            <Box py='20px'>
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 650 }}
                        size='small'
                        aria-label='simple table'
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell width="50%" style={{ fontSize: "1.1rem" }}>Nighttime Sleep</TableCell>
                                <TableCell>&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>1. Do you have trouble falling asleep?</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='sleepEvaluation.nightTimeSleep.troubleFallingAsleep'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "Never"
                                            },
                                            {
                                                id: "1",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "2",
                                                name: "Regular"
                                            },
                                            {
                                                id: "3",
                                                name: "Often"
                                            },
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>2. Do you wake up in the middle of the night?</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='sleepEvaluation.nightTimeSleep.wakeupInMiddleOfNight'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "Never"
                                            },
                                            {
                                                id: "1",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "2",
                                                name: "Regular"
                                            },
                                            {
                                                id: "3",
                                                name: "Often"
                                            },
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>3. Do you wake up too early in the morning?</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='sleepEvaluation.nightTimeSleep.wakeupTooEarlyInTheMorning'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "Never"
                                            },
                                            {
                                                id: "1",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "2",
                                                name: "Regular"
                                            },
                                            {
                                                id: "3",
                                                name: "Often"
                                            },
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>4. Do you wake up feeling tired or unrested?</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='sleepEvaluation.nightTimeSleep.wakeupFeelingTired'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "Never"
                                            },
                                            {
                                                id: "1",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "2",
                                                name: "Regular"
                                            },
                                            {
                                                id: "3",
                                                name: "Often"
                                            },
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>5. Do you feel like you do not get enough sleep at night?</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='sleepEvaluation.nightTimeSleep.doNotGetEnoughSleepAtNight'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "Never"
                                            },
                                            {
                                                id: "1",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "2",
                                                name: "Regular"
                                            },
                                            {
                                                id: "3",
                                                name: "Often"
                                            },
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 650 }}
                        size='small'
                        aria-label='simple table'
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell width="50%" style={{ fontSize: "1.1rem" }}>Sleep Quality</TableCell>
                                <TableCell>&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>1. Overall, how would rate the quality of your sleep</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='sleepEvaluation.overAllQualityOfSleep'
                                        label={false}
                                        choices={[
                                            {
                                                id: "5",
                                                name: "Excellent"
                                            },
                                            {
                                                id: "4",
                                                name: "Very Good"
                                            },
                                            {
                                                id: "3",
                                                name: "Good"
                                            },
                                            {
                                                id: "2",
                                                name: "Fair"
                                            },
                                            {
                                                id: "1",
                                                name: "poor"
                                            },
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 650 }}
                        size='small'
                        aria-label='simple table'
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell width="50%" style={{ fontSize: "1.1rem" }}>Sleep Behaviors</TableCell>
                                <TableCell>&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>1. Do you snore?</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='sleepEvaluation.sleepingBehaviors.snore'
                                        label={false}
                                        choices={[
                                            {
                                                id: true,
                                                name: "Yes"
                                            },
                                            {
                                                id: false,
                                                name: "No"
                                            },
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>2. Have you ever snorted, choked, or stopped breathing during sleep?</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='sleepEvaluation.sleepingBehaviors.stoppedBreatingDuringSleep'
                                        label={false}
                                        choices={[
                                            {
                                                id: true,
                                                name: "Yes"
                                            },
                                            {
                                                id: false,
                                                name: "No"
                                            },
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>3. Do you have vivid dreams or nightmares?</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='sleepEvaluation.sleepingBehaviors.nightmares'
                                        label={false}
                                        choices={[
                                            {
                                                id: true,
                                                name: "Yes"
                                            },
                                            {
                                                id: false,
                                                name: "No"
                                            },
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>4. Do you kick, punch, thrash, shout, or talk in your sleep?</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='sleepEvaluation.sleepingBehaviors.kickPunchThrashShoutTalkInSleep'
                                        label={false}
                                        choices={[
                                            {
                                                id: true,
                                                name: "Yes"
                                            },
                                            {
                                                id: false,
                                                name: "No"
                                            },
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>5. Do you have a restless, tingly feeling in your legs or an urge to move your legs that affects your ability to sleep?</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='sleepEvaluation.sleepingBehaviors.restless'
                                        label={false}
                                        choices={[
                                            {
                                                id: true,
                                                name: "Yes"
                                            },
                                            {
                                                id: false,
                                                name: "No"
                                            },
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>6. Do your legs jerk, twitch or move during sleep?</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='sleepEvaluation.sleepingBehaviors.legsJerk'
                                        label={false}
                                        choices={[
                                            {
                                                id: true,
                                                name: "Yes"
                                            },
                                            {
                                                id: false,
                                                name: "No"
                                            },
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>7. Do you grind your teeth during sleep?</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='sleepEvaluation.sleepingBehaviors.grindTeethDuringSleep'
                                        label={false}
                                        choices={[
                                            {
                                                id: true,
                                                name: "Yes"
                                            },
                                            {
                                                id: false,
                                                name: "No"
                                            },
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 650 }}
                        size='small'
                        aria-label='simple table'
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell width="50%" style={{ fontSize: "1.1rem" }}>Sleep Treatments</TableCell>
                                <TableCell>&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>1. Have you ever had an overnight sleep study?</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='sleepEvaluation.sleepTreatment.overNightSleepStudy'
                                        label={false}
                                        choices={[
                                            {
                                                id: true,
                                                name: "Yes"
                                            },
                                            {
                                                id: false,
                                                name: "No"
                                            },
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>2. Have you ever been diagnosed with a sleep disorder?</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='sleepEvaluation.sleepTreatment.diagnosedWithSleepDisorder.status'
                                        label={false}
                                        choices={[
                                            {
                                                id: true,
                                                name: "Yes"
                                            },
                                            {
                                                id: false,
                                                name: "No"
                                            },
                                        ]}
                                    ></RadioButtonGroupInput>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.sleepEvaluation?.sleepTreatment?.diagnosedWithSleepDisorder?.status === 'true' &&
                                            <Box>
                                                <SelectInput choices={[
                                                    { id: 'sleepApnea', name: 'Sleep apnea' },
                                                    { id: 'REMSleepBehaviorDisorder', name: 'REM Sleep Behavior Disorder' },
                                                    { id: 'restlessLegSyndrome', name: 'Restless Leg Syndrome' },
                                                    { id: 'periodicLegMovementsOfSleep', name: 'Periodic Leg Movements of Sleep' },
                                                    { id: 'nocturnalMyoclonus', name: 'Nocturnal Myoclonus' },
                                                    { id: 'other', name: 'Other' },
                                                ]} source="sleepEvaluation.sleepTreatment.diagnosedWithSleepDisorders.note" label="If Yes, describe:" variant='outlined'
                                                helperText={false} {...rest} validate={required()} />
                                            </Box>
                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>
                                    <FormLabel>3. Do you take an over-the-counter sleep aid?</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='sleepEvaluation.sleepTreatment.overTheCounterSleepAid'
                                        label={false}
                                        choices={[
                                            {
                                                id: true,
                                                name: "Yes"
                                            },
                                            {
                                                id: false,
                                                name: "No"
                                            },
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>4. Do you take a prescription sleep aid?</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='sleepEvaluation.sleepTreatment.prescriptionSleepAid'
                                        label={false}
                                        choices={[
                                            {
                                                id: true,
                                                name: "Yes"
                                            },
                                            {
                                                id: false,
                                                name: "No"
                                            },
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>5. Do you use a CPAP machine at night?</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='sleepEvaluation.sleepTreatment.CPAPMachineAtNight'
                                        label={false}
                                        choices={[
                                            {
                                                id: true,
                                                name: "Yes"
                                            },
                                            {
                                                id: false,
                                                name: "No"
                                            },
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>

                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default SleepEvaluation;
