import React from "react";
import {
    Box,
    Grid,
    Typography,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    FormLabel
} from "@mui/material";
import { RadioButtonGroupInput, TextInput, FormDataConsumer, required } from "react-admin";

const SelfReportPhyicalExamination = (props) => {

    return (
        <Box width='100%' mb="5px">
            <Typography variant='h6' gutterBottom>
                SELF REPORT PHYSICAL EXAMINATION
            </Typography>
            <Grid container columnSpacing={2} rowSpacing={1} mb={4}>
                <Grid item xs={4}>
                    <TextInput
                        fullWidth
                        source='selfReportPhyicalExamination.BP'
                        label='BP (sitting per patient report):'
                        variant='outlined'
                        type='text'
                        helperText={false}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextInput
                        fullWidth
                        source='selfReportPhyicalExamination.pulse'
                        label='Pulse (sitting per patient report):'
                        variant='outlined'
                        type='text'
                        helperText={false}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextInput
                        fullWidth
                        source='selfReportPhyicalExamination.height'
                        label='Height in meter (per report):'
                        variant='outlined'
                        type='text'
                        helperText={false}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextInput
                        fullWidth
                        source='selfReportPhyicalExamination.weight'
                        label='Weight in KG (per report):'
                        variant='outlined'
                        type='text'
                        helperText={false}
                    />
                </Grid>
                <Grid item xs={12} display="block" alignItems="end">
                    <Box>
                        <RadioButtonGroupInput row helperText={false} margin="none" label="Do you notice any Signs of Parkinson's Disease (slowness, stooped posture, tremor at rest)?" source="selfReportPhyicalExamination.parkinsonDisease.status" choices={[
                            { id: true, name: 'Yes' },
                            { id: false, name: 'No' },
                        ]}>
                        </RadioButtonGroupInput>
                    </Box>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => formData?.selfReportPhyicalExamination?.parkinsonDisease?.status === 'true' &&
                            <Box><TextInput fullWidth source="selfReportPhyicalExamination.parkinsonDisease.note" label="If Yes, describe:" variant='outlined'
                                type='text'
                                helperText={false} {...rest} />
                            </Box>
                        }
                    </FormDataConsumer>
                </Grid>
                <Grid item xs={12} display="block" alignItems="end">
                    <Box>
                        <RadioButtonGroupInput row helperText={false} margin="none" label="Do you notice any Signs of Stroke (weakness on one side of body, difficulty speaking)?" source="selfReportPhyicalExamination.signOfStroke.status" choices={[
                            { id: true, name: 'Yes' },
                            { id: false, name: 'No' },
                        ]}>
                        </RadioButtonGroupInput>
                    </Box>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => formData?.selfReportPhyicalExamination?.signOfStroke?.status === 'true' &&
                            <Box><TextInput fullWidth source="selfReportPhyicalExamination.signOfStroke.note" label="If Yes, describe:" variant='outlined'
                                type='text'
                                helperText={false} {...rest} />
                            </Box>
                        }
                    </FormDataConsumer>
                </Grid>
                <Grid item xs={12} display="block" alignItems="end">
                    <Box>
                        <RadioButtonGroupInput row helperText={false} margin="none" label="Do you have any Walking (Gait) Problems (shuffling, imbalance)?" source="selfReportPhyicalExamination.walkingProblem.status" choices={[
                            { id: true, name: 'Yes' },
                            { id: false, name: 'No' },
                        ]}>
                        </RadioButtonGroupInput>
                    </Box>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => formData?.selfReportPhyicalExamination?.walkingProblem?.status === 'true' &&
                            <Box><TextInput fullWidth source="selfReportPhyicalExamination.walkingProblem.note" label="If Yes, describe:" variant='outlined'
                                type='text'
                                helperText={false} {...rest} />
                            </Box>
                        }
                    </FormDataConsumer>
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell width="50%" style={{ fontSize: "1.1rem" }}>Over the past 6 months, have you experienced any of the following:</TableCell>
                            <TableCell>&nbsp; </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                <FormLabel>a. Unplanned weight loss</FormLabel>
                            </TableCell>
                            <TableCell>
                                <RadioButtonGroupInput
                                    row
                                    helperText={false}
                                    margin="none"
                                    source="selfReportPhyicalExamination.unplannedWeightLoss"
                                    label={false}
                                    choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                >
                                </RadioButtonGroupInput>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                <FormLabel>b. Exhaustion or lack of energy</FormLabel>
                            </TableCell>
                            <TableCell>
                                <RadioButtonGroupInput
                                    row
                                    helperText={false}
                                    margin="none"
                                    source="selfReportPhyicalExamination.lackOfEnergy"
                                    label={false}
                                    choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                >
                                </RadioButtonGroupInput>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                <FormLabel>c. Weakness in your grip or hand strength</FormLabel>
                            </TableCell>
                            <TableCell>
                                <RadioButtonGroupInput
                                    row
                                    helperText={false}
                                    margin="none"
                                    source="selfReportPhyicalExamination.weaknessInGrip"
                                    label={false}
                                    choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                >
                                </RadioButtonGroupInput>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                <FormLabel>d. Walking slower than you used to</FormLabel>
                            </TableCell>
                            <TableCell>
                                <RadioButtonGroupInput
                                    row
                                    helperText={false}
                                    margin="none"
                                    source="selfReportPhyicalExamination.walkingSlower"
                                    label={false}
                                    choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                >
                                </RadioButtonGroupInput>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                <FormLabel>e. Decreasing ability for physical activity</FormLabel>
                            </TableCell>
                            <TableCell>
                                <RadioButtonGroupInput
                                    row
                                    helperText={false}
                                    margin="none"
                                    source="selfReportPhyicalExamination.decreasingAbility"
                                    label={false}
                                    choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                >
                                </RadioButtonGroupInput>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Divider style={{ marginTop: "20px" }} />
        </Box>
    );
};

export default SelfReportPhyicalExamination;
