import BookIcon from "@mui/icons-material/Book";
import BrainHealthCreateForm from "./BrainHealthCreateForm";
import { BrainHealthPatientList } from "./BrainHealthPatientList";

export default {
    create: BrainHealthCreateForm,
    list: BrainHealthPatientList,
    icon: BookIcon,
    recordRepresentation: "title",
};
