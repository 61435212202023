import React from "react";
import { Toolbar, AppBar, Box, Typography } from "@mui/material";
import { UserMenu, Logout, LoadingIndicator, useAuthState, Menu } from "react-admin";
import PeopleIcon from '@mui/icons-material/People';

const Header = () => {
    const { authenticated } = useAuthState();

    return (
        <Box component='nav' sx={{ flexGrow: 1 }}>
            <AppBar position='static'>
                <Toolbar variant='dense'>
                    <Box flex={1} display='flex' justifyContent='space-between'>
                        <Box display='flex' alignItems='center'>
                            <Box
                                component='img'
                                sx={{ marginRight: "1em", height: 30 }}
                                src={
                                    "https://umiamibrainhealth.org/wp-content/uploads/2021/10/image001.png"
                                }
                                alt='Comprehensive Center for Brain Health'
                            />
                            <Typography component='span' variant='h5'>
                                Comprehensive Center for Brain Health
                            </Typography>
                        </Box>
                        {!authenticated && (
                            <Menu.Item to="/login" primaryText="Login" style={{color: "#fff"}} leftIcon={<PeopleIcon style={{color: "#fff"}} />}/>
                        )}
                        {authenticated && (
                            <Box display='flex'>
                                <LoadingIndicator
                                    sx={{
                                        "& .RaLoadingIndicator-loader": {
                                            marginTop: 2
                                        }
                                    }}
                                />

                                <UserMenu>
                                    <Logout />
                                </UserMenu>
                            </Box>
                        )}
                        
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
};

export default Header;
