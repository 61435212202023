import React from "react";
import {
    Box,
    Typography,
    FormLabel,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper
} from "@mui/material";
import { RadioButtonGroupInput, FormDataConsumer } from "react-admin";

const PhysicalActivityScale = (props) => {
    return (
        <Box width='100%'>
            <Box
                borderBottom='1px solid'
                borderColor='grey.200'
                padding='10px 0'
            >
                <Typography variant='h6' fontWeight='bold' gutterBottom>
                PHYSICAL ACTIVITY SCALE (QPAR)
                </Typography>
                <Typography variant='body1' gutterBottom>
                Please rate your physical activity over the <b>past 4 weeks</b>. Choose the <b>one best answer</b> for days per week and hours per day that best fits.
                </Typography>
            </Box>
            <Box py='20px'>
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 650 }}
                        size='small'
                        aria-label='simple table'
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell width="30%" style={{ fontSize: "1.1rem" }}>Type of Activity</TableCell>
                                <TableCell width="40%">How many days per week (Check One)</TableCell>
                                <TableCell>How many hours per day (Check One)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>1. Participate in sitting activities such as reading, book clubs, discussion groups, or handicrafts</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='physicalActivityScale.participantInSittingActivities.value'
                                        label={false}
                                        choices={[
                                            {
                                                id: 0,
                                                name: "Never"
                                            },
                                            {
                                                id: 1,
                                                name: "Seldom (1-2 days)"
                                            },
                                            {
                                                id: 2,
                                                name: "Sometimes (3-4 days)"
                                            },
                                            {
                                                id: 3,
                                                name: "Often (5-7 days)"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                                <TableCell scope='row'>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => !(formData?.physicalActivityScale?.participantInSittingActivities?.value === '0' || formData?.physicalActivityScale?.participantInSittingActivities?.value === undefined) &&
                                            <RadioButtonGroupInput
                                            row
                                            helperText={false}
                                            margin='none'
                                            source='physicalActivityScale.participantInSittingActivities.spend'
                                            label={false}
                                            {...rest}
                                            choices={[
                                                {
                                                    id: 1,
                                                    name: "Less than 1 hour"
                                                },
                                                {
                                                    id: 2,
                                                    name: "1-2 hours"
                                                },
                                                {
                                                    id: 3,
                                                    name: "More than 2 hours"
                                                },
                                            ]}
                                        ></RadioButtonGroupInput>
                                        }
                                    </FormDataConsumer>
                                    
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>2. Walk outside for any reason such as fun or exercise, walking the dog, in a mall, or around a track or path</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='physicalActivityScale.walkOutsideForAnyReason.value'
                                        label={false}
                                        choices={[
                                            {
                                                id: 0,
                                                name: "Never"
                                            },
                                            {
                                                id: 1,
                                                name: "Seldom (1-2 days)"
                                            },
                                            {
                                                id: 2,
                                                name: "Sometimes (3-4 days)"
                                            },
                                            {
                                                id: 3,
                                                name: "Often (5-7 days)"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                                <TableCell scope='row'>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => !(formData?.physicalActivityScale?.walkOutsideForAnyReason?.value === '0' || formData?.physicalActivityScale?.walkOutsideForAnyReason?.value === undefined) &&
                                            <RadioButtonGroupInput
                                            row
                                            helperText={false}
                                            margin='none'
                                            source='physicalActivityScale.walkOutsideForAnyReason.spend'
                                            label={false}
                                            {...rest}
                                            choices={[
                                                {
                                                    id: 1,
                                                    name: "Less than 1 hour"
                                                },
                                                {
                                                    id: 2,
                                                    name: "1-2 hours"
                                                },
                                                {
                                                    id: 3,
                                                    name: "More than 2 hours"
                                                },
                                            ]}
                                        ></RadioButtonGroupInput>
                                        }
                                    </FormDataConsumer>
                                    
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>3. Engage in light activities such as bowling, billiards, golf with a cart, shuffleboard, fishing, or playing catch</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='physicalActivityScale.engageOnLightActivities.value'
                                        label={false}
                                        choices={[
                                            {
                                                id: 0,
                                                name: "Never"
                                            },
                                            {
                                                id: 1,
                                                name: "Seldom (1-2 days)"
                                            },
                                            {
                                                id: 2,
                                                name: "Sometimes (3-4 days)"
                                            },
                                            {
                                                id: 3,
                                                name: "Often (5-7 days)"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                                <TableCell scope='row'>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => !(formData?.physicalActivityScale?.engageOnLightActivities?.value === '0' || formData?.physicalActivityScale?.engageOnLightActivities?.value === undefined) &&
                                            <RadioButtonGroupInput
                                            row
                                            helperText={false}
                                            margin='none'
                                            source='physicalActivityScale.engageOnLightActivities.spend'
                                            label={false}
                                            {...rest}
                                            choices={[
                                                {
                                                    id: 1,
                                                    name: "Less than 1 hour"
                                                },
                                                {
                                                    id: 2,
                                                    name: "1-2 hours"
                                                },
                                                {
                                                    id: 3,
                                                    name: "More than 2 hours"
                                                },
                                            ]}
                                        ></RadioButtonGroupInput>
                                        }
                                    </FormDataConsumer>
                                    
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>4. Engage in moderate activities such as doubles tennis, dancing, hunting, skating, golf without a cart, or hiking (flat terrain)</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='physicalActivityScale.engageModerateActivities.value'
                                        label={false}
                                        choices={[
                                            {
                                                id: 0,
                                                name: "Never"
                                            },
                                            {
                                                id: 1,
                                                name: "Seldom (1-2 days)"
                                            },
                                            {
                                                id: 2,
                                                name: "Sometimes (3-4 days)"
                                            },
                                            {
                                                id: 3,
                                                name: "Often (5-7 days)"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                                <TableCell scope='row'>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => !(formData?.physicalActivityScale?.engageModerateActivities?.value === '0' || formData?.physicalActivityScale?.engageModerateActivities?.value === undefined) &&
                                            <RadioButtonGroupInput
                                            row
                                            helperText={false}
                                            margin='none'
                                            source='physicalActivityScale.engageModerateActivities.spend'
                                            label={false}
                                            {...rest}
                                            choices={[
                                                {
                                                    id: 1,
                                                    name: "Less than 1 hour"
                                                },
                                                {
                                                    id: 2,
                                                    name: "1-2 hours"
                                                },
                                                {
                                                    id: 3,
                                                    name: "More than 2 hours"
                                                },
                                            ]}
                                        ></RadioButtonGroupInput>
                                        }
                                    </FormDataConsumer>
                                    
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>5. Engage in strenuous activities such as jogging, swimming, cycling, singles tennis, skiing, hiking (hilly terrain), or climbing stairs for exercise</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='physicalActivityScale.engageStrenuousActivities.value'
                                        label={false}
                                        choices={[
                                            {
                                                id: 0,
                                                name: "Never"
                                            },
                                            {
                                                id: 1,
                                                name: "Seldom (1-2 days)"
                                            },
                                            {
                                                id: 2,
                                                name: "Sometimes (3-4 days)"
                                            },
                                            {
                                                id: 3,
                                                name: "Often (5-7 days)"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                                <TableCell scope='row'>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => !(formData?.physicalActivityScale?.engageStrenuousActivities?.value === '0' || formData?.physicalActivityScale?.engageStrenuousActivities?.value === undefined) &&
                                            <RadioButtonGroupInput
                                            row
                                            helperText={false}
                                            margin='none'
                                            source='physicalActivityScale.engageStrenuousActivities.spend'
                                            label={false}
                                            {...rest}
                                            choices={[
                                                {
                                                    id: 1,
                                                    name: "Less than 1 hour"
                                                },
                                                {
                                                    id: 2,
                                                    name: "1-2 hours"
                                                },
                                                {
                                                    id: 3,
                                                    name: "More than 2 hours"
                                                },
                                            ]}
                                        ></RadioButtonGroupInput>
                                        }
                                    </FormDataConsumer>
                                    
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>6. Any exercises to increase muscle strength or endurance, such as lifting weights, pushups, pullups, or chin-ups</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='physicalActivityScale.excericesForMuscleStrength.value'
                                        label={false}
                                        choices={[
                                            {
                                                id: 0,
                                                name: "Never"
                                            },
                                            {
                                                id: 1,
                                                name: "Seldom (1-2 days)"
                                            },
                                            {
                                                id: 2,
                                                name: "Sometimes (3-4 days)"
                                            },
                                            {
                                                id: 3,
                                                name: "Often (5-7 days)"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                                <TableCell scope='row'>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => !(formData?.physicalActivityScale?.excericesForMuscleStrength?.value === '0' || formData?.physicalActivityScale?.excericesForMuscleStrength?.value === undefined) &&
                                            <RadioButtonGroupInput
                                            row
                                            helperText={false}
                                            margin='none'
                                            source='physicalActivityScale.excericesForMuscleStrength.spend'
                                            label={false}
                                            {...rest}
                                            choices={[
                                                {
                                                    id: 1,
                                                    name: "Less than 1 hour"
                                                },
                                                {
                                                    id: 2,
                                                    name: "1-2 hours"
                                                },
                                                {
                                                    id: 3,
                                                    name: "More than 2 hours"
                                                },
                                            ]}
                                        ></RadioButtonGroupInput>
                                        }
                                    </FormDataConsumer>
                                    
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>7. Engage in flexibility activities such as stretching, yoga, chair yoga, or Tai Chi</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='physicalActivityScale.engagedOnFlexibilityActivities.value'
                                        label={false}
                                        choices={[
                                            {
                                                id: 0,
                                                name: "Never"
                                            },
                                            {
                                                id: 1,
                                                name: "Seldom (1-2 days)"
                                            },
                                            {
                                                id: 2,
                                                name: "Sometimes (3-4 days)"
                                            },
                                            {
                                                id: 3,
                                                name: "Often (5-7 days)"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                                <TableCell scope='row'>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => !(formData?.physicalActivityScale?.engagedOnFlexibilityActivities?.value === '0' || formData?.physicalActivityScale?.engagedOnFlexibilityActivities?.value === undefined) &&
                                            <RadioButtonGroupInput
                                            row
                                            helperText={false}
                                            margin='none'
                                            source='physicalActivityScale.engagedOnFlexibilityActivities.spend'
                                            label={false}
                                            {...rest}
                                            choices={[
                                                {
                                                    id: 1,
                                                    name: "Less than 1 hour"
                                                },
                                                {
                                                    id: 2,
                                                    name: "1-2 hours"
                                                },
                                                {
                                                    id: 3,
                                                    name: "More than 2 hours"
                                                },
                                            ]}
                                        ></RadioButtonGroupInput>
                                        }
                                    </FormDataConsumer>
                                    
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>8. Any light housework or labor, such as dusting, washing dishes, mopping floors, ironing, or office work</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='physicalActivityScale.engagedOnLightHousework.value'
                                        label={false}
                                        choices={[
                                            {
                                                id: 0,
                                                name: "Never"
                                            },
                                            {
                                                id: 1,
                                                name: "Seldom (1-2 days)"
                                            },
                                            {
                                                id: 2,
                                                name: "Sometimes (3-4 days)"
                                            },
                                            {
                                                id: 3,
                                                name: "Often (5-7 days)"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                                <TableCell scope='row'>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => !(formData?.physicalActivityScale?.engagedOnLightHousework?.value === '0' || formData?.physicalActivityScale?.engagedOnLightHousework?.value === undefined) &&
                                            <RadioButtonGroupInput
                                            row
                                            helperText={false}
                                            margin='none'
                                            source='physicalActivityScale.engagedOnLightHousework.spend'
                                            label={false}
                                            {...rest}
                                            choices={[
                                                {
                                                    id: 1,
                                                    name: "Less than 1 hour"
                                                },
                                                {
                                                    id: 2,
                                                    name: "1-2 hours"
                                                },
                                                {
                                                    id: 3,
                                                    name: "More than 2 hours"
                                                },
                                            ]}
                                        ></RadioButtonGroupInput>
                                        }
                                    </FormDataConsumer>
                                    
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>9. Any moderate housework or labor such as vacuuming, washing windows, scrubbing floors, laundry, or moderate manual labor</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='physicalActivityScale.engagedOnModerateHousework.value'
                                        label={false}
                                        choices={[
                                            {
                                                id: 0,
                                                name: "Never"
                                            },
                                            {
                                                id: 1,
                                                name: "Seldom (1-2 days)"
                                            },
                                            {
                                                id: 2,
                                                name: "Sometimes (3-4 days)"
                                            },
                                            {
                                                id: 3,
                                                name: "Often (5-7 days)"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                                <TableCell scope='row'>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => !(formData?.physicalActivityScale?.engagedOnModerateHousework?.value === '0' || formData?.physicalActivityScale?.engagedOnModerateHousework?.value === undefined) &&
                                            <RadioButtonGroupInput
                                            row
                                            helperText={false}
                                            margin='none'
                                            source='physicalActivityScale.engagedOnModerateHousework.spend'
                                            label={false}
                                            {...rest}
                                            choices={[
                                                {
                                                    id: 1,
                                                    name: "Less than 1 hour"
                                                },
                                                {
                                                    id: 2,
                                                    name: "1-2 hours"
                                                },
                                                {
                                                    id: 3,
                                                    name: "More than 2 hours"
                                                },
                                            ]}
                                        ></RadioButtonGroupInput>
                                        }
                                    </FormDataConsumer>
                                    
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>10. Any heavy housework or labor such as home repairs, painting, yard work, moving furniture, or heavy manual labor</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='physicalActivityScale.engagedOnHeavyHousework.value'
                                        label={false}
                                        choices={[
                                            {
                                                id: 0,
                                                name: "Never"
                                            },
                                            {
                                                id: 1,
                                                name: "Seldom (1-2 days)"
                                            },
                                            {
                                                id: 2,
                                                name: "Sometimes (3-4 days)"
                                            },
                                            {
                                                id: 3,
                                                name: "Often (5-7 days)"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                                <TableCell scope='row'>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => !(formData?.physicalActivityScale?.engagedOnHeavyHousework?.value === '0' || formData?.physicalActivityScale?.engagedOnHeavyHousework?.value === undefined) &&
                                            <RadioButtonGroupInput
                                            row
                                            helperText={false}
                                            margin='none'
                                            source='physicalActivityScale.engagedOnHeavyHousework.spend'
                                            label={false}
                                            {...rest}
                                            choices={[
                                                {
                                                    id: 1,
                                                    name: "Less than 1 hour"
                                                },
                                                {
                                                    id: 2,
                                                    name: "1-2 hours"
                                                },
                                                {
                                                    id: 3,
                                                    name: "More than 2 hours"
                                                },
                                            ]}
                                        ></RadioButtonGroupInput>
                                        }
                                    </FormDataConsumer>
                                    
                                </TableCell>
                            </TableRow>
                            
                                     
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default PhysicalActivityScale;
