export const occupationClassification = [
    {
        id: 'actuaries',
        name: 'Actuaries',
        category: 'Senior-Level Manager/Professional',
        score: 1,
    },
    {
        id: 'architects',
        name: 'Architects',
        category: 'Senior-Level Manager/Professional',
        score: 1,
    },
    {
        id: 'bankOfficers',
        name: 'Bank Officers',
        category: 'Senior-Level Manager/Professional',
        score: 1,
    },
    {
        id: 'biologicScientists',
        name: 'Biologic Scientists',
        category: 'Senior-Level Manager/Professional',
        score: 1,
    },
    {
        id: 'dentists',
        name: 'Dentists',
        category: 'Senior-Level Manager/Professional',
        score: 1,
    },
    {
        id: 'economists',
        name: 'Economists',
        category: 'Senior-Level Manager/Professional',
        score: 1,
    },
    {
        id: 'financialManagers',
        name: 'Financial Managers',
        category: 'Senior-Level Manager/Professional',
        score: 1,
    },
    {
        id: 'governmentOfficials',
        name: 'Government Officials (Federal, State, Local)',
        category: 'Senior-Level Manager/Professional',
        score: 1,
    },
    {
        id: 'healthAdministrators',
        name: 'Health Administrators',
        category: 'Senior-Level Manager/Professional',
        score: 1,
    },
    {
        id: 'higherExecutives',
        name: 'Higher Executives',
        category: 'Senior-Level Manager/Professional',
        score: 1,
    },
    {
        id: 'judges',
        name: 'Judges',
        category: 'Senior-Level Manager/Professional',
        score: 1,
    },
    {
        id: 'lawyers',
        name: 'Lawyers',
        category: 'Senior-Level Manager/Professional',
        score: 1,
    },
    {
        id: 'mathematicians',
        name: 'Mathematicians',
        category: 'Senior-Level Manager/Professional',
        score: 1,
    },
    {
        id: 'militaryOfficersMajorsLieutenantCommandersAndAbove',
        name: 'Military Officers (Majors, Lieutenant Commanders and above)',
        category: 'Senior-Level Manager/Professional',
        score: 1,
    },
    {
        id: 'physicalScientists',
        name: 'Physical Scientists',
        category: 'Senior-Level Manager/Professional',
        score: 1,
    },
    {
        id: 'physicians',
        name: 'Physicians',
        category: 'Senior-Level Manager/Professional',
        score: 1,
    },
    {
        id: 'psychologists',
        name: 'Psychologists',
        category: 'Senior-Level Manager/Professional',
        score: 1,
    },
    {
        id: 'sociologists',
        name: 'Sociologists',
        category: 'Senior-Level Manager/Professional',
        score: 1,
    },
    {
        id: 'teachersCollegeProfessorsAndCoaches',
        name: 'Teachers (College Professors and Coaches)',
        category: 'Senior-Level Manager/Professional',
        score: 1,
    },
    {
        id: 'urbanAndRegionalPlanners',
        name: 'Urban and Regional Planners',
        category: 'Senior-Level Manager/Professional',
        score: 1,
    },
    {
        id: 'veterinarians',
        name: 'Veterinarians',
        category: 'Senior-Level Manager/Professional',
        score: 1,
    },
    {
        id: 'engineerNotTrainOrIndustrial',
        name: 'Engineer (not train or industrial)',
        category: 'Senior-Level Manager/Professional',
        score: 1,
    },

    {
        id: 'accountants',
        name: 'Accountants',
        category: 'Mid-Level Manager/Professional',
        score: 2,
    },
    {
        id: 'administrativeOfficers',
        name: 'Administrative Officers',
        category: 'Mid-Level Manager/Professional',
        score: 2,
    },
    {
        id: 'authors',
        name: 'Authors',
        category: 'Mid-Level Manager/Professional',
        score: 2,
    },
    {
        id: 'chiropractors',
        name: 'Chiropractors',
        category: 'Mid-Level Manager/Professional',
        score: 2,
    },
    {
        id: 'clergymen',
        name: 'Clergymen',
        category: 'Mid-Level Manager/Professional',
        score: 2,
    },
    {
        id: 'computerSystemsAnalysts',
        name: 'Computer Systems Analysts',
        category: 'Mid-Level Manager/Professional',
        score: 2,
    },
    {
        id: 'computerSpecialists',
        name: 'Computer Specialists',
        category: 'Mid-Level Manager/Professional',
        score: 2,
    },
    {
        id: 'softwareDeveloperProgrammer',
        name: 'Software Developer/Programmer',
        category: 'Mid-Level Manager/Professional',
        score: 2,
    },
    {
        id: 'Editors',
        name: 'Editors',
        category: 'Mid-Level Manager/Professional',
        score: 2,
    },
    {
        id: 'industrialEngineers',
        name: 'Industrial Engineers',
        category: 'Mid-Level Manager/Professional',
        score: 2,
    },
    {
        id: 'librarians',
        name: 'Librarians',
        category: 'Mid-Level Manager/Professional',
        score: 2,
    },
    {
        id: 'MilitaryOfficersLieutenantsCapts',
        name: 'Military Officers (Lieutenants, Capts)',
        category: 'Mid-Level Manager/Professional',
        score: 2,
    },
    {
        id: 'musiciansComposers',
        name: 'Musicians/Composers',
        category: 'Mid-Level Manager/Professional',
        score: 2,
    },
    {
        id: 'registeredNurses',
        name: 'Registered Nurses',
        category: 'Mid-Level Manager/Professional',
        score: 2,
    },
    {
        id: 'publicAdministrationOfficials',
        name: 'Public Administration Officials',
        category: 'Mid-Level Manager/Professional',
        score: 2,
    },
    {
        id: 'pharmacists',
        name: 'Pharmacists',
        category: 'Mid-Level Manager/Professional',
        score: 2,
    },
    {
        id: 'pilots',
        name: 'Pilots',
        category: 'Mid-Level Manager/Professional',
        score: 2,
    },
    {
        id: 'schoolAdministrators',
        name: 'School Administrators',
        category: 'Mid-Level Manager/Professional',
        score: 2,
    },
    {
        id: 'teachersHighSchool)',
        name: 'Teachers (High School)',
        category: 'Mid-Level Manager/Professional',
        score: 2,
    },
    {
        id: 'treasurers',
        name: 'Treasurers',
        category: 'Mid-Level Manager/Professional',
        score: 2,
    },
    {
        id: 'ITManager',
        name: 'IT Manager',
        category: 'Mid-Level Manager/Professional',
        score: 2,
    },

    {
        id: 'Appraisers',
        name: 'Appraisers',
        category: 'Small Business Owner/Salaried',
        score: 3,
    },
    {
        id: 'buyersWholesaleRetail',
        name: 'Buyers (wholesale/retail)',
        category: 'Small Business Owner/Salaried',
        score: 3,
    },
    {
        id: 'computerProgrammers',
        name: 'Computer Programmers',
        category: 'Small Business Owner/Salaried',
        score: 3,
    },
    {
        id: 'designers',
        name: 'Designers',
        category: 'Small Business Owner/Salaried',
        score: 3,
    },
    {
        id: 'entertainersArtists',
        name: 'Entertainers/Artists',
        category: 'Small Business Owner/Salaried',
        score: 3,
    },
    {
        id: 'funeralDirectors',
        name: 'Funeral Directors',
        category: 'Small Business Owner/Salaried',
        score: 3,
    },
    {
        id: 'healthPracticioners',
        name: 'Health Practicioners',
        category: 'Small Business Owner/Salaried',
        score: 3,
    },
    {
        id: 'insuranceAgentsAdjusters',
        name: 'Insurance Agents/Adjusters',
        category: 'Small Business Owner/Salaried',
        score: 3,
    },
    {
        id: 'managersOfficeAdministration',
        name: 'Managers(office/administration)',
        category: 'Small Business Owner/Salaried',
        score: 3,
    },
    {
        id: 'postmasters',
        name: 'Postmasters',
        category: 'Small Business Owner/Salaried',
        score: 3,
    },
    {
        id: 'publicRelations',
        name: 'Public Relations',
        category: 'Small Business Owner/Salaried',
        score: 3,
    },
    {
        id: 'purchasingAgents',
        name: 'Purchasing Agents',
        category: 'Small Business Owner/Salaried',
        score: 3,
    },
    {
        id: 'realEstateBrokersAgents',
        name: 'Real Estate Brokers/Agents',
        category: 'Small Business Owner/Salaried',
        score: 3,
    },
    {
        id: 'reporters',
        name: 'Reporters',
        category: 'Small Business Owner/Salaried',
        score: 3,
    },
    {
        id: 'salesManagersNotRetail',
        name: 'Sales Managers (not retail)',
        category: 'Small Business Owner/Salaried',
        score: 3,
    },
    {
        id: 'socialWorkers',
        name: 'Social Workers',
        category: 'Small Business Owner/Salaried',
        score: 3,
    },
    {
        id: 'stockBondSalesmen',
        name: 'Stock/Bond Salesmen',
        category: 'Small Business Owner/Salaried',
        score: 3,
    },
    {
        id: 'surveyors',
        name: 'Surveyors',
        category: 'Small Business Owner/Salaried',
        score: 3,
    },
    {
        id: 'teachersK-8',
        name: 'Teachers (K-8)',
        category: 'Small Business Owner/Salaried',
        score: 3,
    },
    {
        id: 'vocationalEducationalCounselors',
        name: 'Vocational/Educational Counselors',
        category: 'Small Business Owner/Salaried',
        score: 3,
    },
    {
        id: 'writers',
        name: 'Writers',
        category: 'Small Business Owner/Salaried',
        score: 3,
    },
    {
        id: 'graphicDesigner',
        name: 'Graphic Designer',
        category: 'Small Business Owner/Salaried',
        score: 3,
    },

    {
        id: 'adminAssistants',
        name: 'Admin Assistants',
        category: 'Tech/Semi-Professional',
        score: 4,
    },
    {
        id: 'advertisingAgent',
        name: 'Advertising Agent',
        category: 'Tech/Semi-Professional',
        score: 4,
    },
    {
        id: 'airTrafficControllers',
        name: 'Air Traffic Controllers',
        category: 'Tech/Semi-Professional',
        score: 4,
    },
    {
        id: 'payrollClerk',
        name: 'Payroll Clerk',
        category: 'Tech/Semi-Professional',
        score: 4,
    },
    {
        id: 'sentalHygienists',
        name: 'Dental Hygienists',
        category: 'Tech/Semi-Professional',
        score: 4,
    },
    {
        id: 'dietitians',
        name: 'Dietitians',
        category: 'Tech/Semi-Professional',
        score: 4,
    },
    {
        id: 'embalmers',
        name: 'Embalmers',
        category: 'Tech/Semi-Professional',
        score: 4,
    },
    {
        id: 'foremenConstructionInspectors',
        name: 'Foremen/Construction Inspectors',
        category: 'Tech/Semi-Professional',
        score: 4,
    },
    {
        id: 'photographers',
        name: 'Photographers',
        category: 'Tech/Semi-Professional',
        score: 4,
    },
    {
        id: 'religiousWorkers',
        name: 'Religious Workers',
        category: 'Tech/Semi-Professional',
        score: 4,
    },
    {
        id: 'researchWorkers',
        name: 'Research Workers',
        category: 'Tech/Semi-Professional',
        score: 4,
    },
    {
        id: 'retailSalesManagers',
        name: 'Retail Sales Managers',
        category: 'Tech/Semi-Professional',
        score: 4,
    },
    {
        id: 'wholesaleSalesReps',
        name: 'Wholesale Sales Reps',
        category: 'Tech/Semi-Professional',
        score: 4,
    },
    {
        id: 'secretaries',
        name: 'Secretaries',
        category: 'Tech/Semi-Professional',
        score: 4,
    },
    {
        id: 'sheriffsBailiffs',
        name: 'Sheriffs/Bailiffs',
        category: 'Tech/Semi-Professional',
        score: 4,
    },
    {
        id: 'teacherAides',
        name: 'Teacher Aides',
        category: 'Tech/Semi-Professional',
        score: 4,
    },
    {
        id: 'technicians',
        name: 'Technicians',
        category: 'Tech/Semi-Professional',
        score: 4,
    },


    {
        id: 'auctioneers',
        name: 'Auctioneers',
        category: 'Clerical/Sales',
        score: 4,
    },
    {
        id: 'bankTellers',
        name: 'Bank Tellers',
        category: 'Clerical/Sales',
        score: 4,
    },
    {
        id: 'billCollectors',
        name: 'Bill Collectors',
        category: 'Clerical/Sales',
        score: 4,
    },
    {
        id: 'billingClerks',
        name: 'Billing Clerks',
        category: 'Clerical/Sales',
        score: 4,
    },
    {
        id: 'bookkeepers',
        name: 'Bookkeepers',
        category: 'Clerical/Sales',
        score: 4,
    },
    {
        id: 'cashiers',
        name: 'Cashiers',
        category: 'Clerical/Sales',
        score: 4,
    },
    {
        id: 'clericalWorkers',
        name: 'Clerical Workers',
        category: 'Clerical/Sales',
        score: 4,
    },
    {
        id: 'dentalAssistant',
        name: 'Dental Assistant',
        category: 'Clerical/Sales',
        score: 4,
    },
    {
        id: 'libraryAssistant',
        name: 'Library Assistant',
        category: 'Clerical/Sales',
        score: 4,
    },
    {
        id: 'recreationWorkers',
        name: 'Recreation Workers',
        category: 'Clerical/Sales',
        score: 4,
    },


    {
        id: 'flightAttendant',
        name: 'Flight Attendant',
        category: 'Skilled Worker',
        score: 5,
    },
    {
        id: 'baker',
        name: 'Baker',
        category: 'Skilled Worker',
        score: 5,
    },
    {
        id: 'carpenter',
        name: 'Carpenter',
        category: 'Skilled Worker',
        score: 5,
    },
    {
        id: 'shippingReceivingClerk',
        name: 'Shipping/Receiving Clerk',
        category: 'Skilled Worker',
        score: 5,
    },
    {
        id: 'counterClerk',
        name: 'Counter Clerk',
        category: 'Skilled Worker',
        score: 5,
    },
    {
        id: 'detective',
        name: 'Detective',
        category: 'Skilled Worker',
        score: 5,
    },
    {
        id: 'dispatcher',
        name: 'Dispatcher',
        category: 'Skilled Worker',
        score: 5,
    },
    {
        id: 'electrician',
        name: 'Electrician',
        category: 'Skilled Worker',
        score: 5,
    },
    {
        id: 'trainEngineer',
        name: 'Train Engineer',
        category: 'Skilled Worker',
        score: 5,
    },
    {
        id: 'fireManWoman',
        name: 'Fireman/woman',
        category: 'Skilled Worker',
        score: 5,
    },
    {
        id: 'jeweler',
        name: 'Jeweler',
        category: 'Skilled Worker',
        score: 5,
    },
    {
        id: 'machinist',
        name: 'Machinist',
        category: 'Skilled Worker',
        score: 5,
    },
    {
        id: 'mailCarrierPostalClerk',
        name: 'Mail Carrier/Postal Clerk',
        category: 'Skilled Worker',
        score: 5,
    },
    {
        id: 'barRestaurantManager',
        name: 'Bar/Restaurant Manager',
        category: 'Skilled Worker',
        score: 5,
    },
    {
        id: 'mechanic',
        name: 'Mechanic',
        category: 'Skilled Worker',
        score: 5,
    },
    {
        id: 'plumber',
        name: 'Plumber',
        category: 'Skilled Worker',
        score: 5,
    },
    {
        id: 'receptionist',
        name: 'Receptionist',
        category: 'Skilled Worker',
        score: 5,
    },
    {
        id: 'stockClerk',
        name: 'Stock Clerk',
        category: 'Skilled Worker',
        score: 5,
    },

    {
        id: 'animalCaretaker',
        name: 'Animal Caretaker',
        category: 'Semi-Skilled Ctd',
        score: 6,
    },
    {
        id: 'barber',
        name: 'Barber',
        category: 'Semi-Skilled Ctd',
        score: 6,
    },
    {
        id: 'innKeeper',
        name: 'Inn Keeper',
        category: 'Semi-Skilled Ctd',
        score: 6,
    },
    {
        id: 'truckBusDriver',
        name: 'Truck/Bus Driver',
        category: 'Semi-Skilled Ctd',
        score: 6,
    },
    {
        id: 'chaufferCabDriver',
        name: 'Chauffer/Cab Driver',
        category: 'Semi-Skilled Ctd',
        score: 6,
    },
    {
        id: 'dayCareWorker',
        name: 'Day Care Worker',
        category: 'Semi-Skilled Ctd',
        score: 6,
    },
    {
        id: 'trainConductor',
        name: 'Train Conductor',
        category: 'Semi-Skilled Ctd',
        score: 6,
    },
    {
        id: 'deliverymen',
        name: 'Deliverymen',
        category: 'Semi-Skilled Ctd',
        score: 6,
    },
    {
        id: 'tailorSeamstress',
        name: 'Tailor/Seamstress',
        category: 'Semi-Skilled Ctd',
        score: 6,
    },

    {
        id: 'constructionEquipOpertaorForkliftCranes',
        name: 'Construction Equip Opertaor (forklift,cranes etc)',
        category: 'Machine/Semi-skilled Worker',
        score: 6,
    },
    {
        id: 'factoryMachineOperator',
        name: 'Factory Machine Operator',
        category: 'Machine/Semi-skilled Worker',
        score: 6,
    },
    {
        id: 'fileClerks',
        name: 'File Clerks',
        category: 'Machine/Semi-skilled Worker',
        score: 6,
    },
    {
        id: 'fisherman',
        name: 'Fisherman',
        category: 'Machine/Semi-skilled Worker',
        score: 6,
    },
    {
        id: 'furnitureFinishers',
        name: 'Furniture Finishers',
        category: 'Machine/Semi-skilled Worker',
        score: 6,
    },
    {
        id: 'guardWatchman',
        name: 'Guard/Watchman',
        category: 'Machine/Semi-skilled Worker',
        score: 6,
    },
    {
        id: 'hairdresserCosmetologist',
        name: 'Hairdresser/Cosmetologist',
        category: 'Machine/Semi-skilled Worker',
        score: 6,
    },
    {
        id: 'healthAidesCNA',
        name: 'Health Aides/CNA',
        category: 'Machine/Semi-skilled Worker',
        score: 6,
    },
    {
        id: 'butcher',
        name: 'Butcher',
        category: 'Machine/Semi-skilled Worker',
        score: 6,
    },
    {
        id: 'painterNonArtist',
        name: 'Painter (non artist)',
        category: 'Machine/Semi-skilled Worker',
        score: 6,
    },
    {
        id: 'memberOfArmedServices',
        name: 'Member of Armed Services (other than noncommissioned officers)',
        category: 'Machine/Semi-skilled Worker',
        score: 6,
    },

    {
        id: 'bartenders',
        name: 'Bartenders',
        category: 'Unskilled/Service Worker',
        score: 7,
    },
    {
        id: 'busboys',
        name: 'Busboys',
        category: 'Unskilled/Service Worker',
        score: 7,
    },
    {
        id: 'inHomeChildCareWorkers',
        name: 'In-Home Child Care Workers',
        category: 'Unskilled/Service Worker',
        score: 7,
    },
    {
        id: 'constructionWorkers',
        name: 'Construction Workers',
        category: 'Unskilled/Service Worker',
        score: 7,
    },
    {
        id: 'cooks',
        name: 'Cooks',
        category: 'Unskilled/Service Worker',
        score: 7,
    },
    {
        id: 'crossingGuards',
        name: 'Crossing Guards',
        category: 'Unskilled/Service Worker',
        score: 7,
    },
    {
        id: 'foodServicePrepOrServing',
        name: 'Food Service (prep or serving)',
        category: 'Unskilled/Service Worker',
        score: 7,
    },
    {
        id: 'garbageCollectors',
        name: 'Garbage Collectors',
        category: 'Unskilled/Service Worker',
        score: 7,
    },
    {
        id: 'groundskeepers',
        name: 'Groundskeepers',
        category: 'Unskilled/Service Worker',
        score: 7,
    },
    {
        id: 'lumbermen',
        name: 'Lumbermen',
        category: 'Unskilled/Service Worker',
        score: 7,
    },
    {
        id: 'parkingAttendants',
        name: 'Parking Attendants',
        category: 'Unskilled/Service Worker',
        score: 7,
    },
    {
        id: 'schoolMonitors',
        name: 'School Monitors',
        category: 'Unskilled/Service Worker',
        score: 7,
    },
    {
        id: 'waiterWaitress',
        name: 'Waiter/Waitress',
        category: 'Unskilled/Service Worker',
        score: 7,
    },
    {
        id: 'warehousemenStockmen',
        name: 'Warehousemen (stockmen)',
        category: 'Unskilled/Service Worker',
        score: 7,
    },

    {
        id: 'attendants',
        name: 'Attendants',
        category: 'Unskilled Farm/Laborer',
        score: 7,
    },
    {
        id: 'bellhops',
        name: 'Bellhops',
        category: 'Unskilled Farm/Laborer',
        score: 7,
    },
    {
        id: 'cleaners',
        name: 'Cleaners',
        category: 'Unskilled Farm/Laborer',
        score: 7,
    },
    {
        id: 'dishwashers',
        name: 'Dishwashers',
        category: 'Unskilled Farm/Laborer',
        score: 7,
    },
    {
        id: 'farmHandLaborer',
        name: 'Farm Hand/Laborer',
        category: 'Unskilled Farm/Laborer',
        score: 7,
    },
    {
        id: 'janitor',
        name: 'Janitor',
        category: 'Unskilled Farm/Laborer',
        score: 7,
    },
    {
        id: 'maid',
        name: 'Maid',
        category: 'Unskilled Farm/Laborer',
        score: 7,
    },
    {
        id: 'newsboy',
        name: 'Newsboy',
        category: 'Unskilled Farm/Laborer',
        score: 7,
    },
    {
        id: 'privateHouseholdWorker',
        name: 'Private Household Worker',
        category: 'Unskilled Farm/Laborer',
        score: 7,
    },
    {
        id: 'ushers',
        name: 'Ushers',
        category: 'Unskilled Farm/Laborer',
        score: 7,
    },
];
