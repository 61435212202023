import { defaultTheme } from "react-admin";
import red from "@mui/material/colors/red";
import cyan from "@mui/material/colors/cyan";
import orange from "@mui/material/colors/orange";

export const myTheme = {
    ...defaultTheme,
    palette: {
        primary: {
            main: cyan[900]
        },
        secondary: {
            main: orange[900]
        },
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Arial",
            "sans-serif"
        ].join(",")
    },
};
