import React from "react";
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
} from "@mui/material";
import { RadioButtonGroupInput } from "react-admin";

const SymptomRatings = (props) => {
    return (
        <Box width='100%'>
            <Box
                borderTop='1px solid'
                borderBottom='1px solid'
                borderColor='grey.200'
                padding='10px 0'
            >
                <Typography variant='h6' gutterBottom>
                    SYMPTOM RATINGS
                </Typography>
                <Typography variant='body1' gutterBottom>
                    The following descriptions characterize changes you may have
                    noticed in your cognitive and functional abilities. The
                    important thing to consider is comparing how your memory and
                    thinking abilities are <b>now</b>
                    compared with how they <b>used</b> to be – the key feature
                    is <b>change</b>.
                </Typography>
                <Typography variant='body1' gutterBottom>
                    Choose the <b>one best answer</b> for each category that
                    best describes you – Note: not all the descriptions need to
                    be present to choose an answer. Please make only one choice
                    per category.
                </Typography>
            </Box>
            <Box py='20px'>
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 650 }}
                        size='small'
                        aria-label='simple table'
                    >
                        <TableBody>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row={false}
                                        helperText={false}
                                        margin='none'
                                        source='symptomRating.memoryAndRecall'
                                        label='1. MEMORY AND RECALL'
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "No obvious memory loss or slight inconsistent forgetfulness that does not interfere with your everyday function"
                                            },
                                            {
                                                id: "0.5",
                                                name:
                                                    "Consistent mild forgetfulness or partial recollection of events that may interfere with performing your everyday activities; repeats questions/statements, misplaces items, forgets appointments"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Mild to moderate memory loss; more noticeable for recent events; interferes with performing your everyday activities"
                                            },
                                            {
                                                id: "2",
                                                name:
                                                    "Moderate to severe memory loss; only highly learned information remembered; new information rapidly forgotten"
                                            },
                                            {
                                                id: "3",
                                                name:
                                                    "Severe memory loss, almost impossible to recall new information; long-term memory may be affected"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row={false}
                                        helperText={false}
                                        margin='none'
                                        source='symptomRating.orientation'
                                        label='2. ORIENTATION'
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "Fully oriented to person, place, and time nearly all the time"
                                            },
                                            {
                                                id: "0.5",
                                                name:
                                                    "Slight difficulty keeping track of time; may forget day or date more frequently than in the past"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Mild to moderate difficulty keeping track of time and sequence of events; forgets month or year; oriented to familiar places but gets confused outside of familiar areas; gets lost or wanders"
                                            },
                                            {
                                                id: "2",
                                                name:
                                                    "Moderate to severe difficulty, usually disoriented to time and place (familiar and unfamiliar); frequently dwells in past"
                                            },
                                            {
                                                id: "3",
                                                name:
                                                    "Only oriented to your name, although may recognize family members"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row={false}
                                        helperText={false}
                                        margin='none'
                                        source='symptomRating.decisionMakingAndProblemSolvingAbilities'
                                        label='3. DECISION MAKING AND PROBLEM SOLVING
                                        ABILITIES'
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "Solves everyday problems; handles personal business and financial affairs well; decision-making abilities consistent with your past performance"
                                            },
                                            {
                                                id: "0.5",
                                                name:
                                                    "Slight impairment or takes longer to solve problems; trouble with abstract concepts; decisions still sound"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Moderate difficulty with handling problems and making decisions; defers many decisions to others; social judgment and behavior may be slightly impaired; loss of insight"
                                            },
                                            {
                                                id: "2",
                                                name:
                                                    "Severely impaired in handling problems, making only simple personal decisions; social judgment and behavior often impaired; lacks insight"
                                            },
                                            {
                                                id: "3",
                                                name:
                                                    "Unable to make decisions or solve problems; others make nearly all decisions for you"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row={false}
                                        helperText={false}
                                        margin='none'
                                        source='symptomRating.activitiesOutsideHome'
                                        label='4. ACTIVITIES OUTSIDE THE HOME'
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "Independent in function at usual level of performance in profession, shopping, community activities, religious services, volunteering or social groups"
                                            },
                                            {
                                                id: "0.5",
                                                name:
                                                    "Slight impairment in these activities compared to previous performance; slight change in driving skills; still able to handle emergency situations"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Unable to function independently but still may attend and be engaged; appears “normal” to others; notable changes in driving skills; concern about ability to handle emergency situations"
                                            },
                                            {
                                                id: "2",
                                                name:
                                                    "No pretense of independent function outside the home; appears well enough to be taken to activities outside the family home but generally needs to be accompanied by someone"
                                            },
                                            {
                                                id: "3",
                                                name:
                                                    "No independent function or activities; appear too ill to be taken to activities outside the home"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row={false}
                                        helperText={false}
                                        margin='none'
                                        source='symptomRating.activitiesInsideHome'
                                        label='5. FUNCTION AT HOME AND HOBBY ACTIVITIES'
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "Chores at home, hobbies and personal interests are well maintained compared to your past performance"
                                            },
                                            {
                                                id: "0.5",
                                                name:
                                                    "Slight impairment or less interest in these activities; trouble operating appliances (particularly new purchases)"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Mild but definite impairment in home and hobby function; more difficult chores or tasks abandoned; more complicated hobbies and interests given up"
                                            },
                                            {
                                                id: "2",
                                                name:
                                                    "Only simple chores preserved, very restricted interest in hobbies which are poorly maintained"
                                            },
                                            {
                                                id: "3",
                                                name:
                                                    "No independent function or activities; appear too ill to be taken to activities outside the home"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row={false}
                                        helperText={false}
                                        margin='none'
                                        source='symptomRating.toiletingAndPersonalHygine'
                                        label='6. TOILETING AND PERSONAL HYGIENE'
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "Fully capable of self-care (dressing, grooming, washing, bathing, toileting)"
                                            },
                                            {
                                                id: "0.5",
                                                name:
                                                    "Slight changes in abilities and attention to these activities"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Needs prompting to complete these activities but may still complete independently"
                                            },
                                            {
                                                id: "2",
                                                name:
                                                    "Requires some assistance in dressing, hygiene, keeping of personal items; occasionally incontinent"
                                            },
                                            {
                                                id: "3",
                                                name:
                                                    "Requires significant help with personal care and hygiene; frequent incontinence"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row={false}
                                        helperText={false}
                                        margin='none'
                                        source='symptomRating.behaviorAndPersonalityChanges'
                                        label='7. BEHAVIOR AND PERSONALITY CHANGES'
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "Socially appropriate behavior in public and private; no changes in personality"
                                            },
                                            {
                                                id: "0.5",
                                                name:
                                                    "Questionable or very mild changes in behavior, personality, emotional control, appropriateness of choices"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Mild changes in behavior or personality"
                                            },
                                            {
                                                id: "2",
                                                name:
                                                    "Moderate behavior or personality changes, affects interactions with others; may be avoided by friends or distant family"
                                            },
                                            {
                                                id: "3",
                                                name:
                                                    "Severe behavior or personality changes; making interactions with others unpleasant or avoided all together"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row={false}
                                        helperText={false}
                                        margin='none'
                                        source='symptomRating.languageAndCommunicationAbilities'
                                        label='8. LANGUAGE AND COMMUNICATION ABILITIES'
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "No language difficulty or occasional word searching; reads and writes as well as in past"
                                            },
                                            {
                                                id: "0.5",
                                                name:
                                                    "Consistent mild word finding difficulties, using descriptive terms or takes longer to get point across, mild problems with comprehension, decreased conversation; may affect reading and writing"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Moderate word finding difficulty in speech, cannot name objects, marked reduction in word production; reduced comprehension, conversation, writing and/or reading"
                                            },
                                            {
                                                id: "2",
                                                name:
                                                    "Moderate to severe impairments in speech production or comprehension; has difficulty communicating thoughts to others; limited ability to read or write"
                                            },
                                            {
                                                id: "3",
                                                name:
                                                    "Severe deficits in language and communication; little to no understandable speech"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row={false}
                                        helperText={false}
                                        margin='none'
                                        source='symptomRating.mood'
                                        label='9. MOOD'
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "No changes in your mood, interest or motivation level"
                                            },
                                            {
                                                id: "0.5",
                                                name:
                                                    "Occasional sadness, depression, anxiety, nervousness or loss of interest/motivation"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Daily mild issues with sadness, depression, anxiety, nervousness or loss of interest/motivation"
                                            },
                                            {
                                                id: "2",
                                                name:
                                                    "Moderate issues with sadness, depression, anxiety, nervousness or loss of interest/motivation"
                                            },
                                            {
                                                id: "3",
                                                name:
                                                    "Severe issues with sadness, depression, anxiety, nervousness or loss of interest/motivation"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell
                                    style={{
                                        fontSize: "18px",
                                        fontWeight: "700"
                                    }}
                                ></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row={false}
                                        helperText={false}
                                        margin='none'
                                        source='symptomRating.attentionAndConcentration'
                                        label='10. ATTENTION AND CONCENTRATION'
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "No changes in your mood, interest or motivation levelNormal attention, concentration and interaction with your environment and surroundings"
                                            },
                                            {
                                                id: "0.5",
                                                name:
                                                    "Mild problems with attention, concentration, and interaction with environment and surroundings, may appear drowsy during day"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Moderate problems with attention and concentration, may have staring spells or spend time with eyes closed, increased daytime sleepiness"
                                            },
                                            {
                                                id: "2",
                                                name:
                                                    "Significant portion of the day is spent sleeping, not paying attention to environment, when having a conversation may say things that are illogical or not consistent with topic"
                                            },
                                            {
                                                id: "3",
                                                name:
                                                    "Limited to no ability to pay attention to external environment or surroundings"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default SymptomRatings;
