export const getPersonalityTraitScoring = (data) => {
    return {
        extroversion: calculateExtroversion(data.personalityTraitQuestionaire),
        agreeableness: calculateAgreeableness(
            data.personalityTraitQuestionaire
        ),
        conscientiousness: calculateConscientiousness(
            data.personalityTraitQuestionaire
        ),
        neuroticism: calculateNeuroticism(data.personalityTraitQuestionaire),
        openness: calculateOpenness(data.personalityTraitQuestionaire)
    };
};

const calculateExtroversion = (data) => {
    return parseInt(data.extrovert ?? 0) + parseInt(data.reserved ?? 0);
};
const calculateAgreeableness = (data) => {
    return parseInt(data.agreeable ?? 0) + parseInt(data.selfFocused ?? 0);
};
const calculateConscientiousness = (data) => {
    return parseInt(data.conscientious ?? 0) + parseInt(data.disLikingStructure ?? 0);
};
const calculateNeuroticism = (data) => {
    return parseInt(data.emotionallyStable ?? 0) + parseInt(data.anxious ?? 0);
};
const calculateOpenness = (data) => {
    return (
        parseInt(data.openToNewExperiences ?? 0) +
        parseInt(data.conventinalAndReserved ?? 0)
    );
};
