import React from "react";
import {
    Box,
    Typography,
    FormLabel,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper
} from "@mui/material";
import { RadioButtonGroupInput } from "react-admin";

const PersonalityTraitQuestionaire = (props) => {
    return (
        <Box width='100%'>
            <Box
                borderBottom='1px solid'
                borderColor='grey.200'
                padding='10px 0'
            >
                <Typography variant='h6' fontWeight='bold' gutterBottom>
                    PERSONALITY TRAIT QUESTIONNAIRE
                </Typography>
                <Typography variant='body1' gutterBottom>
                    The following statements describe different personality
                    traits and characteristics. Please rate your agreement with
                    each statement to indicate the extent to which you agree or
                    disagree with the statement. Give your overall rating, even
                    if not every feature listed applies to you.
                </Typography>
                <Typography variant='body1' gutterBottom>
                    Please read the following statements and rate your agreement
                </Typography>
            </Box>
            <Box py='20px'>
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 650 }}
                        size='small'
                        aria-label='simple table'
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    width='45%'
                                    style={{ fontSize: "1.1rem" }}
                                >
                                    Do you consider yourself…
                                </TableCell>
                                <TableCell>&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        1. An Extrovert, highly social and
                                        energetic, thriving on small talk, and
                                        being the center of attention
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='personalityTraitQuestionaire.extrovert'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "Strongly Disagree"
                                            },
                                            {
                                                id: "1",
                                                name: "Disagree"
                                            },
                                            {
                                                id: "2",
                                                name:
                                                    "Neither Agree nor Disagree"
                                            },
                                            {
                                                id: "3",
                                                name: "Agree"
                                            },
                                            {
                                                id: "4",
                                                name: "Strongly Agree"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        2. Reserved, preferring solitude or
                                        small groups where you can blend into
                                        the background
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='personalityTraitQuestionaire.reserved'
                                        label={false}
                                        choices={[
                                            {
                                                id: "4",
                                                name: "Strongly Disagree"
                                            },
                                            {
                                                id: "3",
                                                name: "Disagree"
                                            },
                                            {
                                                id: "2",
                                                name:
                                                    "Neither Agree nor Disagree"
                                            },
                                            {
                                                id: "1",
                                                name: "Agree"
                                            },
                                            {
                                                id: "0",
                                                name: "Strongly Agree"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        3. Agreeable, trusting, sympathetic,
                                        warm, kind, and affectionate
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='personalityTraitQuestionaire.agreeable'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "Strongly Disagree"
                                            },
                                            {
                                                id: "1",
                                                name: "Disagree"
                                            },
                                            {
                                                id: "2",
                                                name:
                                                    "Neither Agree nor Disagree"
                                            },
                                            {
                                                id: "3",
                                                name: "Agree"
                                            },
                                            {
                                                id: "4",
                                                name: "Strongly Agree"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        4. Self-focused, critical of others,
                                        confrontational, less interested in
                                        others and their problems
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='personalityTraitQuestionaire.selfFocused'
                                        label={false}
                                        choices={[
                                            {
                                                id: "4",
                                                name: "Strongly Disagree"
                                            },
                                            {
                                                id: "3",
                                                name: "Disagree"
                                            },
                                            {
                                                id: "2",
                                                name:
                                                    "Neither Agree nor Disagree"
                                            },
                                            {
                                                id: "1",
                                                name: "Agree"
                                            },
                                            {
                                                id: "0",
                                                name: "Strongly Agree"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        5. Conscientious, thoughtful with
                                        goal-directed behavior, detail-oriented,
                                        and well organized
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='personalityTraitQuestionaire.conscientious'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "Strongly Disagree"
                                            },
                                            {
                                                id: "1",
                                                name: "Disagree"
                                            },
                                            {
                                                id: "2",
                                                name:
                                                    "Neither Agree nor Disagree"
                                            },
                                            {
                                                id: "3",
                                                name: "Agree"
                                            },
                                            {
                                                id: "4",
                                                name: "Strongly Agree"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        6. Disliking structure, routines, and
                                        schedules, procrastinating on tasks,
                                        leaving things to last minute
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='personalityTraitQuestionaire.disLikingStructure'
                                        label={false}
                                        choices={[
                                            {
                                                id: "4",
                                                name: "Strongly Disagree"
                                            },
                                            {
                                                id: "3",
                                                name: "Disagree"
                                            },
                                            {
                                                id: "2",
                                                name:
                                                    "Neither Agree nor Disagree"
                                            },
                                            {
                                                id: "1",
                                                name: "Agree"
                                            },
                                            {
                                                id: "0",
                                                name: "Strongly Agree"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        7. Emotionally stable, mindful, taking
                                        pleasure in the moment, handle stress
                                        without a problem
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='personalityTraitQuestionaire.emotionallyStable'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "Strongly Disagree"
                                            },
                                            {
                                                id: "1",
                                                name: "Disagree"
                                            },
                                            {
                                                id: "2",
                                                name:
                                                    "Neither Agree nor Disagree"
                                            },
                                            {
                                                id: "3",
                                                name: "Agree"
                                            },
                                            {
                                                id: "4",
                                                name: "Strongly Agree"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        8. Anxious, sad, moody, with physical or
                                        emotional responses to stress
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='personalityTraitQuestionaire.anxious'
                                        label={false}
                                        choices={[
                                            {
                                                id: "4",
                                                name: "Strongly Disagree"
                                            },
                                            {
                                                id: "3",
                                                name: "Disagree"
                                            },
                                            {
                                                id: "2",
                                                name:
                                                    "Neither Agree nor Disagree"
                                            },
                                            {
                                                id: "1",
                                                name: "Agree"
                                            },
                                            {
                                                id: "0",
                                                name: "Strongly Agree"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        9. Open to new experiences, creative,
                                        imaginative, insightful, eager to learn
                                        and experience new things
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='personalityTraitQuestionaire.openToNewExperiences'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "Strongly Disagree"
                                            },
                                            {
                                                id: "1",
                                                name: "Disagree"
                                            },
                                            {
                                                id: "2",
                                                name:
                                                    "Neither Agree nor Disagree"
                                            },
                                            {
                                                id: "3",
                                                name: "Agree"
                                            },
                                            {
                                                id: "4",
                                                name: "Strongly Agree"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        10. Conventional and reserved,
                                        preferring more traditional approaches
                                        to life, feel uncomfortable outside
                                        comfort zone
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='personalityTraitQuestionaire.conventinalAndReserved'
                                        label={false}
                                        choices={[
                                            {
                                                id: "4",
                                                name: "Strongly Disagree"
                                            },
                                            {
                                                id: "3",
                                                name: "Disagree"
                                            },
                                            {
                                                id: "2",
                                                name:
                                                    "Neither Agree nor Disagree"
                                            },
                                            {
                                                id: "1",
                                                name: "Agree"
                                            },
                                            {
                                                id: "0",
                                                name: "Strongly Agree"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default PersonalityTraitQuestionaire;
