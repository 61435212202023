import React from "react";
import {
    Box,
    Typography,
    FormLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from "@mui/material";
import { TextInput, RadioButtonGroupInput, FormDataConsumer } from "react-admin";

const AutonomicSymptomsChecklist = (props) => {
    return (
        <Box width='100%'>
            <Box
                borderBottom='1px solid'
                borderColor='grey.200'
                padding='10px 0'
            >
                <Typography variant='h6' marginTop="10px" gutterBottom>
                AUTONOMIC SYMPTOMS CHECKLIST
                </Typography>
                <Typography variant="body1">Please rate the presence or absence of each of the following symptoms over the past 6 months.
For each symptom that is present, please provide the best estimate of the <b>age of onset</b> (how old
you were) when the symptom(s) began occurring.</Typography>
            </Box>
            <Box py="20px">
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell width="50%" style={{ fontSize: "1.1rem" }}>Over the past six months, do you ….</TableCell>
                                <TableCell width="26%">&nbsp;</TableCell>
                                <TableCell>Age of onset</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>1. Dribble saliva during the day</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="AutonomicSymptomsChecklist.dribbleSaliva.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.AutonomicSymptomsChecklist?.dribbleSaliva?.status === 'true' &&
                                            <TextInput source="AutonomicSymptomsChecklist.dribbleSaliva.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>2. Have difficulty swallowing</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="AutonomicSymptomsChecklist.difficultySwallowing.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.AutonomicSymptomsChecklist?.difficultySwallowing?.status === 'true' &&
                                            <TextInput source="AutonomicSymptomsChecklist.difficultySwallowing.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>3. Have increased interest in sex</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="AutonomicSymptomsChecklist.increaseInterestOnSex.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.AutonomicSymptomsChecklist?.increaseInterestOnSex?.status === 'true' &&
                                            <TextInput source="AutonomicSymptomsChecklist.increaseInterestOnSex.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>4. Have decreased interest in sex</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="AutonomicSymptomsChecklist.decreaseInterestOnSex.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.AutonomicSymptomsChecklist?.decreaseInterestOnSex?.status === 'true' &&
                                            <TextInput source="AutonomicSymptomsChecklist.decreaseInterestOnSex.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>5. Have problems with sexual performance</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="AutonomicSymptomsChecklist.problemWithSexualPerformance.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.AutonomicSymptomsChecklist?.problemWithSexualPerformance?.status === 'true' &&
                                            <TextInput source="AutonomicSymptomsChecklist.problemWithSexualPerformance.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>6. Have a loss of weight (not due to dieting)</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="AutonomicSymptomsChecklist.lossOfWeight.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.AutonomicSymptomsChecklist?.lossOfWeight?.status === 'true' &&
                                            <TextInput source="AutonomicSymptomsChecklist.lossOfWeight.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>7. Notice a change in sense of taste</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="AutonomicSymptomsChecklist.noticeChangeInSenseOfTaste.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.AutonomicSymptomsChecklist?.noticeChangeInSenseOfTaste?.status === 'true' &&
                                            <TextInput source="AutonomicSymptomsChecklist.noticeChangeInSenseOfTaste.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow> 
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>8. Notice a change in sense of smell</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="AutonomicSymptomsChecklist.noticeChangeInSenseOfSmell.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.AutonomicSymptomsChecklist?.noticeChangeInSenseOfSmell?.status === 'true' &&
                                            <TextInput source="AutonomicSymptomsChecklist.noticeChangeInSenseOfSmell.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow> 
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>9. Experience excessive sweating</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="AutonomicSymptomsChecklist.experienceExcessiveSweating.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.AutonomicSymptomsChecklist?.experienceExcessiveSweating?.status === 'true' &&
                                            <TextInput source="AutonomicSymptomsChecklist.experienceExcessiveSweating.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow> 
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>10. Notice having difficulty tolerating cold weather</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="AutonomicSymptomsChecklist.difficultyToleratingColdWeather.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.AutonomicSymptomsChecklist?.difficultyToleratingColdWeather?.status === 'true' &&
                                            <TextInput source="AutonomicSymptomsChecklist.difficultyToleratingColdWeather.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow> 
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>11. Notice having difficulty tolerating hot weather</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="AutonomicSymptomsChecklist.difficultyToleratingHotWeather.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.AutonomicSymptomsChecklist?.difficultyToleratingHotWeather?.status === 'true' &&
                                            <TextInput source="AutonomicSymptomsChecklist.difficultyToleratingHotWeather.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow> 
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>12. Experience double vision (2 separate real objects and not blurred vision)</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="AutonomicSymptomsChecklist.experienceDoubleVision.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.AutonomicSymptomsChecklist?.experienceDoubleVision?.status === 'true' &&
                                            <TextInput source="AutonomicSymptomsChecklist.experienceDoubleVision.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow> 
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>13. Have difficulty digesting food or a sensation of feeling full long after last meal</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="AutonomicSymptomsChecklist.difficultyDigestingFood.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.AutonomicSymptomsChecklist?.difficultyDigestingFood?.status === 'true' &&
                                            <TextInput source="AutonomicSymptomsChecklist.difficultyDigestingFood.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow> 
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>14. Have problems with constipation</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="AutonomicSymptomsChecklist.constipation.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.AutonomicSymptomsChecklist?.constipation?.status === 'true' &&
                                            <TextInput source="AutonomicSymptomsChecklist.constipation.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow> 
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>15. Have to strain hard to pass stools</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="AutonomicSymptomsChecklist.strainHardToPassStools.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.AutonomicSymptomsChecklist?.strainHardToPassStools?.status === 'true' &&
                                            <TextInput source="AutonomicSymptomsChecklist.strainHardToPassStools.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow> 
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>16. Have any involuntary loss of stools</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="AutonomicSymptomsChecklist.involuntaryLossOfStools.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.AutonomicSymptomsChecklist?.involuntaryLossOfStools?.status === 'true' &&
                                            <TextInput source="AutonomicSymptomsChecklist.involuntaryLossOfStools.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow> 
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>17. Have the feeling that after passing urine your bladder was not completely empty</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="AutonomicSymptomsChecklist.bladderWasNotCompletelyEmpty.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.AutonomicSymptomsChecklist?.bladderWasNotCompletelyEmpty?.status === 'true' &&
                                            <TextInput source="AutonomicSymptomsChecklist.bladderWasNotCompletelyEmpty.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow> 
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>18. Notice your stream of urine has been weak or reduced</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="AutonomicSymptomsChecklist.weakStreamOfUrine.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.AutonomicSymptomsChecklist?.weakStreamOfUrine?.status === 'true' &&
                                            <TextInput source="AutonomicSymptomsChecklist.weakStreamOfUrine.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow> 
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>19. Have the frequent feeling to pass urine within two hours of previous urination</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="AutonomicSymptomsChecklist.frequentFeelingToPassUrine.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.AutonomicSymptomsChecklist?.frequentFeelingToPassUrine?.status === 'true' &&
                                            <TextInput source="AutonomicSymptomsChecklist.frequentFeelingToPassUrine.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow> 
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>20. Have any involuntary loss of urine</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="AutonomicSymptomsChecklist.involuntaryLossOfUrine.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.AutonomicSymptomsChecklist?.involuntaryLossOfUrine?.status === 'true' &&
                                            <TextInput source="AutonomicSymptomsChecklist.involuntaryLossOfUrine.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow> 
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>21. Complain of feeling lightheaded or dizzy when standing up for seated or reclined position</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="AutonomicSymptomsChecklist.feelingLightheaded.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.AutonomicSymptomsChecklist?.feelingLightheaded?.status === 'true' &&
                                            <TextInput source="AutonomicSymptomsChecklist.feelingLightheaded.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow> 
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>22. Become lightheaded after standing for a while</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="AutonomicSymptomsChecklist.feelingLightheadedAfterStanding.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.AutonomicSymptomsChecklist?.feelingLightheadedAfterStanding?.status === 'true' &&
                                            <TextInput source="AutonomicSymptomsChecklist.feelingLightheadedAfterStanding.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow> 
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <FormLabel>23. Ever fainted</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin="none"
                                        source="AutonomicSymptomsChecklist.everFainted.status"
                                        label={false}
                                        choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                                    >
                                    </RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => formData.AutonomicSymptomsChecklist?.everFainted?.status === 'true' &&
                                            <TextInput source="AutonomicSymptomsChecklist.everFainted.age" label="Age" variant='outlined'
                                                type='text'
                                                helperText={false} {...rest} />

                                        }
                                    </FormDataConsumer>
                                </TableCell>
                            </TableRow> 
                            
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

        </Box>
    );
};

export default AutonomicSymptomsChecklist;
