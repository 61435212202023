import React from "react";
import {
    Box,
    Grid,
    Typography,
    FormLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Slider,
    Divider
} from "@mui/material";
import { TextInput, RadioButtonGroupInput, useInput } from "react-admin";

function valuetext(value: number) {
    return `${value}°C`;
}

const SliderInput = ({ source, label }) => {
    const { id, field, fieldState } = useInput({ source });
    return (
        <label htmlFor={id}>
            <Typography variant='h6' fontSize='1rem'>
                {label}
            </Typography>
            <Slider
                aria-label='Small steps'
                defaultValue={0}
                getAriaValueText={valuetext}
                step={10}
                min={-100}
                max={100}
                marks={true}
                valueLabelDisplay='auto'
                id={id}
                {...field}
            />
            <Grid
                justifyContent='space-between'
                display='flex'
                fontSize='.8rem'
                fontWeight='600'
            >
                <Box>Less Activity</Box>
                <Box>No Change</Box>
                <Box>More Activity</Box>
            </Grid>
            {fieldState.error && <span>{fieldState.error.message}</span>}
        </label>
    );
};

const BrainHealthOverLifeCourse = (props) => {
    return (
        <Box width='100%'>
            <Box
                borderBottom='1px solid'
                borderColor='grey.200'
                padding='10px 0'
            >
                <Typography variant='h6' marginTop='10px' gutterBottom>
                    BRAIN HEALTH OVER THE LIFECOURSE
                </Typography>
                <Typography variant='body1'>
                    Our research is not only interested in your current brain
                    health, but also how early and midlife experiences and
                    exposures may increase or decrease the risk of brain
                    disease.
                </Typography>
            </Box>
            <Box py='20px'>
                <Grid container columnSpacing={3} rowSpacing={1} my={3}>
                    <Grid item xs={12}>
                        <Typography variant='body1'>
                            Now, please think back about your lifestyle and
                            health behaviors when you were younger.{" "}
                            <b>Compared to your activity now</b>, how would you
                            rate your overall{" "}
                            <b>
                                <u>physical activity</u>
                            </b>{" "}
                            at age 25, age 40 and age 65. Example physical
                            activities include exercising, walking, yoga,
                            stretching, dancing, gardening, and household chores
                            that involve some exertion. Please mark the ruler
                            where you believe your activity level to be, ranging
                            from 100% Less Activity to 100% More Activity
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <SliderInput
                            source='brainHealthOverLifeCourse.physicalActivity.age25'
                            label='At age 25'
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SliderInput
                            source='brainHealthOverLifeCourse.physicalActivity.age40'
                            label='At age 40'
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SliderInput
                            source='brainHealthOverLifeCourse.physicalActivity.age65'
                            label='At age 65'
                        />
                    </Grid>
                </Grid>
                <Divider />
                <Grid container columnSpacing={2} rowSpacing={1} my={3}>
                    <Grid item xs={12}>
                        <Typography variant='body1'>
                            <b>Compared to your activity now</b>, how would you
                            rate your overall{" "}
                            <b>
                                <u>cognitive and mental activity</u>
                            </b>{" "}
                            at age 25, age 40 and age 65. Example cognitive
                            activities include reading, learning new hobbies or
                            skills, attending plays or concerts, and doing
                            crossword puzzles. Please mark the ruler where you
                            believe your activity level to be, ranging from 100%
                            Less Activity to 100% More Activity
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <SliderInput
                            source='brainHealthOverLifeCourse.cognitiveAndMentalActivity.age25'
                            label='At age 25'
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SliderInput
                            source='brainHealthOverLifeCourse.cognitiveAndMentalActivity.age40'
                            label='At age 40'
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SliderInput
                            source='brainHealthOverLifeCourse.cognitiveAndMentalActivity.age65'
                            label='At age 65'
                        />
                    </Grid>
                </Grid>
                <Divider />
                <Grid container columnSpacing={2} rowSpacing={1} my={3}>
                    <Grid item xs={12}>
                        <Typography variant='body1'>
                            <b>Compared to your activity now</b>, how would you
                            rate your overall{" "}
                            <b>
                                <u>social activity</u>
                            </b>{" "}
                            at age 25, age 40 and age 65. Example social
                            activities are seeing friends and family, belonging
                            to clubs or organizations, and participating in
                            religious services and other social activities.
                            Please mark the ruler where you believe your
                            activity level to be, ranging from 100% Less
                            Activity to 100% More Activity
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <SliderInput
                            source='brainHealthOverLifeCourse.socialActivity.age25'
                            label='At age 25'
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SliderInput
                            source='brainHealthOverLifeCourse.socialActivity.age40'
                            label='At age 40'
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SliderInput
                            source='brainHealthOverLifeCourse.socialActivity.age65'
                            label='At age 65'
                        />
                    </Grid>
                </Grid>
                <Divider />
                <Grid container columnSpacing={2} rowSpacing={1} my={3}>
                    <Grid item xs={12}>
                        <Typography variant='body1'>
                            Thinking back, how much did you{" "}
                            <b>
                                <u>weight (in pounds)</u>
                            </b>{" "}
                            at each age? Please give your best estimate.
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput
                            fullWidth
                            source='brainHealthOverLifeCourse.weight.age25'
                            label='At Age 25:'
                            variant='outlined'
                            type='number'
                            helperText={false}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput
                            fullWidth
                            source='brainHealthOverLifeCourse.weight.age40'
                            label='At Age 40:'
                            variant='outlined'
                            type='number'
                            helperText={false}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput
                            fullWidth
                            source='brainHealthOverLifeCourse.weight.age65'
                            label='At Age 65:'
                            variant='outlined'
                            type='number'
                            helperText={false}
                        />
                    </Grid>
                </Grid>
                <Divider />
                <Grid container columnSpacing={2} rowSpacing={1} my={3}>
                    <Grid item xs={12}>
                        <Typography variant='body1'>
                            Please consider your diet and nutritional choices.{" "}
                            <b>Compared to your diet now</b>, how would you rate
                            your
                            <b>
                                <u>dietary and nutritional choices</u>
                            </b>{" "}
                            at age 25, age 40 and age 65. We are interested in
                            whether your appetite has changed over time and if
                            your food choices have changed. For each age, please
                            circle the answer that best characterizes your
                            appetite and food choices at that age.
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <FormLabel
                            style={{
                                display: "block",
                                color: "#333",
                                marginBottom: ".5rem",
                                fontWeight: "600"
                            }}
                        >
                            At Age 25:
                        </FormLabel>
                        <RadioButtonGroupInput
                            row
                            helperText={false}
                            margin='none'
                            source='brainHealthOverLifeCourse.appetite.age25'
                            label='1. My appetite was:'
                            choices={[
                                { id: "bigger", name: "Bigger" },
                                { id: "smaller", name: "Smaller" },
                                { id: "unchanged", name: "Unchanged" }
                            ]}
                        ></RadioButtonGroupInput>
                        <RadioButtonGroupInput
                            row
                            helperText={false}
                            margin='none'
                            source='brainHealthOverLifeCourse.nutritionalChoices.age25'
                            label='2. My nutritional choices were:'
                            choices={[
                                { id: "healthier", name: "Healthier" },
                                { id: "lessHealthy", name: "Less Healthy" },
                                { id: "unchanged", name: "Unchanged" }
                            ]}
                        ></RadioButtonGroupInput>
                    </Grid>
                    <Grid item xs={4}>
                        <FormLabel
                            style={{
                                display: "block",
                                color: "#333",
                                marginBottom: ".5rem",
                                fontWeight: "600"
                            }}
                        >
                            At Age 40:
                        </FormLabel>
                        <RadioButtonGroupInput
                            row
                            helperText={false}
                            margin='none'
                            source='brainHealthOverLifeCourse.appetite.age40'
                            label='1. My appetite was:'
                            choices={[
                                { id: "bigger", name: "Bigger" },
                                { id: "smaller", name: "Smaller" },
                                { id: "unchanged", name: "Unchanged" }
                            ]}
                        ></RadioButtonGroupInput>
                        <RadioButtonGroupInput
                            row
                            helperText={false}
                            margin='none'
                            source='brainHealthOverLifeCourse.nutritionalChoices.age40'
                            label='2. My nutritional choices were:'
                            choices={[
                                { id: "healthier", name: "Healthier" },
                                { id: "lessHealthy", name: "Less Healthy" },
                                { id: "unchanged", name: "Unchanged" }
                            ]}
                        ></RadioButtonGroupInput>
                    </Grid>
                    <Grid item xs={4}>
                        <FormLabel
                            style={{
                                display: "block",
                                color: "#333",
                                marginBottom: ".5rem",
                                fontWeight: "600"
                            }}
                        >
                            At Age 65:
                        </FormLabel>
                        <RadioButtonGroupInput
                            row
                            helperText={false}
                            margin='none'
                            source='brainHealthOverLifeCourse.appetite.age65'
                            label='1. My appetite was:'
                            choices={[
                                { id: "bigger", name: "Bigger" },
                                { id: "smaller", name: "Smaller" },
                                { id: "unchanged", name: "Unchanged" }
                            ]}
                        ></RadioButtonGroupInput>
                        <RadioButtonGroupInput
                            row
                            helperText={false}
                            margin='none'
                            source='brainHealthOverLifeCourse.nutritionalChoices.age65'
                            label='2. My nutritional choices were:'
                            choices={[
                                { id: "healthier", name: "Healthier" },
                                { id: "lessHealthy", name: "Less Healthy" },
                                { id: "unchanged", name: "Unchanged" }
                            ]}
                        ></RadioButtonGroupInput>
                    </Grid>
                </Grid>
                <Divider />

                <Typography variant='body1' my={3}>
                    <b>
                        These questions are about your lifelong experiences
                        related to{" "}
                        <u>how others might perceive or describe you.</u>
                    </b>{" "}
                    For example, your skin color, ancestry, nationality,
                    language, accent, dialect, education, religion, gender,
                    sexuality, age, weight, physical limitation, mental health
                    condition, or income.
                </Typography>
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 650 }}
                        size='small'
                        aria-label='simple table'
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    width='50%'
                                    style={{ fontSize: "1.1rem" }}
                                >
                                    Because of how others might perceive or
                                    describe me…
                                </TableCell>
                                <TableCell width='50%'>&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>
                                        1. A doctor, nurse or other health care
                                        provider might treat me poorly
                                    </FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='brainHealthOverLifeCourse.perceiveOrDescribe.healthCareProviderTreatMePoorly'
                                        label={false}
                                        choices={[
                                            {
                                                id: 0,
                                                name: "Strongly Disagree"
                                            },
                                            { id: 1, name: "Disagree" },
                                            {
                                                id: 2,
                                                name:
                                                    "Neither Agree nor Disagree"
                                            },
                                            { id: 3, name: "Agree" },
                                            { id: 4, name: "Strongly Agree" }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>
                                        2. I might have trouble finding or
                                        keeping a job
                                    </FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='brainHealthOverLifeCourse.perceiveOrDescribe.troubleFindingOrKeepJob'
                                        label={false}
                                        choices={[
                                            {
                                                id: 0,
                                                name: "Strongly Disagree"
                                            },
                                            { id: 1, name: "Disagree" },
                                            {
                                                id: 2,
                                                name:
                                                    "Neither Agree nor Disagree"
                                            },
                                            { id: 3, name: "Agree" },
                                            { id: 4, name: "Strongly Agree" }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>
                                        3. I might have trouble getting an
                                        apartment or house
                                    </FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='brainHealthOverLifeCourse.perceiveOrDescribe.troubleGettingAnApartment'
                                        label={false}
                                        choices={[
                                            {
                                                id: 0,
                                                name: "Strongly Disagree"
                                            },
                                            { id: 1, name: "Disagree" },
                                            {
                                                id: 2,
                                                name:
                                                    "Neither Agree nor Disagree"
                                            },
                                            { id: 3, name: "Agree" },
                                            { id: 4, name: "Strongly Agree" }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>
                                        4. I worry about being treated unfairly
                                        by a teacher, supervisor, or employer
                                    </FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='brainHealthOverLifeCourse.perceiveOrDescribe.treatedUnfairlyByTeacherSupervisorEmployer'
                                        label={false}
                                        choices={[
                                            {
                                                id: 0,
                                                name: "Strongly Disagree"
                                            },
                                            { id: 1, name: "Disagree" },
                                            {
                                                id: 2,
                                                name:
                                                    "Neither Agree nor Disagree"
                                            },
                                            { id: 3, name: "Agree" },
                                            { id: 4, name: "Strongly Agree" }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>
                                        5. I may be denied a bank account, loan,
                                        or mortgage
                                    </FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='brainHealthOverLifeCourse.perceiveOrDescribe.deniedBankAccount'
                                        label={false}
                                        choices={[
                                            {
                                                id: 0,
                                                name: "Strongly Disagree"
                                            },
                                            { id: 1, name: "Disagree" },
                                            {
                                                id: 2,
                                                name:
                                                    "Neither Agree nor Disagree"
                                            },
                                            { id: 3, name: "Agree" },
                                            { id: 4, name: "Strongly Agree" }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>
                                        6. I worry about be harassed or stopped
                                        by police or security
                                    </FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='brainHealthOverLifeCourse.perceiveOrDescribe.harassedByPoliceOrSecurity'
                                        label={false}
                                        choices={[
                                            {
                                                id: 0,
                                                name: "Strongly Disagree"
                                            },
                                            { id: 1, name: "Disagree" },
                                            {
                                                id: 2,
                                                name:
                                                    "Neither Agree nor Disagree"
                                            },
                                            { id: 3, name: "Agree" },
                                            { id: 4, name: "Strongly Agree" }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>
                                        7. People might try to attack me
                                        physically
                                    </FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='brainHealthOverLifeCourse.perceiveOrDescribe.tryToAttackMePhysically'
                                        label={false}
                                        choices={[
                                            {
                                                id: 0,
                                                name: "Strongly Disagree"
                                            },
                                            { id: 1, name: "Disagree" },
                                            {
                                                id: 2,
                                                name:
                                                    "Neither Agree nor Disagree"
                                            },
                                            { id: 3, name: "Agree" },
                                            { id: 4, name: "Strongly Agree" }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>
                                        8. I might be pointed at, called names,
                                        or verbally attacked when in public
                                    </FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='brainHealthOverLifeCourse.perceiveOrDescribe.verballyAttackedWhenInPublic'
                                        label={false}
                                        choices={[
                                            {
                                                id: 0,
                                                name: "Strongly Disagree"
                                            },
                                            { id: 1, name: "Disagree" },
                                            {
                                                id: 2,
                                                name:
                                                    "Neither Agree nor Disagree"
                                            },
                                            { id: 3, name: "Agree" },
                                            { id: 4, name: "Strongly Agree" }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>
                                        9. I fear that I will have a hard time
                                        finding friendship or romance
                                    </FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='brainHealthOverLifeCourse.perceiveOrDescribe.hardTimeFindingFriendship'
                                        label={false}
                                        choices={[
                                            {
                                                id: 0,
                                                name: "Strongly Disagree"
                                            },
                                            { id: 1, name: "Disagree" },
                                            {
                                                id: 2,
                                                name:
                                                    "Neither Agree nor Disagree"
                                            },
                                            { id: 3, name: "Agree" },
                                            { id: 4, name: "Strongly Agree" }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <Typography variant='body1' my={3}>
                    <b>
                        These questions ask you about your feelings and thoughts
                        during YOUR ADULTHOOD.
                    </b>{" "}
                    Please indicate your response representing <b>HOW OFTEN</b>{" "}
                    you felt or thought a certain way. The best approach is to
                    indicate the choice that seems like a reasonable estimate of
                    your <b>LIFELONG</b> experiences.
                </Typography>
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 650 }}
                        size='small'
                        aria-label='simple table'
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    width='50%'
                                    style={{ fontSize: "1.1rem" }}
                                >
                                    Over the course of your lifetime, how often
                                    have you….
                                </TableCell>
                                <TableCell width='50%'>&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>
                                        1. Been upset because of something that
                                        happened unexpectedly
                                    </FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='brainHealthOverLifeCourse.thoughtsDuringYourAdulthood.beenUpset'
                                        label={false}
                                        choices={[
                                            { id: 0, name: "Never" },
                                            { id: 1, name: "Almost Never" },
                                            { id: 2, name: "Sometimes" },
                                            { id: 3, name: "Fairly Often" },
                                            { id: 4, name: "Very Often" }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>
                                        2. Felt that you were unable to control
                                        the important things in your life
                                    </FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='brainHealthOverLifeCourse.thoughtsDuringYourAdulthood.unableToControl'
                                        label={false}
                                        choices={[
                                            { id: 0, name: "Never" },
                                            { id: 1, name: "Almost Never" },
                                            { id: 2, name: "Sometimes" },
                                            { id: 3, name: "Fairly Often" },
                                            { id: 4, name: "Very Often" }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>
                                        3. Felt nervous and “stressed”
                                    </FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='brainHealthOverLifeCourse.thoughtsDuringYourAdulthood.feltNervous'
                                        label={false}
                                        choices={[
                                            { id: 0, name: "Never" },
                                            { id: 1, name: "Almost Never" },
                                            { id: 2, name: "Sometimes" },
                                            { id: 3, name: "Fairly Often" },
                                            { id: 4, name: "Very Often" }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>
                                        4. Dealt successfully with day-to-day
                                        problems and annoyances
                                    </FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='brainHealthOverLifeCourse.thoughtsDuringYourAdulthood.dealtSuccessfullyWithDayToDayProblems'
                                        label={false}
                                        choices={[
                                            { id: 4, name: "Never" },
                                            { id: 3, name: "Almost Never" },
                                            { id: 2, name: "Sometimes" },
                                            { id: 1, name: "Fairly Often" },
                                            { id: 0, name: "Very Often" }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>
                                        5. Felt that you were effectively coping
                                        with important changes that were
                                        occurring in your life
                                    </FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='brainHealthOverLifeCourse.thoughtsDuringYourAdulthood.effectivelyCopingWithImportantChanges'
                                        label={false}
                                        choices={[
                                            { id: 4, name: "Never" },
                                            { id: 3, name: "Almost Never" },
                                            { id: 2, name: "Sometimes" },
                                            { id: 1, name: "Fairly Often" },
                                            { id: 0, name: "Very Often" }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>
                                        6. Felt confident about your ability to
                                        handle your personal problems
                                    </FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='brainHealthOverLifeCourse.thoughtsDuringYourAdulthood.feltConfidentAboutYourAbility'
                                        label={false}
                                        choices={[
                                            { id: 4, name: "Never" },
                                            { id: 3, name: "Almost Never" },
                                            { id: 2, name: "Sometimes" },
                                            { id: 1, name: "Fairly Often" },
                                            { id: 0, name: "Very Often" }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>
                                        7. Felt that things were going your way
                                    </FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='brainHealthOverLifeCourse.thoughtsDuringYourAdulthood.feltThatThingsWereGoingYourWay'
                                        label={false}
                                        choices={[
                                            { id: 4, name: "Never" },
                                            { id: 3, name: "Almost Never" },
                                            { id: 2, name: "Sometimes" },
                                            { id: 1, name: "Fairly Often" },
                                            { id: 0, name: "Very Often" }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>
                                        8. Found that you could not cope with
                                        all the things that you had to do
                                    </FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='brainHealthOverLifeCourse.thoughtsDuringYourAdulthood.foundThatYouCouldNotCopeWithAllTheThings'
                                        label={false}
                                        choices={[
                                            { id: 0, name: "Never" },
                                            { id: 1, name: "Almost Never" },
                                            { id: 2, name: "Sometimes" },
                                            { id: 3, name: "Fairly Often" },
                                            { id: 4, name: "Very Often" }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>
                                        9. Been able to control irritations in
                                        your life
                                    </FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='brainHealthOverLifeCourse.thoughtsDuringYourAdulthood.beenAbleToControlIrritationsInYourLife'
                                        label={false}
                                        choices={[
                                            { id: 4, name: "Never" },
                                            { id: 3, name: "Almost Never" },
                                            { id: 2, name: "Sometimes" },
                                            { id: 1, name: "Fairly Often" },
                                            { id: 0, name: "Very Often" }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>
                                        10. Felt that you were on top of things
                                    </FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='brainHealthOverLifeCourse.thoughtsDuringYourAdulthood.feltThatYouWereOnTopOfThings'
                                        label={false}
                                        choices={[
                                            { id: 4, name: "Never" },
                                            { id: 3, name: "Almost Never" },
                                            { id: 2, name: "Sometimes" },
                                            { id: 1, name: "Fairly Often" },
                                            { id: 0, name: "Very Often" }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>
                                        11. Been angered because of things that
                                        happened that were outside of your
                                        control
                                    </FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='brainHealthOverLifeCourse.thoughtsDuringYourAdulthood.beenAngeredBecauseOfThings'
                                        label={false}
                                        choices={[
                                            { id: 0, name: "Never" },
                                            { id: 1, name: "Almost Never" },
                                            { id: 2, name: "Sometimes" },
                                            { id: 3, name: "Fairly Often" },
                                            { id: 4, name: "Very Often" }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>
                                        12. Found yourself thinking about all
                                        the things that you have to accomplish
                                    </FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='brainHealthOverLifeCourse.thoughtsDuringYourAdulthood.foundYourselfThinkingAboutAllTheThings'
                                        label={false}
                                        choices={[
                                            { id: 0, name: "Never" },
                                            { id: 1, name: "Almost Never" },
                                            { id: 2, name: "Sometimes" },
                                            { id: 3, name: "Fairly Often" },
                                            { id: 4, name: "Very Often" }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>
                                        13. Been able to control the way you
                                        spend your time
                                    </FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='brainHealthOverLifeCourse.thoughtsDuringYourAdulthood.beenAbleToControl'
                                        label={false}
                                        choices={[
                                            { id: 4, name: "Never" },
                                            { id: 3, name: "Almost Never" },
                                            { id: 2, name: "Sometimes" },
                                            { id: 1, name: "Fairly Often" },
                                            { id: 0, name: "Very Often" }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>
                                        14. Felt difficulties were piling up so
                                        high that you could not overcome them
                                    </FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='brainHealthOverLifeCourse.thoughtsDuringYourAdulthood.feltDifficultiesWerePilingUp'
                                        label={false}
                                        choices={[
                                            { id: 0, name: "Never" },
                                            { id: 1, name: "Almost Never" },
                                            { id: 2, name: "Sometimes" },
                                            { id: 3, name: "Fairly Often" },
                                            { id: 4, name: "Very Often" }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default BrainHealthOverLifeCourse;
