import React from "react";
import {
    Box,
    Typography,
    FormLabel,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper
} from "@mui/material";
import { RadioButtonGroupInput } from "react-admin";

const TheHealthyBrain = (props) => {
    return (
        <Box width='100%'>
            <Box
                borderBottom='1px solid'
                borderColor='grey.200'
                padding='10px 0'
            >
                <Typography variant='h6' fontWeight='bold' gutterBottom>
                    BRAIN HEALTH QUESTIONAIRE
                </Typography>
                <Typography variant='body1' gutterBottom>
                    The following descriptions characterize changes you may have
                    noticed in your memory, thinking and everyday activities.
                    The important thing to consider is comparing your abilities{" "}
                    <b>now</b> with how they <b>used</b> to be.
                </Typography>
                <Box>
                    <Typography variant='body1' gutterBottom>
                        Compared to 5 years ago, please rate your current
                        ability using the following scale
                    </Typography>
                    <ul style={{ marginTop: ".5rem" }}>
                        <li>
                            <b>No Change:</b> I can do these things the same as
                            I ever could
                        </li>
                        <li>
                            <b>Very Mild Change:</b> I notice some differences,
                            but they are inconsistent or do not cause any
                            difficulties
                        </li>
                        <li>
                            <b>Mild Change:</b> Differences are noticeable to
                            me, but maybe not to others
                        </li>
                        <li>
                            <b>Moderate Change:</b> Definite differences, and
                            others may notice the change
                        </li>
                        <li>
                            <b>Marked Change:</b> I now have trouble doing these
                            things for myself and need assistance from others
                        </li>
                    </ul>
                </Box>
            </Box>
            <Box py='20px'>
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 650 }}
                        size='small'
                        aria-label='simple table'
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    width='45%'
                                    style={{ fontSize: "1.1rem" }}
                                >
                                    Compared to 5 years ago, please rate your
                                    current ability on the following:
                                </TableCell>
                                <TableCell>&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        1. Remembering recent events,
                                        conversations, phone messages,
                                        appointments, and/or taking my
                                        medications
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='theHealthyBrain.remembering'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "No Change"
                                            },
                                            {
                                                id: "1",
                                                name: "Very Mild Change"
                                            },
                                            {
                                                id: "2",
                                                name: "Mild Change"
                                            },
                                            {
                                                id: "3",
                                                name: "Moderate Change"
                                            },
                                            {
                                                id: "4",
                                                name: "Marked Change"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        2. Making decisions, solving everyday
                                        problems, and being able to focus and
                                        concentrate at my expected level of
                                        performance
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='theHealthyBrain.makingDecision'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "No Change"
                                            },
                                            {
                                                id: "1",
                                                name: "Very Mild Change"
                                            },
                                            {
                                                id: "2",
                                                name: "Mild Change"
                                            },
                                            {
                                                id: "3",
                                                name: "Moderate Change"
                                            },
                                            {
                                                id: "4",
                                                name: "Marked Change"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        3. Keeping accurate track of the correct
                                        date and day of the week
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='theHealthyBrain.keepAccurateTrack'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "No Change"
                                            },
                                            {
                                                id: "1",
                                                name: "Very Mild Change"
                                            },
                                            {
                                                id: "2",
                                                name: "Mild Change"
                                            },
                                            {
                                                id: "3",
                                                name: "Moderate Change"
                                            },
                                            {
                                                id: "4",
                                                name: "Marked Change"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        4. Taking care of financial matters such
                                        as paying bills, managing money and bank
                                        accounts, and keeping track of business
                                        papers
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='theHealthyBrain.takingCareOfFinancialMatters'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "No Change"
                                            },
                                            {
                                                id: "1",
                                                name: "Very Mild Change"
                                            },
                                            {
                                                id: "2",
                                                name: "Mild Change"
                                            },
                                            {
                                                id: "3",
                                                name: "Moderate Change"
                                            },
                                            {
                                                id: "4",
                                                name: "Marked Change"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        5. Carrying out my everyday activities
                                        (work, shopping, community activities,
                                        religious services, social groups) at my
                                        usual level of performance
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='theHealthyBrain.carryingOutMyEverydayActivities'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "No Change"
                                            },
                                            {
                                                id: "1",
                                                name: "Very Mild Change"
                                            },
                                            {
                                                id: "2",
                                                name: "Mild Change"
                                            },
                                            {
                                                id: "3",
                                                name: "Moderate Change"
                                            },
                                            {
                                                id: "4",
                                                name: "Marked Change"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        6. Having conversations, finding my
                                        words, communicating with others,
                                        reading, and writing at my usual level
                                        of performance
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='theHealthyBrain.havingConversations'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "No Change"
                                            },
                                            {
                                                id: "1",
                                                name: "Very Mild Change"
                                            },
                                            {
                                                id: "2",
                                                name: "Mild Change"
                                            },
                                            {
                                                id: "3",
                                                name: "Moderate Change"
                                            },
                                            {
                                                id: "4",
                                                name: "Marked Change"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        7. Doing my household chores and tasks
                                        (such as cooking, cleaning, gardening,
                                        home repairs), and operating appliances
                                        to my usual standards
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='theHealthyBrain.doingMyHouseholdChores'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "No Change"
                                            },
                                            {
                                                id: "1",
                                                name: "Very Mild Change"
                                            },
                                            {
                                                id: "2",
                                                name: "Mild Change"
                                            },
                                            {
                                                id: "3",
                                                name: "Moderate Change"
                                            },
                                            {
                                                id: "4",
                                                name: "Marked Change"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        8. Traveling independently outside my
                                        local neighborhood without having to use
                                        directions or getting mixed up
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='theHealthyBrain.travelingIndependently'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "No Change"
                                            },
                                            {
                                                id: "1",
                                                name: "Very Mild Change"
                                            },
                                            {
                                                id: "2",
                                                name: "Mild Change"
                                            },
                                            {
                                                id: "3",
                                                name: "Moderate Change"
                                            },
                                            {
                                                id: "4",
                                                name: "Marked Change"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        9. Doing my hobby and recreational
                                        activities and keeping up with personal
                                        interests at my usual pace and level
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='theHealthyBrain.doingMyHobby'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "No Change"
                                            },
                                            {
                                                id: "1",
                                                name: "Very Mild Change"
                                            },
                                            {
                                                id: "2",
                                                name: "Mild Change"
                                            },
                                            {
                                                id: "3",
                                                name: "Moderate Change"
                                            },
                                            {
                                                id: "4",
                                                name: "Marked Change"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default TheHealthyBrain;
