export const intialNumberSymbolQuestions = [
    {
        sNo: 1,
        num: "3",
        symbol: "",
        questionColumn: "top",
        block: 1,
        correctAns: "c"
    },
    {
        sNo: 2,
        num: "1",
        symbol: "",
        questionColumn: "top",
        block: 1,
        correctAns: "a"
    },
    {
        sNo: 3,
        num: "0",
        symbol: "",
        questionColumn: "top",
        block: 1,
        correctAns: "j"
    },
    {
        sNo: 4,
        num: "9",
        symbol: "",
        questionColumn: "top",
        block: 1,
        correctAns: "i"
    },
    {
        sNo: 5,
        num: "4",
        symbol: "",
        questionColumn: "top",
        block: 1,
        correctAns: "d"
    },
    {
        sNo: 6,
        num: "",
        questionColumn: "bottom",
        symbol: "f",
        block: 2,
        correctAns: "6"
    },
    {
        sNo: 7,
        num: "",
        questionColumn: "bottom",
        symbol: "e",
        block: 2,
        correctAns: "5"
    },
    {
        sNo: 8,
        num: "",
        questionColumn: "bottom",
        symbol: "h",
        block: 2,
        correctAns: "8"
    },
    {
        sNo: 9,
        num: "",
        questionColumn: "bottom",
        symbol: "i",
        block: 2,
        correctAns: "9"
    },
    {
        sNo: 10,
        num: "",
        questionColumn: "bottom",
        symbol: "b",
        block: 2,
        correctAns: "2"
    },
    {
        sNo: 11,
        num: "4",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "d"
    },
    {
        sNo: 12,
        num: "1",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "a"
    },
    {
        sNo: 13,
        num: "9",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "i"
    },
    {
        sNo: 14,
        num: "8",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "h"
    },
    {
        sNo: 15,
        num: "4",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "d"
    },
    {
        sNo: 16,
        num: "2",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "b"
    },
    {
        sNo: 17,
        num: "9",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "i"
    },
    {
        sNo: 18,
        num: "3",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "c"
    },
    {
        sNo: 19,
        num: "5",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "e"
    },
    {
        sNo: 20,
        num: "4",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "d"
    },
    {
        sNo: 21,
        num: "8",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "h"
    },
    {
        sNo: 22,
        num: "6",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "f"
    },
    {
        sNo: 23,
        num: "5",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "e"
    },
    {
        sNo: 24,
        num: "2",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "b"
    },
    {
        sNo: 25,
        num: "7",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "g"
    },
    {
        sNo: 26,
        num: "0",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "j"
    },
    {
        sNo: 27,
        num: "",
        questionColumn: "bottom",
        symbol: "i",
        block: 3,
        correctAns: "9"
    },
    {
        sNo: 28,
        num: "1",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "a"
    },
    {
        sNo: 29,
        num: "",
        questionColumn: "bottom",
        symbol: "d",
        block: 3,
        correctAns: "4"
    },
    {
        sNo: 30,
        num: "5",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "e"
    },
    {
        sNo: 31,
        num: "6",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "f"
    },
    {
        sNo: 32,
        num: "",
        questionColumn: "bottom",
        symbol: "c",
        block: 3,
        correctAns: "3"
    },
    {
        sNo: 33,
        num: "8",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "h"
    },
    {
        sNo: 34,
        num: "9",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "i"
    },
    {
        sNo: 35,
        num: "",
        questionColumn: "bottom",
        symbol: "g",
        block: 3,
        correctAns: "7"
    },
    {
        sNo: 36,
        num: "",
        questionColumn: "bottom",
        symbol: "b",
        block: 3,
        correctAns: "2"
    },
    {
        sNo: 37,
        num: "0",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "j"
    },
    {
        sNo: 38,
        num: "",
        questionColumn: "bottom",
        symbol: "f",
        block: 3,
        correctAns: "6"
    },
    {
        sNo: 39,
        num: "4",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "d"
    },
    {
        sNo: 40,
        num: "",
        questionColumn: "bottom",
        symbol: "i",
        block: 3,
        correctAns: "9"
    },
    {
        sNo: 41,
        num: "",
        questionColumn: "bottom",
        symbol: "g",
        block: 3,
        correctAns: "7"
    },
    {
        sNo: 42,
        num: "",
        questionColumn: "bottom",
        symbol: "j",
        block: 3,
        correctAns: "0"
    },
    {
        sNo: 43,
        num: "",
        questionColumn: "bottom",
        symbol: "e",
        block: 3,
        correctAns: "5"
    },
    {
        sNo: 44,
        num: "7",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "g"
    },
    {
        sNo: 45,
        num: "",
        questionColumn: "bottom",
        symbol: "f",
        block: 3,
        correctAns: "6"
    },
    {
        sNo: 46,
        num: "1",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "a"
    },
    {
        sNo: 47,
        num: "",
        questionColumn: "bottom",
        symbol: "h",
        block: 3,
        correctAns: "8"
    },
    {
        sNo: 48,
        num: "0",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "j"
    },
    {
        sNo: 49,
        num: "",
        questionColumn: "bottom",
        symbol: "c",
        block: 3,
        correctAns: "3"
    },
    {
        sNo: 50,
        num: "6",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "f"
    },
    {
        sNo: 51,
        num: "",
        questionColumn: "bottom",
        symbol: "a",
        block: 3,
        correctAns: "1"
    },
    {
        sNo: 52,
        num: "2",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "b"
    },
    {
        sNo: 53,
        num: "",
        questionColumn: "bottom",
        symbol: "g",
        block: 3,
        correctAns: "7"
    },
    {
        sNo: 54,
        num: "4",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "d"
    },
    {
        sNo: 55,
        num: "",
        questionColumn: "bottom",
        symbol: "c",
        block: 3,
        correctAns: "3"
    },
    {
        sNo: 56,
        num: "0",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "j"
    },
    {
        sNo: 57,
        num: "",
        questionColumn: "bottom",
        symbol: "h",
        block: 3,
        correctAns: "8"
    },
    {
        sNo: 58,
        num: "0",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "j"
    },
    {
        sNo: 59,
        num: "",
        questionColumn: "bottom",
        symbol: "d",
        block: 3,
        correctAns: "4"
    },
    {
        sNo: 60,
        num: "3",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "c"
    },
    {
        sNo: 61,
        num: "1",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "a"
    },
    {
        sNo: 62,
        num: "0",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "j"
    },
    {
        sNo: 63,
        num: "3",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "c"
    },
    {
        sNo: 64,
        num: "",
        questionColumn: "bottom",
        symbol: "c",
        block: 3,
        correctAns: "3"
    },
    {
        sNo: 65,
        num: "",
        questionColumn: "bottom",
        symbol: "e",
        block: 3,
        correctAns: "5"
    },
    {
        sNo: 66,
        num: "",
        questionColumn: "bottom",
        symbol: "f",
        block: 3,
        correctAns: "6"
    },
    {
        sNo: 67,
        num: "7",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "g"
    },
    {
        sNo: 68,
        num: "9",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "i"
    },
    {
        sNo: 69,
        num: "1",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "a"
    },
    {
        sNo: 70,
        num: "5",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "e"
    },
    {
        sNo: 71,
        num: "",
        questionColumn: "bottom",
        symbol: "c",
        block: 3,
        correctAns: "3"
    },
    {
        sNo: 72,
        num: "",
        questionColumn: "bottom",
        symbol: "h",
        block: 3,
        correctAns: "8"
    },
    {
        sNo: 73,
        num: "",
        questionColumn: "bottom",
        symbol: "b",
        block: 3,
        correctAns: "2"
    },
    {
        sNo: 74,
        num: "",
        questionColumn: "bottom",
        symbol: "g",
        block: 3,
        correctAns: "7"
    },
    {
        sNo: 75,
        num: "0",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "j"
    },
    {
        sNo: 76,
        num: "5",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "e"
    },
    {
        sNo: 77,
        num: "1",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "a"
    },
    {
        sNo: 78,
        num: "0",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "j"
    },
    {
        sNo: 79,
        num: "9",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "i"
    },
    {
        sNo: 80,
        num: "3",
        symbol: "",
        questionColumn: "top",
        block: 3,
        correctAns: "c"
    }
];

export const numberSymbolButtons = [
    { num: "1", symbol: "a" },
    { num: "2", symbol: "b" },
    { num: "3", symbol: "c" },
    { num: "4", symbol: "d" },
    { num: "5", symbol: "e" },
    { num: "6", symbol: "f" },
    { num: "7", symbol: "g" },
    { num: "8", symbol: "h" },
    { num: "9", symbol: "i" },
    { num: "0", symbol: "j" }
];
