export const getSystemRatingScoring = (data) => {
    return {
        P_QDRS: calculateP_QDRS(data.symptomRating),
        CDR: calculateCDR(data.symptomRating),
        CDR_SB: calculateCDR_SB(data.symptomRating)
    };
};

const calculateCDR_SB = (DA3_D_Data) => {
    return (
        parseInt(
            DA3_D_Data.memoryAndRecall === "0.5" || DA3_D_Data.memoryAndRecall === undefined
                ? 0
                : DA3_D_Data.memoryAndRecall
        ) +
        parseInt(
            DA3_D_Data.orientation === "0.5" || DA3_D_Data.orientation === undefined ? 0 : DA3_D_Data.orientation
        ) +
        parseInt(
            DA3_D_Data.decisionMakingAndProblemSolvingAbilities === "0.5" || DA3_D_Data.decisionMakingAndProblemSolvingAbilities === undefined
                ? 0
                : DA3_D_Data.decisionMakingAndProblemSolvingAbilities
        ) +
        parseInt(
            DA3_D_Data.activitiesOutsideHome === "0.5" || DA3_D_Data.activitiesOutsideHome === undefined
                ? 0
                : DA3_D_Data.activitiesOutsideHome
        ) +
        parseInt(
            DA3_D_Data.activitiesInsideHome === "0.5" || DA3_D_Data.activitiesInsideHome === undefined
                ? 0
                : DA3_D_Data.activitiesInsideHome
        ) +
        parseInt(
            DA3_D_Data.toiletingAndPersonalHygine === "0.5" || DA3_D_Data.toiletingAndPersonalHygine === undefined
                ? 0
                : DA3_D_Data.toiletingAndPersonalHygine
        ) +
        parseInt(DA3_D_Data.behaviorAndPersonalityChanges ?? 0) +
        parseInt(DA3_D_Data.languageAndCommunicationAbilities ?? 0) +
        parseInt(DA3_D_Data.mood ?? 0) +
        parseInt(DA3_D_Data.attentionAndConcentration ?? 0)
    );
};

const calculateP_QDRS = (P_QDRS_Data) => {
    return (
        parseInt(P_QDRS_Data.memoryAndRecall ?? 0) +
        parseInt(P_QDRS_Data.orientation ?? 0) +
        parseInt(P_QDRS_Data.decisionMakingAndProblemSolvingAbilities ?? 0) +
        parseInt(P_QDRS_Data.activitiesOutsideHome ?? 0) +
        parseInt(P_QDRS_Data.activitiesInsideHome ?? 0) +
        parseInt(P_QDRS_Data.toiletingAndPersonalHygine ?? 0) +
        parseInt(P_QDRS_Data.behaviorAndPersonalityChanges ?? 0) +
        parseInt(P_QDRS_Data.languageAndCommunicationAbilities ?? 0) +
        parseInt(P_QDRS_Data.mood ?? 0) +
        parseInt(P_QDRS_Data.attentionAndConcentration ?? 0)
    );
};

const calculateCDR = (DA3_Ap_Data) => {
    return (
        parseInt(DA3_Ap_Data.memoryAndRecall ?? 0) +
        parseInt(DA3_Ap_Data.orientation ?? 0) +
        parseInt(DA3_Ap_Data.decisionMakingAndProblemSolvingAbilities ?? 0) +
        parseInt(DA3_Ap_Data.activitiesOutsideHome ?? 0) +
        parseInt(DA3_Ap_Data.activitiesInsideHome ?? 0) +
        parseInt(DA3_Ap_Data.toiletingAndPersonalHygine ?? 0) +
        parseInt(DA3_Ap_Data.behaviorAndPersonalityChanges ?? 0) +
        parseInt(DA3_Ap_Data.languageAndCommunicationAbilities ?? 0) +
        parseInt(DA3_Ap_Data.mood ?? 0) +
        parseInt(DA3_Ap_Data.attentionAndConcentration ?? 0)
    );
};
