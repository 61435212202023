import { fetchUtils } from "react-admin";
import { stringify } from "query-string";

const apiUrl = process.env.REACT_APP_API_URL;

const httpClientForCreate = fetchUtils.fetchJson;

const httpClient = (url, options: any = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: "application/json" });
    }
    const { token } = JSON.parse(localStorage.getItem("auth"));
    options.headers.set("Authorization", `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
};

export const dataProvider = {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify(params.filter),
            page: page,
            size: perPage
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url)
            .then(({ headers, json }) => ({
                data: json,
                total: parseInt(
                    headers
                        .get("content-range")
                        .split("/")
                        .pop(),
                    10
                )
            }))
            .catch((error) => {
                // Handle error appropriately
                throw new Error("Error fetching resource list", error);
            });
    },

    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`)
            .then(({ json }) => ({
                data: json
            }))
            .catch((error) => {
                // Handle error appropriately
                throw new Error("Error in getting resource");
            }),

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids })
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url)
            .then(({ json }) => ({ data: json }))
            .catch((error) => {
                // Handle error appropriately
                throw new Error("Error in getting many resource", error);
            });
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id
            })
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url)
            .then(({ headers, json }) => ({
                data: json,
                total: parseInt(
                    headers
                        .get("content-range")
                        .split("/")
                        .pop(),
                    10
                )
            }))
            .catch((error) => {
                // Handle error appropriately
                throw new Error(
                    "Error in getting Many Reference resource",
                    error
                );
            });
    },

    update: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: "PUT",
            body: JSON.stringify(params.data)
        })
            .then(({ json }) => ({ data: json }))
            .catch((error) => {
                // Handle error appropriately
                throw new Error("Error in updating resource", error);
            }),

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids })
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: "PUT",
            body: JSON.stringify(params.data)
        })
            .then(({ json }) => ({ data: json }))
            .catch((error) => {
                // Handle error appropriately
                throw new Error("Error in updating many resource", error);
            });
    },

    create: (resource, params) =>
        httpClientForCreate(`${apiUrl}/${resource}`, {
            method: "POST",
            body: JSON.stringify(params.data)
        })
            .then(({ json }) => ({
                data: { ...params.data, id: json.id }
            }))
            .catch((error) => {
                // Handle error appropriately
                throw new Error("Error in creating resouces resource", error);
            }),

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: "DELETE"
        })
            .then(({ json }) => ({ data: json }))
            .catch((error) => {
                // Handle error appropriately
                throw new Error("Error in getting deleting resource", error);
            }),

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids })
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: "DELETE"
        })
            .then(({ json }) => ({ data: json }))
            .catch((error) => {
                // Handle error appropriately
                throw new Error(
                    "Error in getting deleting many Reference",
                    error
                );
            });
    }
};
