import React from "react";
import {
    Box,
    Typography,
    FormLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Divider
} from "@mui/material";
import { TextInput, RadioButtonGroupInput } from "react-admin";

const PatientFamilyHistory = (props) => {
    return (
        <Box width='100%'>
            <Box
                borderBottom='1px solid'
                borderColor='grey.200'
                padding='10px 0'
            >
                <Typography variant='h6' gutterBottom>
                    FAMILY HISTORY
                </Typography>
            </Box>
            <Box py='20px'>
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 650 }}
                        size='small'
                        aria-label='simple table'
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    width='50%'
                                    style={{ fontSize: "1.1rem" }}
                                >
                                    Is there family history (parents,
                                    grandparents, siblings) of:
                                </TableCell>
                                <TableCell>&nbsp;</TableCell>
                                <TableCell style={{ fontSize: "1.1rem" }}>
                                    List family member affected
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>
                                        Alzheimer’s Disease or other forms of
                                        Dementia
                                    </FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='patientFamilyHistory.alzheimerDisease'
                                        label={false}
                                        choices={[
                                            { id: true, name: "Yes" },
                                            { id: false, name: "No" }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <TextInput
                                        variant='outlined'
                                        helperText={false}
                                        source='patientFamilyHistory.alzheimerDiseaseNote'
                                        label='Note'
                                        multiline
                                        fullWidth
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>Parkinson’s Disease</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='patientFamilyHistory.parkinsonDisease'
                                        label={false}
                                        choices={[
                                            { id: true, name: "Yes" },
                                            { id: false, name: "No" }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <TextInput
                                        variant='outlined'
                                        helperText={false}
                                        source='patientFamilyHistory.parkinsonDiseaseNote'
                                        label='Note'
                                        multiline
                                        fullWidth
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>Stroke</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='patientFamilyHistory.stroke'
                                        label={false}
                                        choices={[
                                            { id: true, name: "Yes" },
                                            { id: false, name: "No" }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <TextInput
                                        variant='outlined'
                                        helperText={false}
                                        source='patientFamilyHistory.strokeNote'
                                        label='Note'
                                        multiline
                                        fullWidth
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>Heart Disease</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='patientFamilyHistory.heartDisease'
                                        label={false}
                                        choices={[
                                            { id: true, name: "Yes" },
                                            { id: false, name: "No" }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <TextInput
                                        variant='outlined'
                                        helperText={false}
                                        source='patientFamilyHistory.heartDiseaseNote'
                                        label='Note'
                                        multiline
                                        fullWidth
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>Diabetes</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='patientFamilyHistory.diabetes'
                                        label={false}
                                        choices={[
                                            { id: true, name: "Yes" },
                                            { id: false, name: "No" }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <TextInput
                                        variant='outlined'
                                        helperText={false}
                                        source='patientFamilyHistory.diabetesNote'
                                        label='Note'
                                        multiline
                                        fullWidth
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ "td, th": { border: 0 } }}>
                                <TableCell component='th' scope='row'>
                                    <FormLabel>Depression</FormLabel>
                                </TableCell>
                                <TableCell>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='patientFamilyHistory.depression'
                                        label={false}
                                        choices={[
                                            { id: true, name: "Yes" },
                                            { id: false, name: "No" }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                                <TableCell>
                                    <TextInput
                                        variant='outlined'
                                        helperText={false}
                                        source='patientFamilyHistory.depressionNote'
                                        label='Note'
                                        multiline
                                        fullWidth
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Divider />
        </Box>
    );
};

export default PatientFamilyHistory;
