import { getQPAR } from "./QPAR";

export const getModifiedCaideScore = (data) => {
    return calculateModifiedCaideScore(data);
};

const calculateModifiedCaideScore = (data) => {
    return {
        Sex: data.personalInformation.gender,
        Age: data.personalInformation.currentAge,
        Education: data.personalInformation.participantHighestDegree,
        SystolicBloodPressure: data.selfReportPhyicalExamination.BP,
        BodyMassIndex: getScoreForBMI(data),
        HighCholesterolHistory: data.medicalHistory.highCholesterol.status,
        PhysicalActivityOrQPAR: getQPAR(data),
        totalScore:
            parseInt(data.personalInformation.gender === "male" ? "1" : "0") +
            getScoreForAge(data.personalInformation.currentAge) +
            getScoreForEducation(
                data.personalInformation.participantHighestDegree
            ) +
            parseInt(
                data.selfReportPhyicalExamination.BP <= "140" ? "0" : "2"
            ) +
            getScoreForBMI(data) +
            parseInt(
                data.medicalHistory.highCholesterol.status === "true"
                    ? "2"
                    : "0"
            ) +
            (getQPAR(data) >= 24 ? 0 : 3)
    };
};

const getScoreForAge = (currentAge) => {
    const ageInNum = parseInt(currentAge);
    if (ageInNum < 65) {
        return 0;
    } else if (ageInNum >= 65 && ageInNum <= 72) {
        return 1;
    } else {
        return 2;
    }
};

const getScoreForEducation = (education) => {
    switch (education) {
        case "graduateProfessionalLargeBusiness":
        case "standardCollege":
            return 0;
        case "partialCollege":
        case "highSchoolGraduate":
            return 1;
        default:
            return 2;
    }
};

export const getScoreForBMI = (data) => {
    const weightInKG = parseInt(data.selfReportPhyicalExamination.weight);
    const heightInMeter = parseInt(data.selfReportPhyicalExamination.height);
    return weightInKG / heightInMeter ** 2;
};
