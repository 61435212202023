import React from "react";
import {
    Box,
    Typography,
    FormLabel,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper
} from "@mui/material";
import { RadioButtonGroupInput } from "react-admin";

const AppliedMindfullnessProcessScale = (props) => {
    return (
        <Box width='100%'>
            <Box
                borderBottom='1px solid'
                borderColor='grey.200'
                padding='10px 0'
            >
                <Typography variant='h6' fontWeight='bold' gutterBottom>
                MINDFULLNESS ASSESSMENT
                </Typography>
                <Typography variant='body1' gutterBottom>
                Everyone gets confronted with negative or stressful events in daily life, and individuals experience
                these events in very different ways. Please choose the <b>one best answer</b> for each of the questions
                that best describes <b>your</b> use of the following techniques to approach negative or stressful events
                over the past week (past 7 days). Be sure to choose only one answer.
                </Typography>
            </Box>
            <Box py='20px'>
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 650 }}
                        size='small'
                        aria-label='simple table'
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell width="45%" style={{ fontSize: "1.1rem" }}>When faced with a stressful event, I….</TableCell>
                                <TableCell>&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>1. Observe my thoughts in a detached or non-emotional manner</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='appliedMindFullnessProcessScale.nonEmotionalManner'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "Never"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Rarely"
                                            },
                                            {
                                                id: "2",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "3",
                                                name: "Often"
                                            },
                                            {
                                                id: "4",
                                                name: "Almost Always"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>2. Relax my body when I am tense</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='appliedMindFullnessProcessScale.relaxMyBody'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "Never"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Rarely"
                                            },
                                            {
                                                id: "2",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "3",
                                                name: "Often"
                                            },
                                            {
                                                id: "4",
                                                name: "Almost Always"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>3. Can see that my thoughts are not necessarily always true</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='appliedMindFullnessProcessScale.myThoughtAreNotNecessarily'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "Never"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Rarely"
                                            },
                                            {
                                                id: "2",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "3",
                                                name: "Often"
                                            },
                                            {
                                                id: "4",
                                                name: "Almost Always"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>4. Enjoy the little things in life more fully</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='appliedMindFullnessProcessScale.enjoyTheLittleThings'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "Never"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Rarely"
                                            },
                                            {
                                                id: "2",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "3",
                                                name: "Often"
                                            },
                                            {
                                                id: "4",
                                                name: "Almost Always"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>5. Calm my emotions when I am upset</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='appliedMindFullnessProcessScale.clamMyEmotions'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "Never"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Rarely"
                                            },
                                            {
                                                id: "2",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "3",
                                                name: "Often"
                                            },
                                            {
                                                id: "4",
                                                name: "Almost Always"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>6. Stop reacting to my negative impulses</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='appliedMindFullnessProcessScale.stopReactingToMyNegativeImpluses'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "Never"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Rarely"
                                            },
                                            {
                                                id: "2",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "3",
                                                name: "Often"
                                            },
                                            {
                                                id: "4",
                                                name: "Almost Always"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>7. See the positive side of difficult circumstances</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='appliedMindFullnessProcessScale.positiveSideOfDifficultCircumstances'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "Never"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Rarely"
                                            },
                                            {
                                                id: "2",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "3",
                                                name: "Often"
                                            },
                                            {
                                                id: "4",
                                                name: "Almost Always"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>8. Reduce tension when I am stressed</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='appliedMindFullnessProcessScale.reduceTensionOnStress'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "Never"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Rarely"
                                            },
                                            {
                                                id: "2",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "3",
                                                name: "Often"
                                            },
                                            {
                                                id: "4",
                                                name: "Almost Always"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>9. Realize that I can grow stronger from difficult circumstances</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='appliedMindFullnessProcessScale.realizeToGrowStronger'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "Never"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Rarely"
                                            },
                                            {
                                                id: "2",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "3",
                                                name: "Often"
                                            },
                                            {
                                                id: "4",
                                                name: "Almost Always"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            
                            <TableRow>
                                <TableCell>
                                    <FormLabel>10. Stop my unhelpful reactions to situations</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='appliedMindFullnessProcessScale.stopMyUnHelpFulReaction'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "Never"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Rarely"
                                            },
                                            {
                                                id: "2",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "3",
                                                name: "Often"
                                            },
                                            {
                                                id: "4",
                                                name: "Almost Always"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>11. Be aware of and appreciate pleasantevents</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='appliedMindFullnessProcessScale.beAwareOf'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "Never"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Rarely"
                                            },
                                            {
                                                id: "2",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "3",
                                                name: "Often"
                                            },
                                            {
                                                id: "4",
                                                name: "Almost Always"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>12. Let go of unpleasant thoughts and feelings</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='appliedMindFullnessProcessScale.letGoOfUnpleasantThoughts'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "Never"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Rarely"
                                            },
                                            {
                                                id: "2",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "3",
                                                name: "Often"
                                            },
                                            {
                                                id: "4",
                                                name: "Almost Always"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>13. Realize that my thoughts are not always facts</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='appliedMindFullnessProcessScale.realizeThatMyThoughts'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "Never"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Rarely"
                                            },
                                            {
                                                id: "2",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "3",
                                                name: "Often"
                                            },
                                            {
                                                id: "4",
                                                name: "Almost Always"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>14. Notice pleasant things in the face of difficult circumstances</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='appliedMindFullnessProcessScale.noticePleasantThings'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "Never"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Rarely"
                                            },
                                            {
                                                id: "2",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "3",
                                                name: "Often"
                                            },
                                            {
                                                id: "4",
                                                name: "Almost Always"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>15. See alternative view of a situation</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='appliedMindFullnessProcessScale.seeAlterNativeViewOfaSituation'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "Never"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Rarely"
                                            },
                                            {
                                                id: "2",
                                                name: "Sometimes"
                                            },
                                            {
                                                id: "3",
                                                name: "Often"
                                            },
                                            {
                                                id: "4",
                                                name: "Almost Always"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default AppliedMindfullnessProcessScale;
