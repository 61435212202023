import _find from "lodash/find";
import { educationClassification } from "../EducationClassification";
import { occupationClassification } from "../OccupationClassification";

const calculateOccupationScale = (participantOccupation: string) => {
    return (
        _find(
            occupationClassification,
            (occupation) => occupation.id === participantOccupation
        ).score * 7
    );
};

const calculateEducationScale = (participantHighestDegree: string) => {
    return (
        _find(
            educationClassification,
            (education) => education.id === participantHighestDegree
        ).score * 4
    );
};

const calculateSocialClass = (totalScore: number) => {
    switch (true) {
        case totalScore >= 11 && totalScore <= 17:
            return "I";
        case totalScore >= 18 && totalScore <= 27:
            return "II";
        case totalScore >= 28 && totalScore <= 43:
            return "III";
        case totalScore >= 44 && totalScore <= 60:
            return "IV";
        case totalScore >= 60 && totalScore <= 77:
            return "V";
        default:
            break;
    }
};

export const getSocialPositionScoring = (data) => {
    return {
        participantScoring: {
            occupationScaleScoring: calculateOccupationScale(
                data.personalInformation.participantOccupation
            ),
            educationScaleScoring: calculateEducationScale(
                data.personalInformation.participantHighestDegree
            ),
            sum:
                calculateOccupationScale(
                    data.personalInformation.participantOccupation
                ) +
                calculateEducationScale(
                    data.personalInformation.participantHighestDegree
                ),
            socialClass: calculateSocialClass(
                calculateOccupationScale(
                    data.personalInformation.participantOccupation
                ) +
                    calculateEducationScale(
                        data.personalInformation.participantHighestDegree
                    )
            )
        },
        headOfHouseHoldScoring: data.personalInformation.headOfHouse &&
            data.personalInformation.headOfHouse === "false" && {
                occupationScaleScoring: calculateOccupationScale(
                    data.personalInformation.headOfHouseOccupation
                ),
                educationScaleScoring: calculateEducationScale(
                    data.personalInformation.headOfHouseHighestDegree
                ),
                sum:
                    calculateOccupationScale(
                        data.personalInformation.headOfHouseOccupation
                    ) +
                    calculateEducationScale(
                        data.personalInformation.headOfHouseHighestDegree
                    ),
                socialClass: calculateSocialClass(
                    calculateOccupationScale(
                        data.personalInformation.headOfHouseOccupation
                    ) +
                        calculateEducationScale(
                            data.personalInformation.headOfHouseHighestDegree
                        )
                )
            },
        CRU:
            77 -
            calculateOccupationScale(
                data.personalInformation.participantOccupation
            ) +
            calculateEducationScale(
                data.personalInformation.participantHighestDegree
            )
    };
};
