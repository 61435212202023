import React from "react";
import {
    Box,
    Grid,
    Typography,
    Divider,
} from "@mui/material";
import { RadioButtonGroupInput, TextInput, FormDataConsumer, required } from "react-admin";

const PersonalInformation = (props) => {

    return (
        <Box width='100%' mb="5px">
            <Grid container columnSpacing={2} rowSpacing={1}>
                <Grid item xs={4}>
                    <TextInput
                        fullWidth
                        source='id'
                        label='Your Identity Number'
                        variant='outlined'
                        type='text'
                        helperText={false}
                        validate={[required()]}
                    />
                </Grid>
            </Grid>
            <Divider style={{marginTop: "20px", marginBottom: "20px"}} />
            
            <Typography variant='h6' gutterBottom>
                BRAIN HEALTH PLATFORM
            </Typography>
            <Typography variant='subtitle1' marginBottom="20px">
                University of Miami Miller School of Medicine
            </Typography>
            
            <Grid container columnSpacing={2} rowSpacing={1}>
                <Grid item xs={4}>
                    <TextInput
                        fullWidth
                        source='personalInformation.patientName'
                        label='Your Name'
                        variant='outlined'
                        type='text'
                        helperText={false}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextInput
                        fullWidth
                        source='personalInformation.addressOfPatient'
                        label='Your Address'
                        variant='outlined'
                        helperText={false}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextInput
                        fullWidth
                        source='personalInformation.phone'
                        label='Phone'
                        variant='outlined'
                        helperText={false}
                        type="tel"
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextInput
                        fullWidth
                        source='personalInformation.eMail'
                        label=' E-Mail'
                        variant='outlined'
                        helperText={false}
                        type="email"
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextInput
                        fullWidth
                        source='personalInformation.currentAge'
                        label='Current Age'
                        variant='outlined'
                        helperText={false}
                        type="number"
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextInput
                        fullWidth
                        source='personalInformation.birthPlace'
                        label='Your Place of Birth'
                        variant='outlined'
                        helperText={false}
                    />
                </Grid>
                <Grid item xs={4}>
                    <RadioButtonGroupInput
                        row
                        helperText={false}
                        margin="none"
                        source="personalInformation.gender"
                        label="Gender"
                        defaultValue=""
                        validate={[required()]}
                        choices={[{ id: 'female', name: 'Female' }, { id: 'male', name: 'Male' }]}
                    >
                    </RadioButtonGroupInput>
                </Grid>
                <Grid item xs={4}>
                    <RadioButtonGroupInput 
                    row 
                    helperText={false} 
                    margin="none" 
                    source="personalInformation.patientDominantHand" 
                    label="Your Dominant Hand" 
                    choices={[
                        { id: 'right', name: 'Right' },
                        { id: 'left', name: 'Left' },
                        { id: 'ambidextrous', name: 'Ambidextrous' },
                    ]}>
                    </RadioButtonGroupInput>
                </Grid>
                <Grid item xs={4}>
                    <RadioButtonGroupInput row helperText={false} margin="none" source="personalInformation.ethinicity" label="Hispanic/Latino Ethnicity" choices={[
                        { id: true, name: 'Yes' },
                        { id: false, name: 'No' },]}>
                    </RadioButtonGroupInput>
                </Grid>
                <FormDataConsumer>
                    {({ formData, ...rest }) => formData.personalInformation?.ethinicity === 'true' &&
                        <Grid item xs={12} display="flex" alignItems="end">
                            <RadioButtonGroupInput row helperText={false} margin="none" source="personalInformation.familyOrigin" label="Choose region of family origin" choices={[
                                { id: 'mexican', name: 'Mexican' },
                                { id: 'puertoRican', name: 'Puerto Rican' },
                                { id: 'cuban', name: 'Cuban' },
                                { id: 'dominican', name: 'Dominican' },
                                { id: 'centralAmerican', name: 'Central American' },
                                { id: 'southAmerican', name: 'South American' },
                                { id: 'other', name: 'Other' }]}>
                            </RadioButtonGroupInput>
                            <FormDataConsumer>
                                {({ formData, ...rest }) => formData?.personalInformation?.familyOrigin === 'other' &&
                                    <TextInput source="personalInformation.familyOriginOther" label="Family Origin" variant='outlined'
                                        type='text'
                                        helperText={false} {...rest} />
                                }
                            </FormDataConsumer>
                        </Grid>
                    }
                </FormDataConsumer>
                <Grid item xs={4}>
                    <TextInput
                        fullWidth
                        source='personalInformation.completedYearsOfEducation'
                        label='Completed Years of Education'
                        variant='outlined'
                        type='text'
                        helperText={false}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextInput
                        fullWidth
                        source='personalInformation.highestDegreeEarned'
                        label='Highest Degree Earned'
                        variant='outlined'
                        type='text'
                        helperText={false}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextInput
                        fullWidth
                        source='personalInformation.MajorOccupation'
                        label='Major Occupation'
                        variant='outlined'
                        type='text'
                        helperText={false}
                    />
                </Grid>
                <Grid item xs={6} display="flex" alignItems="end">
                    <RadioButtonGroupInput row helperText={false} margin="none" label="Primary Language" source="personalInformation.primaryLanguage" choices={[
                        { id: 'english', name: 'English' },
                        { id: 'spanish', name: 'Spanish' },
                        { id: 'other', name: 'Other' },
                    ]}>
                    </RadioButtonGroupInput>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => formData?.personalInformation?.primaryLanguage === 'other' &&
                            <TextInput source="personalInformation.primaryLanguageOther" label="Language" variant='outlined'
                                type='text'
                                helperText={false} {...rest} />
                        }
                    </FormDataConsumer>
                </Grid>

                <Grid item xs={6} display="flex" alignItems="end">
                    <RadioButtonGroupInput row helperText={false} margin="none" label="Retired" source="personalInformation.retired" choices={[
                        { id: true, name: 'Yes' },
                        { id: false, name: 'No' },
                    ]}>
                    </RadioButtonGroupInput>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => formData?.personalInformation?.retired === 'true' &&
                            <TextInput source="personalInformation.retiredYear" label="When?" variant='outlined'
                                type='text'
                                helperText={false} {...rest} />
                        }
                    </FormDataConsumer>
                </Grid>
                <Grid item xs={12} display="flex" alignItems="end">
                    <RadioButtonGroupInput row helperText={false} margin="none" label="Current Living Situation" source="personalInformation.currentLivingSituation" choices={[
                        { id: 'livesAlone', name: 'Lives Alone' },
                        { id: 'livesWithRelativeFriend', name: 'Lives with Relative/Friend' },
                        { id: 'livesWithSpousePartner', name: 'Lives with spouse/partner' },
                        { id: 'livesWithChild', name: 'Lives with Child' },
                        { id: 'other', name: 'Other' },
                    ]}>
                    </RadioButtonGroupInput>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => formData?.personalInformation?.currentLivingSituation === 'other' &&
                            <TextInput source="personalInformation.livesWithOther" label="  " variant='outlined'
                                type='text'
                                helperText={false} {...rest} />
                        }
                    </FormDataConsumer>
                </Grid>
                <Grid item xs={12} display="flex" alignItems="end">
                    <RadioButtonGroupInput row helperText={false} margin="none" label="Type of Housing" source="personalInformation.typeOfHousing" choices={[
                        { id: 'singleStoryHome', name: 'Single Story Home' },
                        { id: 'twoStoryHome', name: 'Two Story Home' },
                        { id: 'apartment', name: 'Apartment/Condo' },
                        { id: 'seniorHousing', name: 'Senior Housing' },
                        { id: 'assistedLiving', name: 'Assisted Living' },
                        { id: 'nursingHome', name: 'Nursing Home' },
                        { id: 'other', name: 'Other' },
                    ]}>
                    </RadioButtonGroupInput>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => formData?.personalInformation?.typeOfHousing === 'other' &&
                            <TextInput source="personalInformation.typeOfHousingOther" label="Housing" variant='outlined'
                                type='text'
                                helperText={false} {...rest} />
                        }
                    </FormDataConsumer>
                </Grid>
            </Grid>
            <Divider style={{marginTop: "20px"}} /> 
        </Box>
    );
};

export default PersonalInformation;
