import React from "react";
import {
    Box,
    Typography,
    FormLabel,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper
} from "@mui/material";
import { RadioButtonGroupInput } from "react-admin";

const CognitiveAndLeisureActivities = (props) => {
    return (
        <Box width='100%'>
            <Box
                borderBottom='1px solid'
                borderColor='grey.200'
                padding='10px 0'
            >
                <Typography variant='h6' fontWeight='bold' gutterBottom>
                    COGNITIVE &amp; LEISURE ACTIVITIES (CLAS)
                </Typography>
                <Typography variant='body1' gutterBottom>
                    Please rate your cognitive and leisure activities over the{" "}
                    <b>past year</b>. Choose the <b>one best answer</b> that
                    best fits your activities
                </Typography>
            </Box>
            <Box py='20px'>
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 650 }}
                        size='small'
                        aria-label='simple table'
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    width='30%'
                                    style={{ fontSize: "1.1rem" }}
                                >
                                    Type of Activity
                                </TableCell>
                                <TableCell>
                                    How often do you participate in each
                                    activity (Check One)
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        1. Chess, Checkers, Backgammon
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='cognitiveAndLeisureActivities.chessCheckersBackgammon'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "Never"
                                            },
                                            {
                                                id: "1",
                                                name: "Several times per year"
                                            },
                                            {
                                                id: "2",
                                                name: "Several times per month"
                                            },
                                            {
                                                id: "3",
                                                name: "Once per week"
                                            },
                                            {
                                                id: "4",
                                                name: "Several times per week"
                                            },
                                            {
                                                id: "5",
                                                name: "Daily"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        2. Crossword puzzles, Jigsaw puzzles,
                                        Sudoku
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='cognitiveAndLeisureActivities.crosswordPuzzlesJigsawPuzzlesSudoku'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "Never"
                                            },
                                            {
                                                id: "1",
                                                name: "Several times per year"
                                            },
                                            {
                                                id: "2",
                                                name: "Several times per month"
                                            },
                                            {
                                                id: "3",
                                                name: "Once per week"
                                            },
                                            {
                                                id: "4",
                                                name: "Several times per week"
                                            },
                                            {
                                                id: "5",
                                                name: "Daily"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        3. Playing cards or Board Games
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='cognitiveAndLeisureActivities.playingCard'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "Never"
                                            },
                                            {
                                                id: "1",
                                                name: "Several times per year"
                                            },
                                            {
                                                id: "2",
                                                name: "Several times per month"
                                            },
                                            {
                                                id: "3",
                                                name: "Once per week"
                                            },
                                            {
                                                id: "4",
                                                name: "Several times per week"
                                            },
                                            {
                                                id: "5",
                                                name: "Daily"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        4. Socializing with friends
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='cognitiveAndLeisureActivities.socializingWithFriends'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "Never"
                                            },
                                            {
                                                id: "1",
                                                name: "Several times per year"
                                            },
                                            {
                                                id: "2",
                                                name: "Several times per month"
                                            },
                                            {
                                                id: "3",
                                                name: "Once per week"
                                            },
                                            {
                                                id: "4",
                                                name: "Several times per week"
                                            },
                                            {
                                                id: "5",
                                                name: "Daily"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        5. Attending a club or group activity
                                        outside the home
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='cognitiveAndLeisureActivities.attendingClub'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "Never"
                                            },
                                            {
                                                id: "1",
                                                name: "Several times per year"
                                            },
                                            {
                                                id: "2",
                                                name: "Several times per month"
                                            },
                                            {
                                                id: "3",
                                                name: "Once per week"
                                            },
                                            {
                                                id: "4",
                                                name: "Several times per week"
                                            },
                                            {
                                                id: "5",
                                                name: "Daily"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>6. Volunteering</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='cognitiveAndLeisureActivities.volunteering'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "Never"
                                            },
                                            {
                                                id: "1",
                                                name: "Several times per year"
                                            },
                                            {
                                                id: "2",
                                                name: "Several times per month"
                                            },
                                            {
                                                id: "3",
                                                name: "Once per week"
                                            },
                                            {
                                                id: "4",
                                                name: "Several times per week"
                                            },
                                            {
                                                id: "5",
                                                name: "Daily"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        7. Painting, drawing or other
                                        arts/crafts
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='cognitiveAndLeisureActivities.painting'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "Never"
                                            },
                                            {
                                                id: "1",
                                                name: "Several times per year"
                                            },
                                            {
                                                id: "2",
                                                name: "Several times per month"
                                            },
                                            {
                                                id: "3",
                                                name: "Once per week"
                                            },
                                            {
                                                id: "4",
                                                name: "Several times per week"
                                            },
                                            {
                                                id: "5",
                                                name: "Daily"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        8. Singing or playing instrument
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='cognitiveAndLeisureActivities.singing'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "Never"
                                            },
                                            {
                                                id: "1",
                                                name: "Several times per year"
                                            },
                                            {
                                                id: "2",
                                                name: "Several times per month"
                                            },
                                            {
                                                id: "3",
                                                name: "Once per week"
                                            },
                                            {
                                                id: "4",
                                                name: "Several times per week"
                                            },
                                            {
                                                id: "5",
                                                name: "Daily"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        9. Watching TV or listening to music
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='cognitiveAndLeisureActivities.watchingTV'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "Never"
                                            },
                                            {
                                                id: "1",
                                                name: "Several times per year"
                                            },
                                            {
                                                id: "2",
                                                name: "Several times per month"
                                            },
                                            {
                                                id: "3",
                                                name: "Once per week"
                                            },
                                            {
                                                id: "4",
                                                name: "Several times per week"
                                            },
                                            {
                                                id: "5",
                                                name: "Daily"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        10. Reading a newspaper, book or
                                        magazine
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='cognitiveAndLeisureActivities.readingNewspaper'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "Never"
                                            },
                                            {
                                                id: "1",
                                                name: "Several times per year"
                                            },
                                            {
                                                id: "2",
                                                name: "Several times per month"
                                            },
                                            {
                                                id: "3",
                                                name: "Once per week"
                                            },
                                            {
                                                id: "4",
                                                name: "Several times per week"
                                            },
                                            {
                                                id: "5",
                                                name: "Daily"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        11. Attending the theatre, concert, or
                                        symphony
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='cognitiveAndLeisureActivities.attendingTheater'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "Never"
                                            },
                                            {
                                                id: "1",
                                                name: "Several times per year"
                                            },
                                            {
                                                id: "2",
                                                name: "Several times per month"
                                            },
                                            {
                                                id: "3",
                                                name: "Once per week"
                                            },
                                            {
                                                id: "4",
                                                name: "Several times per week"
                                            },
                                            {
                                                id: "5",
                                                name: "Daily"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        12. Going to a museum or exhibition
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='cognitiveAndLeisureActivities.goingToMuseum'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "Never"
                                            },
                                            {
                                                id: "1",
                                                name: "Several times per year"
                                            },
                                            {
                                                id: "2",
                                                name: "Several times per month"
                                            },
                                            {
                                                id: "3",
                                                name: "Once per week"
                                            },
                                            {
                                                id: "4",
                                                name: "Several times per week"
                                            },
                                            {
                                                id: "5",
                                                name: "Daily"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        13. Attending a conference, lecture, or
                                        course
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='cognitiveAndLeisureActivities.attendingConference'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "Never"
                                            },
                                            {
                                                id: "1",
                                                name: "Several times per year"
                                            },
                                            {
                                                id: "2",
                                                name: "Several times per month"
                                            },
                                            {
                                                id: "3",
                                                name: "Once per week"
                                            },
                                            {
                                                id: "4",
                                                name: "Several times per week"
                                            },
                                            {
                                                id: "5",
                                                name: "Daily"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        14. Attending a religious service
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='cognitiveAndLeisureActivities.attendingReligiousService'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "Never"
                                            },
                                            {
                                                id: "1",
                                                name: "Several times per year"
                                            },
                                            {
                                                id: "2",
                                                name: "Several times per month"
                                            },
                                            {
                                                id: "3",
                                                name: "Once per week"
                                            },
                                            {
                                                id: "4",
                                                name: "Several times per week"
                                            },
                                            {
                                                id: "5",
                                                name: "Daily"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        16. Writing a letter, poem, journal or
                                        diary entry
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='cognitiveAndLeisureActivities.writingLetterPoem'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "Never"
                                            },
                                            {
                                                id: "1",
                                                name: "Several times per year"
                                            },
                                            {
                                                id: "2",
                                                name: "Several times per month"
                                            },
                                            {
                                                id: "3",
                                                name: "Once per week"
                                            },
                                            {
                                                id: "4",
                                                name: "Several times per week"
                                            },
                                            {
                                                id: "5",
                                                name: "Daily"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        16. Exercise (any type)
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        source='cognitiveAndLeisureActivities.exercise'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "Never"
                                            },
                                            {
                                                id: "1",
                                                name: "Several times per year"
                                            },
                                            {
                                                id: "2",
                                                name: "Several times per month"
                                            },
                                            {
                                                id: "3",
                                                name: "Once per week"
                                            },
                                            {
                                                id: "4",
                                                name: "Several times per week"
                                            },
                                            {
                                                id: "5",
                                                name: "Daily"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default CognitiveAndLeisureActivities;
