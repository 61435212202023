export const getAMPSScoring = (data) => {
    return {
        decentering: calculateDecentering(data.appliedMindFullnessProcessScale),
        positiveEmotionalRegulation: calculatePositiveEmotionalRegulation(
            data.appliedMindFullnessProcessScale
        ),
        negativeEmotionalRegulation: calculateNegativeEmotionalRegulation(
            data.appliedMindFullnessProcessScale
        ),
        total: calculateDecentering(data.appliedMindFullnessProcessScale) + calculatePositiveEmotionalRegulation(data.appliedMindFullnessProcessScale) + calculateNegativeEmotionalRegulation(data.appliedMindFullnessProcessScale),
    };
};

const calculateDecentering = (data) => {
    return (
        parseInt(data.nonEmotionalManner ?? 0) +
        parseInt(data.myThoughtAreNotNecessarily ?? 0) +
        parseInt(data.letGoOfUnpleasantThoughts ?? 0) +
        parseInt(data.realizeThatMyThoughts ?? 0) +
        parseInt(data.seeAlterNativeViewOfaSituation ?? 0)
    );
};

const calculatePositiveEmotionalRegulation = (data) => {
    return (
        parseInt(data.enjoyTheLittleThings ?? 0) +
        parseInt(data.positiveSideOfDifficultCircumstances ?? 0) +
        parseInt(data.realizeToGrowStronger ?? 0) +
        parseInt(data.beAwareOf ?? 0) +
        parseInt(data.noticePleasantThings ?? 0)
    );
};

const calculateNegativeEmotionalRegulation = (data) => {
    return (
        parseInt(data.relaxMyBody ?? 0) +
        parseInt(data.clamMyEmotions ?? 0) +
        parseInt(data.stopReactingToMyNegativeImpluses ?? 0) +
        parseInt(data.reduceTensionOnStress ?? 0) +
        parseInt(data.stopMyUnHelpFulReaction ?? 0)
    );
};
