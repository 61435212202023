export const educationClassification = [
    {
        id: 'graduateProfessionalLargeBusiness',
        name: 'Graduate professional large business, major professionals training',
        score: 1,
    },
    {
        id: 'standardCollege',
        name: 'Standard college/university graduate',
        score: 2,
    },
    {
        id: 'partialCollege',
        name: 'Partial college',
        score: 3,
    },
    {
        id: 'highSchoolGraduate',
        name: 'High school graduate (12)',
        score: 4,
    },
    {
        id: 'partialHighSchool',
        name: 'Partial high school (10,11)',
        score: 5,
    },
    {
        id: 'juniorHighSchool',
        name: 'Junior high school (7,8,9)',
        score: 6,
    },
    {
        id: 'lessThan7YearsOfSchool',
        name: 'Less than 7 years of school',
        score: 7,
    },

];
