import { Box, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useRedirect } from "react-admin";

const LandingPage = () => {
    const redirect = useRedirect();
    useEffect(() => {
        redirect("/brainHealth/create");
    }, []);

  return (
    <Box py={6} textAlign='center'>
            <CircularProgress />
        </Box>
  );
};

export default LandingPage;
