import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useAuthState, useRedirect } from "react-admin";

import { Button, Card, CardActions, CircularProgress } from "@mui/material";
import {
    Form,
    required,
    TextInput,
    useTranslate,
    useLogin,
    useNotify
} from "react-admin";

import Box from "@mui/material/Box";

const Login = () => {
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();
    const notify = useNotify();
    const login = useLogin();
    const location = useLocation();
    const { authenticated } = useAuthState();
    const redirect = useRedirect();
    if (authenticated) {
        redirect("/brainHealth");
    }

    const handleSubmit = (auth: FormValues) => {
        setLoading(true);
        login(
            auth,
            location.state ? (location.state as any).nextPathname : "/"
        ).catch((error: Error) => {
            setLoading(false);
            notify(
                typeof error === "string"
                    ? error
                    : typeof error === "undefined" || !error.message
                    ? "ra.auth.sign_in_error"
                    : error.message,
                {
                    type: "warning",
                    messageArgs: {
                        _:
                            typeof error === "string"
                                ? error
                                : error && error.message
                                ? error.message
                                : undefined
                    }
                }
            );
        });
    };

    return (
        <Form onSubmit={handleSubmit} noValidate>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "100vh",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    backgroundColor: "primary.main",
                    backgroundImage:
                        "url(https://umiamibrainhealth.org/wp-content/uploads/2021/09/background-graphic-network-07.png)",
                    backgroundPosition: "left bottom",
                    backgroundRepeat: "no-repeat"
                }}
            >
                <Card sx={{ minWidth: 300, marginTop: "6em" }}>
                    <Box
                        sx={{
                            margin: "1em",
                            display: "flex",
                            justifyContent: "center"
                        }}
                    >
                        <img
                            src='https://umiamibrainhealth.org/wp-content/uploads/2021/10/image001.png'
                            alt=''
                            width='320px'
                        />
                    </Box>
                    <Box sx={{ padding: "0 1em 1em 1em" }} mt={4}>
                        <Box sx={{ marginTop: "1em" }}>
                            <TextInput
                                autoFocus
                                source='username'
                                label={translate("ra.auth.username")}
                                disabled={loading}
                                validate={required()}
                                fullWidth
                            />
                        </Box>
                        <Box sx={{ marginTop: "1em" }}>
                            <TextInput
                                source='password'
                                label={translate("ra.auth.password")}
                                type='password'
                                disabled={loading}
                                validate={required()}
                                fullWidth
                            />
                        </Box>
                    </Box>
                    <CardActions sx={{ padding: "0 1em 1em 1em" }}>
                        <Button
                            variant='contained'
                            type='submit'
                            color='secondary'
                            disabled={loading}
                            fullWidth
                        >
                            {loading && (
                                <CircularProgress size={25} thickness={2} />
                            )}
                            {translate("ra.auth.sign_in")}
                        </Button>
                    </CardActions>
                </Card>
            </Box>
        </Form>
    );
};

Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string
};

export default Login;

interface FormValues {
    username?: string;
    password?: string;
}
