export const getTheHealthyBrainScoring = (data) => {
    return calculateHealthyBrain(data.theHealthyBrain);
};

const calculateHealthyBrain = (data) => {
    return (
        parseInt(data.extrovert ?? 0) +
        parseInt(data.remembering ?? 0) +
        parseInt(data.makingDecision ?? 0) +
        parseInt(data.keepAccurateTrack ?? 0) +
        parseInt(data.takingCareOfFinancialMatters ?? 0) +
        parseInt(data.carryingOutMyEverydayActivities ?? 0) +
        parseInt(data.havingConversations ?? 0) +
        parseInt(data.doingMyHouseholdChores ?? 0) +
        parseInt(data.travelingIndependently ?? 0) +
        parseInt(data.doingMyHobby ?? 0)
    );
};
