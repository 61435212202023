import { Admin, Resource, CustomRoutes } from "react-admin";
import brainHealth from "./brainHealth";
import { myTheme } from "./myTheme";
import Layout from "./Layout";
import { authProvider } from "./authProvider";
import Login from "./Login";
import { Dashboard } from "./Dashboard";
import { dataProvider } from "./dataProvider";
import { Route } from 'react-router-dom';
import BrainHealthCreateForm from "./brainHealth/BrainHealthCreateForm";
import LandingPage from "./LandingPage";

const App = () => (
    <Admin
        dashboard={Dashboard}
        theme={myTheme}
        dataProvider={dataProvider}
        layout={Layout}
        authProvider={authProvider}
        loginPage={Login}
    >
        <Resource name='brainHealth' {...brainHealth} />
        <CustomRoutes>
            <Route path="/" element={<LandingPage />} />
        </CustomRoutes>
    </Admin>
);

export default App;
