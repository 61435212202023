export const getCognitiveAndLeisureActivitiesScoring = (data) => {
    return calculateCognitiveAndLeisureActivities(
        data.cognitiveAndLeisureActivities
    );
};

const calculateCognitiveAndLeisureActivities = (data) => {
    return (
        parseInt(data.chessCheckersBackgammon ?? 0) +
        parseInt(data.crosswordPuzzlesJigsawPuzzlesSudoku ?? 0) +
        parseInt(data.playingCard ?? 0) +
        parseInt(data.socializingWithFriends ?? 0) +
        parseInt(data.attendingClub ?? 0) +
        parseInt(data.volunteering ?? 0) +
        parseInt(data.painting ?? 0) +
        parseInt(data.singing ?? 0) +
        parseInt(data.watchingTV ?? 0) +
        parseInt(data.readingNewspaper ?? 0) +
        parseInt(data.attendingTheater ?? 0) +
        parseInt(data.goingToMuseum ?? 0) +
        parseInt(data.attendingConference ?? 0) +
        parseInt(data.attendingReligiousService ?? 0) +
        parseInt(data.writingLetterPoem ?? 0) +
        parseInt(data.exercise ?? 0)
    );
};
