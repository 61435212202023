import React from "react";
import {
    Box,
    Grid,
    Typography,
    Divider
} from "@mui/material";
import _sortBy from "lodash/sortBy";
import { AutocompleteInput, RadioButtonGroupInput, FormDataConsumer } from "react-admin";
import { occupationClassification } from "./OccupationClassification";
import { educationClassification } from "./EducationClassification";

const HollingHeadIndexOfSocialPosition = (props) => {
    return (
        <Box width='100%' marginBottom="5px">
            <Box py="20px">
                <Grid container alignItems="top" columnSpacing={2} rowSpacing={0}>
                    <Grid item xs={4} mb={1}>
                        <Box>
                            <Typography fontSize="1rem" mb={0} variant='h6' gutterBottom>
                                Your Major Occupation
                            </Typography>
                        </Box>
                        <Grid>
                            <Box>
                                <AutocompleteInput
                                    variant='outlined'
                                    source="personalInformation.participantOccupation"
                                    label="Your Major Occupation"
                                    helperText={false}
                                    choices={_sortBy(occupationClassification, 'name')} />
                            </Box>
                            <Box>
                                <AutocompleteInput
                                    variant='outlined'
                                    source="personalInformation.participantHighestDegree"
                                    label="Your Highest Education"
                                    helperText={false}
                                    choices={educationClassification} />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} mb={1}>
                        <RadioButtonGroupInput
                            row
                            helperText={false}
                            margin="none"
                            source="personalInformation.headOfHouse"
                            label="Are you head of household"
                            choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
                        >
                        </RadioButtonGroupInput>
                    </Grid>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => formData?.personalInformation?.headOfHouse === 'false' &&
                            <Grid item xs={4}>
                                <Box>
                                    <Typography fontSize="1rem" mb={0} variant='h6' gutterBottom>
                                        Head of Household
                                    </Typography>
                                </Box>
                                <Grid>
                                    <Box>
                                        <AutocompleteInput
                                            variant='outlined'
                                            source="personalInformation.headOfHouseOccupation"
                                            label="Head of House Occupation"
                                            helperText={false}
                                            choices={_sortBy(occupationClassification, 'name')} />
                                    </Box>
                                    <Box>
                                        <AutocompleteInput
                                            variant='outlined'
                                            source="personalInformation.headOfHouseHighestDegree"
                                            label="Head of household highest education"
                                            helperText={false}
                                            choices={educationClassification} />
                                    </Box>
                                </Grid>
                            </Grid>
                        }
                    </FormDataConsumer>
                </Grid>
            </Box>
            <Divider style={{ marginTop: "20px" }} />
        </Box>
    );
};

export default HollingHeadIndexOfSocialPosition;
