import React from "react";
import { Box } from "@mui/material";
import iconAngleLeft from "../icons/iconAngleLeft.svg";
import iconAngleUp from "../icons/iconAngleUp.svg";
import iconCircle from "../icons/iconCircle.svg";
import iconCross from "../icons/iconCross.svg";
import iconEquals from "../icons/iconEquals.svg";
import iconMinus from "../icons/iconMinus.svg";
import iconPlus from "../icons/iconPlus.svg";
import iconRectangle from "../icons/iconRectangle.svg";
import iconTriangle from "../icons/iconTriangle.svg";
import iconReverseT from "../icons/iconReverseT.svg";

export type AlphabetToSymbol = {
    alphabet: string;
};

const convertAlphabetToSymbol = (value: string) => {
    switch (value) {
        case "a":
            return <img src={iconTriangle} />;
        case "b":
            return <img src={iconCross} />;
        case "c":
            return <img src={iconMinus} />;
        case "d":
            return <img src={iconReverseT} />;
        case "e":
            return <img src={iconRectangle} />;
        case "f":
            return <img src={iconAngleLeft} />;
        case "g":
            return <img src={iconPlus} />;
        case "h":
            return <img src={iconCircle} />;
        case "i":
            return <img src={iconAngleUp} />;
        case "j":
            return <img src={iconEquals} />;
        default:
            return <Box>{value}</Box>;
    }
};

export const AlphabetToSymbol = ({
    alphabet
}: AlphabetToSymbol): JSX.Element => {
    return convertAlphabetToSymbol(alphabet);
};

export default AlphabetToSymbol;
