import React from "react";
import {
    Box,
    Typography,
    FormLabel,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper
} from "@mui/material";
import { RadioButtonGroupInput } from "react-admin";

const MediterraneanDashIntervention = (props) => {
    return (
        <Box width='100%'>
            <Box
                borderBottom='1px solid'
                borderColor='grey.200'
                padding='10px 0'
            >
                <Typography variant='h6' fontWeight='bold' gutterBottom>
                    DIETARY QUESTIONS
                </Typography>
                <Typography variant='body1' gutterBottom>
                    The following questions will help us understand your dietary
                    intake and nutritional status in relation to brain health.
                    Please try to recall your general pattern of food
                    consumption over the past 3 months, considering all meals
                    (Breakfast, Lunch, Dinner, Snacks). For each category,
                    choose the <b>ONE best option</b> that best characterizes
                    your diet over the past 3 months. Please answer each
                    category.
                </Typography>
            </Box>
            <Box py='20px'>
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 650 }}
                        size='small'
                        aria-label='simple table'
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    width='30%'
                                    style={{ fontSize: "1.1rem" }}
                                >
                                    Diet Components
                                </TableCell>
                                <TableCell>&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        1. Green leafy vegetables
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='mediterraneanDashIntervention.greenLeafyVegetables'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "2 or less servings/week"
                                            },
                                            {
                                                id: "0.5",
                                                name: "2 to 5 servings/week"
                                            },
                                            {
                                                id: "1",
                                                name: "6 or more servings/week"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>2. Other vegetables</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='mediterraneanDashIntervention.otherVegetables'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "Less than 5 servings/week"
                                            },
                                            {
                                                id: "0.5",
                                                name: "5 to 6 servings/week"
                                            },
                                            {
                                                id: "1",
                                                name: "1 or more servings/day"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>3. Berries</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='mediterraneanDashIntervention.berries'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "Less than 1 serving/week"
                                            },
                                            {
                                                id: "0.5",
                                                name: "1 serving/week"
                                            },
                                            {
                                                id: "1",
                                                name: "2 or more servings/week"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>4. Nuts</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='mediterraneanDashIntervention.nuts'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "Less than 1 serving/month"
                                            },
                                            {
                                                id: "0.5",
                                                name:
                                                    "1 serving /month - less than 5 servings/week"
                                            },
                                            {
                                                id: "1",
                                                name: "5 or more servings/week"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>5. Olive oil</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='mediterraneanDashIntervention.oliveOil'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "Not primary oil used"
                                            },
                                            {
                                                id: "1",
                                                name: "Primary oil used"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>6. Butter, margarine</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='mediterraneanDashIntervention.butterMargarine'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "More than 2 tablespoons/day"
                                            },
                                            {
                                                id: "0.5",
                                                name: "1-2 tablespoons/day"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Less than 1 tablespoon/day"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>7. Cheese</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='mediterraneanDashIntervention.cheese'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "7 or more servings/week"
                                            },
                                            {
                                                id: "0.5",
                                                name: "1-6 servings/week"
                                            },
                                            {
                                                id: "1",
                                                name: "Less than 1 serving/week"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>8. Whole grains</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='mediterraneanDashIntervention.wholeGrains'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "Less than 1 serving/day"
                                            },
                                            {
                                                id: "0.5",
                                                name: "1-2 serving/day"
                                            },
                                            {
                                                id: "1",
                                                name: "3 or more serving/day"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>9.Fish (not fried)</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='mediterraneanDashIntervention.fish'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "Rarely"
                                            },
                                            {
                                                id: "0.5",
                                                name: "1-3 meals/month"
                                            },
                                            {
                                                id: "1",
                                                name: "1 or more meals/week"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>10. Beans</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='mediterraneanDashIntervention.beans'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "Less than 1 meal/week"
                                            },
                                            {
                                                id: "0.5",
                                                name: "1-3 meals/week"
                                            },
                                            {
                                                id: "1",
                                                name: "3 or more meals/week"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        11. Poultry (not fried)
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='mediterraneanDashIntervention.poultry'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "Less than 1 meal/week"
                                            },
                                            {
                                                id: "0.5",
                                                name: "1-3 meals/week"
                                            },
                                            {
                                                id: "1",
                                                name: "3 or more meals/week"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        12. Red meat and products
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='mediterraneanDashIntervention.redMeat'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "7 or more meals/week"
                                            },
                                            {
                                                id: "0.5",
                                                name: "4-6 meals/week"
                                            },
                                            {
                                                id: "1",
                                                name: "Less than 4 meals/week"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>13. Fast fried foods</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='mediterraneanDashIntervention.fastFriedFoods'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "4 or more times/week"
                                            },
                                            {
                                                id: "0.5",
                                                name: "1-3 times/week"
                                            },
                                            {
                                                id: "1",
                                                name: "Less than 1 time/week"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>
                                        14. Pastries and sweets
                                    </FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='mediterraneanDashIntervention.pastries'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name: "7 or more servings/week"
                                            },
                                            {
                                                id: "0.5",
                                                name: "5-6 servings/week"
                                            },
                                            {
                                                id: "1",
                                                name:
                                                    "Less than 5 servings/week"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <FormLabel>15. Wine</FormLabel>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                    <RadioButtonGroupInput
                                        row
                                        helperText={false}
                                        margin='none'
                                        style={{ fontSize: "2rem" }}
                                        source='mediterraneanDashIntervention.wine'
                                        label={false}
                                        choices={[
                                            {
                                                id: "0",
                                                name:
                                                    "> glass/day OR never drink"
                                            },
                                            {
                                                id: "0.5",
                                                name:
                                                    "1 glass/month – 6 glasses/week"
                                            },
                                            {
                                                id: "1",
                                                name: "1 glass/day"
                                            }
                                        ]}
                                    ></RadioButtonGroupInput>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default MediterraneanDashIntervention;
