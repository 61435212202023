import { getScoreForBMI } from "./modifiedCaideScoreCalculation";

export const getFunctionalComorbidityIndexCalculation = (data) => {
    return calculateFunctionalComorbidityIndexCalculation(data);
};

const calculateFunctionalComorbidityIndexCalculation = (data) => {
    return ({
        Arthritis : data.medicalHistory.autoimmuneDisorders.status,
        Osteoporosis : data.medicalHistory.arthritisOsteoarthritis.status,
        Asthma : data.medicalHistory.chronicLungDisease.status,
        COPD : data.medicalHistory.miniStroke.status,
        Angina : data.medicalHistory.chronicLungDisease.status,
        CongestiveHeartFailure : data.medicalHistory.heartDisease.effect,
        MyocardialInfarct : data.medicalHistory.heartDisease.effect,
        NeurologicalDisease : data.medicalHistory.miniStroke.status,
        StrokeOrTIA : data.patientFamilyHistory.alzheimerDisease,
        PeripheralVascularDisease : data.medicalHistory.peripheralVascularDisease.status,
        Diabetes : data.medicalHistory.medicalHistory?.diabetes?.status,
        UpperGIDisease : data.medicalHistory.digestiveSystemDisease.status,
        Depression : data.medicalHistory.depression.status,
        AnxietyOrPanicDisorder : data.medicalHistory.anxiety.status ,
        VisualImpairment : data.medicalHistory.eyeDisease.status,
        HearingImpairment : data.medicalHistory.hearingLoss.status,
        DegenerativeDiscDisease : data.medicalHistory.degenerativeDiscDisease.status,
        ObesityOrBMI : getScoreForBMI(data),
        totalScore : parseInt(data.medicalHistory.autoimmuneDisorders.status === "true" ? "1" : "0") +
        parseInt(data.medicalHistory.arthritisOsteoarthritis.status === "true" ? "1" : "0") +
        parseInt(data.medicalHistory.chronicLungDisease.status === "true" ? "1" : "0") +
        parseInt(data.medicalHistory.miniStroke.status === "true" ? "1" : "0") +
        parseInt(data.medicalHistory.chronicLungDisease.status === "true" ? "1" : "0") +
        parseInt(data.medicalHistory.heartDisease.effect === "congestiveHeartFailure" ? "1" : "0") +
        parseInt(data.medicalHistory.heartDisease.effect === "hearthAttack" ? "1" : "0") +
        parseInt(data.medicalHistory.miniStroke.status === "true" ? "1" : "0") +
        parseInt(data.patientFamilyHistory.alzheimerDisease === "true" ? "1" : "0") +
        parseInt(data.medicalHistory.peripheralVascularDisease.status === "true" ? "1" : "0") +
        parseInt(data.medicalHistory.medicalHistory?.diabetes?.status === "true" ? "1" : "0") +
        parseInt(data.medicalHistory.digestiveSystemDisease.status === "true" ? "1" : "0") +
        parseInt(data.medicalHistory.depression.status === "true" ? "1" : "0") +
        parseInt(data.medicalHistory.anxiety.status === "true" ? "1" : "0") +
        parseInt(data.medicalHistory.eyeDisease.status === "true" ? "1" : "0") +
        parseInt(data.medicalHistory.hearingLoss.status === "true" ? "1" : "0") +
        parseInt(data.medicalHistory.degenerativeDiscDisease.status === "true" ? "1" : "0") +
        parseInt(((getScoreForBMI(data) > 30) ? "1" : "0" ))  
    });
};
