export const getDA3Scoring = (data) => {
    return {
        DA3_An: calculateDA3_An(
            data.depressionAnxietyAndApathyAssessment.DA3_An
        ),
        DA3_Ap: calculateDA3_Ap(
            data.depressionAnxietyAndApathyAssessment.DA3_Ap
        ),
        DA3_D: calculateDA3_D(data.depressionAnxietyAndApathyAssessment.DA3_D)
    };
};

const calculateDA3_D = (DA3_D_Data) => {
    return (
        parseInt(DA3_D_Data.cheerFulAndHappy) +
        parseInt(DA3_D_Data.criticalOfMyself) +
        parseInt(DA3_D_Data.goodEnergyLevel) +
        parseInt(DA3_D_Data.satisfied) +
        parseInt(DA3_D_Data.lowSelfEsteem) +
        parseInt(DA3_D_Data.hopeful) +
        parseInt(DA3_D_Data.laughtAtMyself)
    );
};

const calculateDA3_An = (DA3_An_Data) => {
    return (
        parseInt(DA3_An_Data.tenseAndUnableToRelax) +
        parseInt(DA3_An_Data.frightened) +
        parseInt(DA3_An_Data.afraid) +
        parseInt(DA3_An_Data.worry) +
        parseInt(DA3_An_Data.restless) +
        parseInt(DA3_An_Data.trouble) +
        parseInt(DA3_An_Data.strangeFeelings)
    );
};

const calculateDA3_Ap = (DA3_Ap_Data) => {
    return (
        parseInt(DA3_Ap_Data.getThingStarted) +
        parseInt(DA3_Ap_Data.sitByAndLetThingHappen) +
        parseInt(DA3_Ap_Data.lessLikelyToStartConversation) +
        parseInt(DA3_Ap_Data.muchPleaseOutOfThings) +
        parseInt(DA3_Ap_Data.lessSpontaneous) +
        parseInt(DA3_Ap_Data.hobbyActivitiesAndInerest) +
        parseInt(DA3_Ap_Data.lessInterestedInFamilyAndFriendDoing)
    );
};
