export const getFunctionalActivitiesQuestionnaireScoring = (data) => {
    calculateFunctionalActivitiesQuestionnaire(
        data.functionalActivitiesQuestionaire
    );
};

const calculateFunctionalActivitiesQuestionnaire = (data) => {
    return (
        parseInt(data.checkbalancing) +
        parseInt(data.taxRecord) +
        parseInt(data.shoppingAlone) +
        parseInt(data.playingGames) +
        parseInt(data.heatingWater) +
        parseInt(data.preparingMeal) +
        parseInt(data.keepTrack) +
        parseInt(data.payingAttention) +
        parseInt(data.remembering) +
        parseInt(data.taveling)
    );
};
